@font-face {
  font-family: 'ABeeZee';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/abeezee/v22/esDR31xSG-6AGleN6tI.ttf) format('truetype');
}
@font-face {
  font-family: 'Abel';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/abel/v18/MwQ5bhbm2POE6Vg.ttf) format('truetype');
}
@font-face {
  font-family: 'Abhaya Libre';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/abhayalibre/v13/e3tmeuGtX-Co5MNzeAOqinEgew.ttf) format('truetype');
}
@font-face {
  font-family: 'Aboreto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/aboreto/v2/5DCXAKLhwDDQ4N8blKQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Abril Fatface';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/abrilfatface/v19/zOL64pLDlL1D99S8g8PtiKchm-A.ttf) format('truetype');
}
@font-face {
  font-family: 'Abyssinica SIL';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/abyssinicasil/v3/oY1H8ezOqK7iI3rK_45WKoc8J6UZ.ttf) format('truetype');
}
@font-face {
  font-family: 'Aclonica';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/aclonica/v18/K2FyfZJVlfNNSEBXGb7T.ttf) format('truetype');
}
@font-face {
  font-family: 'Acme';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/acme/v18/RrQfboBx-C5_bx0.ttf) format('truetype');
}
@font-face {
  font-family: 'Actor';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/actor/v17/wEOzEBbCkc5cO3ek.ttf) format('truetype');
}
@font-face {
  font-family: 'Adamina';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/adamina/v21/j8_r6-DH1bjoc-dwu-o.ttf) format('truetype');
}
@font-face {
  font-family: 'Advent Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/adventpro/v18/V8mAoQfxVT4Dvddr_yOwtT0.ttf) format('truetype');
}
@font-face {
  font-family: 'Aguafina Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/aguafinascript/v16/If2QXTv_ZzSxGIO30LemWEOmt1bHqg.ttf) format('truetype');
}
@font-face {
  font-family: 'Akaya Kanadaka';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/akayakanadaka/v16/N0bM2S5CPO5oOQqvazoRRb-8-PfR.ttf) format('truetype');
}
@font-face {
  font-family: 'Akaya Telivigala';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/akayatelivigala/v22/lJwc-oo_iG9wXqU3rCTD395tp0uifdI.ttf) format('truetype');
}
@font-face {
  font-family: 'Akronim';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/akronim/v23/fdN-9sqWtWZZlHRp-gA.ttf) format('truetype');
}
@font-face {
  font-family: 'Akshar';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/akshar/v5/Yq6I-LyHWTfz9rGoqDaUbHvhkAUsSXYFy9A.ttf) format('truetype');
}
@font-face {
  font-family: 'Aladin';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/aladin/v18/ZgNSjPJFPrvJV5f16Q.ttf) format('truetype');
}
@font-face {
  font-family: 'Alata';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/alata/v9/PbytFmztEwbIofe6.ttf) format('truetype');
}
@font-face {
  font-family: 'Alatsi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/alatsi/v10/TK3iWkUJAxQ2nLNGHg.ttf) format('truetype');
}
@font-face {
  font-family: 'Albert Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/albertsans/v1/i7dZIFdwYjGaAMFtZd_QA3xXSKZqhr-TenSHq5P_rA.ttf) format('truetype');
}
@font-face {
  font-family: 'Aldrich';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/aldrich/v17/MCoTzAn-1s3IGyJMZaA.ttf) format('truetype');
}
@font-face {
  font-family: 'Alef';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/alef/v21/FeVfS0NQpLYgrjI.ttf) format('truetype');
}
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/alegreya/v29/4UacrEBBsBhlBjvfkQjt71kZfyBzPgNG9hUI_A.ttf) format('truetype');
}
@font-face {
  font-family: 'Alegreya SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/alegreyasc/v22/taiOGmRtCJ62-O0HhNEa-a6o.ttf) format('truetype');
}
@font-face {
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/alegreyasans/v21/5aUz9_-1phKLFgshYDvh6Vwt3V0.ttf) format('truetype');
}
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/alegreyasanssc/v20/mtGh4-RGJqfMvt7P8FUr0Q1j-Hf1Nk4.ttf) format('truetype');
}
@font-face {
  font-family: 'Aleo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/aleo/v11/c4mv1nF8G8_s8Ao.ttf) format('truetype');
}
@font-face {
  font-family: 'Alex Brush';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/alexbrush/v20/SZc83FzrJKuqFbwMKk6EtUI.ttf) format('truetype');
}
@font-face {
  font-family: 'Alexandria';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/alexandria/v1/UMBCrPdDqW66y0Y2usFeQCH18mulUxBvI9r7TqbH.ttf) format('truetype');
}
@font-face {
  font-family: 'Alfa Slab One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/alfaslabone/v17/6NUQ8FmMKwSEKjnm5-4v-4Jh6dU.ttf) format('truetype');
}
@font-face {
  font-family: 'Alice';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/alice/v20/OpNCnoEEmtHa6FcJ.ttf) format('truetype');
}
@font-face {
  font-family: 'Alike';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/alike/v20/HI_EiYEYI6BIoEjB.ttf) format('truetype');
}
@font-face {
  font-family: 'Alike Angular';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/alikeangular/v20/3qTrojWunjGQtEBlIcwMbSoI3kM.ttf) format('truetype');
}
@font-face {
  font-family: 'Alkalami';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/alkalami/v3/zOL_4pfDmqRL95WXi5eL.ttf) format('truetype');
}
@font-face {
  font-family: 'Allan';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/allan/v20/ea8XadU7WuTxEtb2.ttf) format('truetype');
}
@font-face {
  font-family: 'Allerta';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/allerta/v18/TwMO-IAHRlkbx940UnE.ttf) format('truetype');
}
@font-face {
  font-family: 'Allerta Stencil';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/allertastencil/v18/HTx0L209KT-LmIE9N7OR6eiycOeF-w.ttf) format('truetype');
}
@font-face {
  font-family: 'Allison';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/allison/v9/X7nl4b88AP2nkbvZOCY.ttf) format('truetype');
}
@font-face {
  font-family: 'Allura';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/allura/v18/9oRPNYsQpS4zjuAPjA.ttf) format('truetype');
}
@font-face {
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/almarai/v12/tsstApxBaigK_hnnc1o.ttf) format('truetype');
}
@font-face {
  font-family: 'Almendra';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/almendra/v22/H4ckBXKAlMnTn0CskyY6.ttf) format('truetype');
}
@font-face {
  font-family: 'Almendra Display';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/almendradisplay/v25/0FlPVOGWl1Sb4O3tETtADHRRlZhzXS8.ttf) format('truetype');
}
@font-face {
  font-family: 'Almendra SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/almendrasc/v25/Iure6Yx284eebowr7hbyTZZJ.ttf) format('truetype');
}
@font-face {
  font-family: 'Alumni Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/alumnisans/v12/nwpHtKqkOwdO2aOIwhWudEWpx_zq_Xna-Xd9OO9QqA.ttf) format('truetype');
}
@font-face {
  font-family: 'Alumni Sans Collegiate One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/alumnisanscollegiateone/v2/MQpB-XChK8G5CtmK_AuGxQrdNvPSXkn0RM-XqjWWhjda.ttf) format('truetype');
}
@font-face {
  font-family: 'Alumni Sans Inline One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/alumnisansinlineone/v2/RrQBbpJx9zZ3IXTBOASKp5gJAetBdaihcjbpD3A.ttf) format('truetype');
}
@font-face {
  font-family: 'Alumni Sans Pinstripe';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/alumnisanspinstripe/v1/ZgNNjOFFPq_AUJD1umyS30W-Xub8zD1ObhezYg.ttf) format('truetype');
}
@font-face {
  font-family: 'Amarante';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/amarante/v22/xMQXuF1KTa6EvGx9bq-3.ttf) format('truetype');
}
@font-face {
  font-family: 'Amaranth';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/amaranth/v18/KtkuALODe433f0j1zPnC.ttf) format('truetype');
}
@font-face {
  font-family: 'Amatic SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/amaticsc/v24/TUZyzwprpvBS1izr_vO0DQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Amethysta';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/amethysta/v16/rP2Fp2K15kgb_F3ibfWIGA.ttf) format('truetype');
}
@font-face {
  font-family: 'Amiko';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/amiko/v12/WwkQxPq1DFK04tql.ttf) format('truetype');
}
@font-face {
  font-family: 'Amiri';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/amiri/v24/J7aRnpd8CGxBHqUp.ttf) format('truetype');
}
@font-face {
  font-family: 'Amiri Quran';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/amiriquran/v7/_Xmo-Hk0rD6DbUL4_vH8Zq5t.ttf) format('truetype');
}
body {
  --google-font-color-amiriquran:none;
}
@font-face {
  font-family: 'Amita';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/amita/v16/HhyaU5si9Om7PQlv.ttf) format('truetype');
}
@font-face {
  font-family: 'Anaheim';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/anaheim/v14/8vII7w042Wp87g4G0UQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Andada Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/andadapro/v12/HhyEU5Qi9-SuOEhPe4LtKoVCuWGURPcg3DPJBY8c.ttf) format('truetype');
}
@font-face {
  font-family: 'Andika';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/andika/v22/mem_Ya6iyW-LwqgAbQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Anek Bangla';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/anekbangla/v4/_gPW1R38qTExHg-17BhM6n66QhabMYB0fBKONtHhRSIUIre5mq3Ofm9ZIoc.ttf) format('truetype');
}
@font-face {
  font-family: 'Anek Devanagari';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/anekdevanagari/v4/jVyo7nP0CGrUsxB-QiRgw0NlLaVt_QUAkYxLRoCL23mlh20ZVHOMAWbgHLDtku9n.ttf) format('truetype');
}
@font-face {
  font-family: 'Anek Gujarati';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/anekgujarati/v4/l7g_bj5oysqknvkCo2T_8FuiIRBA7lncQUmbIBEtPKiYYQhRwyBxCD-0F5C7ww.ttf) format('truetype');
}
@font-face {
  font-family: 'Anek Gurmukhi';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/anekgurmukhi/v4/0QIAMXRO_YSkA0quVLY79JnHybfeEOrXCa9Dmd9Ql6a6R_vEMc5TaLkbd5tpXA.ttf) format('truetype');
}
@font-face {
  font-family: 'Anek Kannada';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/anekkannada/v4/raxcHiCNvNMKe1CKFsINYFlgkEIwGa8nL6ruWJg1j--h8pvBKSiw4dFDEQuk.ttf) format('truetype');
}
@font-face {
  font-family: 'Anek Latin';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/aneklatin/v4/co3pmWZulTRoU4a8dqrWiajBS5ByUkvdrluH-xWG5uJTY4x-L3PuR7AZKQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Anek Malayalam';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/anekmalayalam/v4/6qLjKZActRTs_mZAJUZWWkhke0nYa_vC8_Azq3-gP1SReZeOtqQuDVUTUZu-HMo.ttf) format('truetype');
}
@font-face {
  font-family: 'Anek Odia';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/anekodia/v4/TK3PWkoJARApz5UCd345tuevwwQX0CwsoYkAWgWYevAauivBUnmZfq3m.ttf) format('truetype');
}
@font-face {
  font-family: 'Anek Tamil';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/anektamil/v4/XLYJIZH2bYJHGYtPGSbUB8JKTp-_9n55SsLHW0WZez6TjtkDu3uNQid6qw.ttf) format('truetype');
}
@font-face {
  font-family: 'Anek Telugu';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/anektelugu/v4/LhWLMVrUNvsddMtYGCx4FcVWOjlwE1WgXdoJ-5XHMl2DkooGK7i13y--oE0.ttf) format('truetype');
}
@font-face {
  font-family: 'Angkor';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/angkor/v28/H4cmBXyAlsPdnlb-8g.ttf) format('truetype');
}
@font-face {
  font-family: 'Annie Use Your Telescope';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/annieuseyourtelescope/v18/daaLSS4tI2qYYl3Jq9s_Hu74xwktnlKxH6osGVGjlA.ttf) format('truetype');
}
@font-face {
  font-family: 'Anonymous Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/anonymouspro/v21/rP2Bp2a15UIB7Un-bOeISG3pLlw.ttf) format('truetype');
}
@font-face {
  font-family: 'Antic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/antic/v19/TuGfUVB8XY5DRaZL.ttf) format('truetype');
}
@font-face {
  font-family: 'Antic Didone';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/anticdidone/v16/RWmPoKKX6u8sp8fIWdnDKqDiqQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Antic Slab';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/anticslab/v16/bWt97fPFfRzkCa9Jlp6IWcI.ttf) format('truetype');
}
@font-face {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/anton/v23/1Ptgg87LROyAm0K0.ttf) format('truetype');
}
@font-face {
  font-family: 'Antonio';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/antonio/v11/gNMbW3NwSYq_9WD34ngK5F8vR8T0PVxx8RtI.ttf) format('truetype');
}
@font-face {
  font-family: 'Anybody';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/anybody/v4/VuJbdNvK2Ib2ppdWYq311GH32hxIv0sd5grncSUi2F_Wim4J12DPrg.ttf) format('truetype');
}
@font-face {
  font-family: 'Arapey';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/arapey/v16/-W__XJn-UDDA2RC6Zw.ttf) format('truetype');
}
@font-face {
  font-family: 'Arbutus';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/arbutus/v24/NaPYcZ7dG_5J3poob9I.ttf) format('truetype');
}
@font-face {
  font-family: 'Arbutus Slab';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/arbutusslab/v16/oY1Z8e7OuLXkJGbXtr5ba7ZVaw.ttf) format('truetype');
}
@font-face {
  font-family: 'Architects Daughter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/architectsdaughter/v18/KtkxAKiDZI_td1Lkx62xHZHDtgO_Y-bvfY4.ttf) format('truetype');
}
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/archivo/v18/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTTNDNp8A.ttf) format('truetype');
}
@font-face {
  font-family: 'Archivo Black';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/archivoblack/v17/HTxqL289NzCGg4MzN6KJ7eW6OYs.ttf) format('truetype');
}
@font-face {
  font-family: 'Archivo Narrow';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/archivonarrow/v24/tss5ApVBdCYD5Q7hcxTE1ArZ0Zz8oY2KRmwvKhhvLFGKpA.ttf) format('truetype');
}
@font-face {
  font-family: 'Are You Serious';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/areyouserious/v10/ll8kK2GVSSr-PtjQ5nONVcNn4306hQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Aref Ruqaa';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/arefruqaa/v23/WwkbxPW1E165rajQKDulEIA.ttf) format('truetype');
}
@font-face {
  font-family: 'Aref Ruqaa Ink';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/arefruqaaink/v5/1q2fY5WOGUFlt84GTOkP6Kdx72Th.ttf) format('truetype');
}
body {
  --google-font-color-arefruqaaink:none;
}
@font-face {
  font-family: 'Arima';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/arima/v1/neIWzCqmt4Aup_qE1nFWqxI1RZX1YTA-pQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Arima Madurai';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/arimamadurai/v14/t5tmIRoeKYORG0WNMgnC3seB7Tk.ttf) format('truetype');
}
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/arimo/v27/P5sfzZCDf9_T_3cV7NCUECyoxNk37cxsBw.ttf) format('truetype');
}
@font-face {
  font-family: 'Arizonia';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/arizonia/v19/neIIzCemt4A5qa7mv6WG.ttf) format('truetype');
}
@font-face {
  font-family: 'Armata';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/armata/v19/gokvH63_HV5jQ-E9lA.ttf) format('truetype');
}
@font-face {
  font-family: 'Arsenal';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/arsenal/v12/wXKrE3kQtZQ4pF3D118.ttf) format('truetype');
}
@font-face {
  font-family: 'Artifika';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/artifika/v20/VEMyRoxzronptCuxu6Wt.ttf) format('truetype');
}
@font-face {
  font-family: 'Arvo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/arvo/v20/tDbD2oWUg0MKmSA.ttf) format('truetype');
}
@font-face {
  font-family: 'Arya';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/arya/v19/ga6CawNG-HJd9UY.ttf) format('truetype');
}
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/asap/v26/KFOOCniXp96a4Tc2DaTeuDAoKsE617JFc49knOIYdjTYkqUsLg.ttf) format('truetype');
}
@font-face {
  font-family: 'Asap Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/asapcondensed/v16/pxidypY1o9NHyXh3WvSbGSggdNeL.ttf) format('truetype');
}
@font-face {
  font-family: 'Asar';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/asar/v22/sZlLdRyI6TBIXkY.ttf) format('truetype');
}
@font-face {
  font-family: 'Asset';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/asset/v24/SLXGc1na-mM4cWIm.ttf) format('truetype');
}
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/assistant/v18/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtuZnEGE.ttf) format('truetype');
}
@font-face {
  font-family: 'Astloch';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/astloch/v26/TuGRUVJ8QI5GSeUjq9w.ttf) format('truetype');
}
@font-face {
  font-family: 'Asul';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/asul/v19/VuJ-dNjKxYr46fM.ttf) format('truetype');
}
@font-face {
  font-family: 'Athiti';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/athiti/v12/pe0vMISdLIZIv1w4DA.ttf) format('truetype');
}
@font-face {
  font-family: 'Atkinson Hyperlegible';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/atkinsonhyperlegible/v10/9Bt23C1KxNDXMspQ1lPyU89-1h6ONRlW45GE5Q.ttf) format('truetype');
}
@font-face {
  font-family: 'Atma';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/atma/v15/uK_84rqWc-Eom24.ttf) format('truetype');
}
@font-face {
  font-family: 'Atomic Age';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/atomicage/v27/f0Xz0eug6sdmRFkYZZGL58E.ttf) format('truetype');
}
@font-face {
  font-family: 'Aubrey';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/aubrey/v28/q5uGsou7NPBw-p7vug.ttf) format('truetype');
}
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/audiowide/v16/l7gdbjpo0cum0ckerWCtkQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Autour One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/autourone/v24/UqyVK80cP25l3fJgbdfbk5k.ttf) format('truetype');
}
@font-face {
  font-family: 'Average';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/average/v18/fC1hPYBHe23MxA7rIeI.ttf) format('truetype');
}
@font-face {
  font-family: 'Average Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/averagesans/v16/1Ptpg8fLXP2dlAXR-HlJJNJPBQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Averia Gruesa Libre';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/averiagruesalibre/v22/NGSov4nEGEktOaDRKsY-1dhh8eEtIx3ZUmk.ttf) format('truetype');
}
@font-face {
  font-family: 'Averia Libre';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/averialibre/v16/2V0aKIcMGZEnV6xygz7eNjEiAg.ttf) format('truetype');
}
@font-face {
  font-family: 'Averia Sans Libre';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/averiasanslibre/v17/ga6XaxZG_G5OvCf_rt7FH3B6BHLMEeVJ.ttf) format('truetype');
}
@font-face {
  font-family: 'Averia Serif Libre';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/averiaseriflibre/v16/neIWzD2ms4wxr6GvjeD0X88SHPyX2xY-pQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Azeret Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/azeretmono/v11/3XF5ErsiyJsY9O_Gepph-FvtTQgMQUdNekSfnPVh0g.ttf) format('truetype');
}
@font-face {
  font-family: 'B612';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/b612/v12/3JnySDDxiSz32jk.ttf) format('truetype');
}
@font-face {
  font-family: 'B612 Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/b612mono/v12/kmK_Zq85QVWbN1eW6lJl1w.ttf) format('truetype');
}
@font-face {
  font-family: 'BIZ UDGothic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bizudgothic/v8/daafSTouBF7RUjnbt8p3LuKttQ.ttf) format('truetype');
}
@font-face {
  font-family: 'BIZ UDMincho';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bizudmincho/v8/EJRRQgI6eOxFjBdKs38yhtW1dw.ttf) format('truetype');
}
@font-face {
  font-family: 'BIZ UDPGothic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bizudpgothic/v8/hES36X5pHAIBjmS84VL0Bue83nU.ttf) format('truetype');
}
@font-face {
  font-family: 'BIZ UDPMincho';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bizudpmincho/v8/ypvfbXOBrmYppy7oWWTg1_58nhg.ttf) format('truetype');
}
@font-face {
  font-family: 'Babylonica';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/babylonica/v2/5aUw9_i2qxWVCAE2aHjTqDI.ttf) format('truetype');
}
@font-face {
  font-family: 'Bad Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/badscript/v16/6NUT8F6PJgbFWQn47_x7lOw.ttf) format('truetype');
}
@font-face {
  font-family: 'Bahiana';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bahiana/v19/uU9PCBUV4YenPWJU7xM.ttf) format('truetype');
}
@font-face {
  font-family: 'Bahianita';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bahianita/v17/yYLr0hTb3vuqqsBUgxWtxQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Bai Jamjuree';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/baijamjuree/v11/LDI1apSCOBt_aeQQ7ftydoaMWQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Bakbak One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bakbakone/v6/zOL54pXAl6RI-p_ardnuycQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Ballet';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ballet/v21/QGYyz_MYZA-HM4NjuGOVnUEXme1I4Xi3C4E.ttf) format('truetype');
}
@font-face {
  font-family: 'Baloo 2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/baloo2/v16/wXK0E3kTposypRydzVT08TS3JnAmtdgazapv.ttf) format('truetype');
}
@font-face {
  font-family: 'Baloo Bhai 2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/baloobhai2/v21/sZlWdRSL-z1VEWZ4YNA7Y5ItevYWUOHDE8FvNighMXc.ttf) format('truetype');
}
@font-face {
  font-family: 'Baloo Bhaijaan 2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/baloobhaijaan2/v14/zYXwKUwuEqdVGqM8tPDdAA_Y-_bMKo1EhQd2tWxo8TyRSqP4.ttf) format('truetype');
}
@font-face {
  font-family: 'Baloo Bhaina 2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/baloobhaina2/v22/qWc-B6yyq4P9Adr3RtoX1q6ySgbwusXwJjkOS-XEssPvRQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Baloo Chettan 2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/baloochettan2/v16/vm8hdRbmXEva26PK-NtuX4ynWEzF69-L4gqgkIL5CeKTO1o.ttf) format('truetype');
}
@font-face {
  font-family: 'Baloo Da 2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/balooda2/v16/2-c39J9j0IaUMQZwAJyJaOX1UUnf3GLnYjALsTNe.ttf) format('truetype');
}
@font-face {
  font-family: 'Baloo Paaji 2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/baloopaaji2/v22/i7dfIFFzbz-QHZUdV9_UGWZuelmy79QJ1HOSY9AX74fy.ttf) format('truetype');
}
@font-face {
  font-family: 'Baloo Tamma 2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/balootamma2/v15/vEFE2_hCAgcR46PaajtrYlBbVUMUJgIC5LHTrMscPp-0.ttf) format('truetype');
}
@font-face {
  font-family: 'Baloo Tammudu 2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/balootammudu2/v22/1Pt5g8TIS_SAmkLguUdFP8UaJcKkzlPmMT00GaE_Jf8e4c4.ttf) format('truetype');
}
@font-face {
  font-family: 'Baloo Thambi 2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/baloothambi2/v16/cY9RfjeOW0NHpmOQXranrbDyu5JMJmNp-aDvUBbKzcIzaQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Balsamiq Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/balsamiqsans/v10/P5sEzZiAbNrN8SB3lQQX7Pnc8dk.ttf) format('truetype');
}
@font-face {
  font-family: 'Balthazar';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/balthazar/v17/d6lKkaajS8Gm4CVQjFEvyQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Bangers';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bangers/v20/FeVQS0BTqb0h60ACL5k.ttf) format('truetype');
}
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/barlow/v12/7cHpv4kjgoGqM7EPCw.ttf) format('truetype');
}
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/barlowcondensed/v12/HTx3L3I-JCGChYJ8VI-L6OO_au7B2xY.ttf) format('truetype');
}
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/barlowsemicondensed/v14/wlpvgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRnfw.ttf) format('truetype');
}
@font-face {
  font-family: 'Barriecito';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/barriecito/v17/WWXXlj-CbBOSLY2QTuY_KdU.ttf) format('truetype');
}
@font-face {
  font-family: 'Barrio';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/barrio/v19/wEO8EBXBk8hBIDiEdQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Basic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/basic/v17/xfu_0WLxV2_XKQN3.ttf) format('truetype');
}
@font-face {
  font-family: 'Baskervville';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/baskervville/v13/YA9Ur0yU4l_XOrogbkun3kQgtw.ttf) format('truetype');
}
@font-face {
  font-family: 'Battambang';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/battambang/v24/uk-mEGe7raEw-HjkzZabDnU.ttf) format('truetype');
}
@font-face {
  font-family: 'Baumans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/baumans/v17/-W_-XJj9QyTd3QfpR_o.ttf) format('truetype');
}
@font-face {
  font-family: 'Bayon';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bayon/v29/9XUrlJNmn0LPFl-p.ttf) format('truetype');
}
@font-face {
  font-family: 'Be Vietnam Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVPSTAyLFyeg_IDWvOJmVES_Eww.ttf) format('truetype');
}
@font-face {
  font-family: 'Beau Rivage';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/beaurivage/v2/UcCi3FIgIG2bH4mMNWJUlmg3.ttf) format('truetype');
}
@font-face {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bebasneue/v9/JTUSjIg69CK48gW7PXooxW4.ttf) format('truetype');
}
@font-face {
  font-family: 'Belgrano';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/belgrano/v18/55xvey5tM9rwKWrJZcMF.ttf) format('truetype');
}
@font-face {
  font-family: 'Bellefair';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bellefair/v14/kJExBuYY6AAuhiXUxG19_w.ttf) format('truetype');
}
@font-face {
  font-family: 'Belleza';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/belleza/v17/0nkoC9_pNeMfhX4BtcY.ttf) format('truetype');
}
@font-face {
  font-family: 'Bellota';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bellota/v16/MwQ2bhXl3_qEpiwAGJI.ttf) format('truetype');
}
@font-face {
  font-family: 'Bellota Text';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bellotatext/v16/0FlTVP2VnlWS4f3-UE9hHXMB-Q.ttf) format('truetype');
}
@font-face {
  font-family: 'BenchNine';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/benchnine/v16/ahcbv8612zF4jxrwMosrVw.ttf) format('truetype');
}
@font-face {
  font-family: 'Benne';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/benne/v22/L0xzDFAhn18E6Vjx.ttf) format('truetype');
}
@font-face {
  font-family: 'Bentham';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bentham/v18/VdGeAZQPEpYfmHglKWw.ttf) format('truetype');
}
@font-face {
  font-family: 'Berkshire Swash';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/berkshireswash/v16/ptRRTi-cavZOGqCvnNJDl5m5XmNPrQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Besley';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/besley/v12/PlIhFlO1MaNwaNGWUC92IOH_mtG4fbbBSdQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Beth Ellen';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bethellen/v17/WwkbxPW2BE-3rb_JNT-qEIA.ttf) format('truetype');
}
@font-face {
  font-family: 'Bevan';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bevan/v20/4iCj6KZ0a9NXjF8a.ttf) format('truetype');
}
@font-face {
  font-family: 'BhuTuka Expanded One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bhutukaexpandedone/v2/SLXXc0jZ4WUJcClHTtv0t7IaDRsBsWRiJCyX.ttf) format('truetype');
}
@font-face {
  font-family: 'Big Shoulders Display';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bigshouldersdisplay/v15/fC1MPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx12wPgf9g-_3F0YdY87JF4.ttf) format('truetype');
}
@font-face {
  font-family: 'Big Shoulders Inline Display';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bigshouldersinlinedisplay/v21/_LOumyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85CGNOFeNLxoYMPJ0nBE3R5.ttf) format('truetype');
}
@font-face {
  font-family: 'Big Shoulders Inline Text';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bigshouldersinlinetext/v21/vm8XdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NMyHY74qpoNNcwga0y6GN.ttf) format('truetype');
}
@font-face {
  font-family: 'Big Shoulders Stencil Display';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bigshouldersstencildisplay/v21/6aeZ4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPytKb-oPRU-vkuLm_O0jPKA.ttf) format('truetype');
}
@font-face {
  font-family: 'Big Shoulders Stencil Text';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bigshouldersstenciltext/v21/5aUV9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcab2SJYLLUtk1OGR04TIGQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Big Shoulders Text';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bigshoulderstext/v17/55xEezRtP9G3CGPIf49hxc8P0eytUxB2l66LmF6xc3kA3Y-q3TI.ttf) format('truetype');
}
@font-face {
  font-family: 'Bigelow Rules';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bigelowrules/v23/RrQWboly8iR_I3KWSzeRuN0zT4c.ttf) format('truetype');
}
@font-face {
  font-family: 'Bigshot One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bigshotone/v25/u-470qukhRkkO6BD_7cM_gxu.ttf) format('truetype');
}
@font-face {
  font-family: 'Bilbo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bilbo/v20/o-0EIpgpwWwZ210h.ttf) format('truetype');
}
@font-face {
  font-family: 'Bilbo Swash Caps';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bilboswashcaps/v22/zrf-0GXbz-H3Wb4XBsGrTgq2PVmdqAM.ttf) format('truetype');
}
@font-face {
  font-family: 'BioRhyme';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/biorhyme/v12/1cXwaULHBpDMsHYW_HxG.ttf) format('truetype');
}
@font-face {
  font-family: 'BioRhyme Expanded';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/biorhymeexpanded/v19/i7dQIE1zZzytGswgU577CDY9LjbffySU.ttf) format('truetype');
}
@font-face {
  font-family: 'Birthstone';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/birthstone/v10/8AtsGs2xO4yLRhy87sv_HLk.ttf) format('truetype');
}
@font-face {
  font-family: 'Birthstone Bounce';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/birthstonebounce/v9/ga6XaxZF43lIvTWrktHOTBJZGH7dEeVJ.ttf) format('truetype');
}
@font-face {
  font-family: 'Biryani';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/biryani/v13/hv-WlzNxIFoO84YdTUw.ttf) format('truetype');
}
@font-face {
  font-family: 'Bitter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bitter/v28/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8fbfCL8.ttf) format('truetype');
}
@font-face {
  font-family: 'Black And White Picture';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/blackandwhitepicture/v22/TwMe-JAERlQd3ooUHBUXGmrmioKjjnRSFO-NqI5H.ttf) format('truetype');
}
@font-face {
  font-family: 'Black Han Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/blackhansans/v15/ea8Aad44WunzF9a-dL6toA8r8nqV.ttf) format('truetype');
}
@font-face {
  font-family: 'Black Ops One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/blackopsone/v20/qWcsB6-ypo7xBdr6Xshe96H3WDw.ttf) format('truetype');
}
@font-face {
  font-family: 'Blaka';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/blaka/v3/8vIG7w8722p_6kdr.ttf) format('truetype');
}
@font-face {
  font-family: 'Blaka Hollow';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/blakahollow/v3/MCoUzAL91sjRE2FsKsxUtezYBw.ttf) format('truetype');
}
@font-face {
  font-family: 'Blaka Ink';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/blakaink/v5/AlZy_zVVtpj22Znag2chdQ.ttf) format('truetype');
}
body {
  --google-font-color-blakaink:none;
}
@font-face {
  font-family: 'Blinker';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/blinker/v12/cIf9MaFatEE-VTaPxCk.ttf) format('truetype');
}
@font-face {
  font-family: 'Bodoni Moda';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bodonimoda/v19/aFT67PxzY382XsXX63LUYL6GYFcan6NJrKp-VPjfJMShrpsGFUt8oU7awIA.ttf) format('truetype');
}
@font-face {
  font-family: 'Bokor';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bokor/v30/m8JcjfpeeaqTiR2W.ttf) format('truetype');
}
@font-face {
  font-family: 'Bona Nova';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bonanova/v10/B50NF7ZCpX7fcHfvIUBJiw.ttf) format('truetype');
}
@font-face {
  font-family: 'Bonbon';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bonbon/v26/0FlVVPeVlFec4ee_cA.ttf) format('truetype');
}
@font-face {
  font-family: 'Bonheur Royale';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bonheurroyale/v9/c4m51nt_GMTrtX-b9GcG4-YRmYK_.ttf) format('truetype');
}
@font-face {
  font-family: 'Boogaloo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/boogaloo/v19/kmK-Zq45GAvOdnaW6x1F.ttf) format('truetype');
}
@font-face {
  font-family: 'Bowlby One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bowlbyone/v19/taiPGmVuC4y96PFeqp8smo4.ttf) format('truetype');
}
@font-face {
  font-family: 'Bowlby One SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bowlbyonesc/v19/DtVlJxerQqQm37tzN3wMug9Pzgg.ttf) format('truetype');
}
@font-face {
  font-family: 'Brawler';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/brawler/v19/xn7gYHE3xXewAscGsgA.ttf) format('truetype');
}
@font-face {
  font-family: 'Bree Serif';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/breeserif/v17/4UaHrEJCrhhnVA3DgluAx60.ttf) format('truetype');
}
@font-face {
  font-family: 'Brygada 1918';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/brygada1918/v21/pe08MI6eKpdGqlF5LANrM--ACNaeo8mTUIR_y2-f-V8.ttf) format('truetype');
}
@font-face {
  font-family: 'Bubblegum Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bubblegumsans/v16/AYCSpXb_Z9EORv1M5QTjEzMEtdaH.ttf) format('truetype');
}
@font-face {
  font-family: 'Bubbler One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bubblerone/v20/f0Xy0eqj68ppQV9KBLmAouHH.ttf) format('truetype');
}
@font-face {
  font-family: 'Buenard';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/buenard/v17/OD5DuM6Cyma8FnnsPzc.ttf) format('truetype');
}
@font-face {
  font-family: 'Bungee';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bungee/v11/N0bU2SZBIuF2PU_ECg.ttf) format('truetype');
}
@font-face {
  font-family: 'Bungee Hairline';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bungeehairline/v18/snfys0G548t04270a_ljTLUVrv-7YA.ttf) format('truetype');
}
@font-face {
  font-family: 'Bungee Inline';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bungeeinline/v11/Gg8zN58UcgnlCweMrih332VuDGI.ttf) format('truetype');
}
@font-face {
  font-family: 'Bungee Outline';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bungeeoutline/v18/_6_mEDvmVP24UvU2MyiGDslL3Qg3.ttf) format('truetype');
}
@font-face {
  font-family: 'Bungee Shade';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bungeeshade/v11/DtVkJxarWL0t2KdzK3oI_jks7g.ttf) format('truetype');
}
@font-face {
  font-family: 'Bungee Spice';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bungeespice/v8/nwpTtK2nIhxE0q-IwgSpZBqCzw.ttf) format('truetype');
}
body {
  --google-font-color-bungeespice:none;
}
@font-face {
  font-family: 'Butcherman';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/butcherman/v24/2EbiL-thF0loflXUBOdb1zU.ttf) format('truetype');
}
@font-face {
  font-family: 'Butterfly Kids';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/butterflykids/v21/ll8lK2CWTjuqAsXDqlnIbMNs5S4a.ttf) format('truetype');
}
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cabin/v26/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkV2EL7E.ttf) format('truetype');
}
@font-face {
  font-family: 'Cabin Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cabincondensed/v19/nwpMtK6mNhBK2err_hqkYhHRqmwaYA.ttf) format('truetype');
}
@font-face {
  font-family: 'Cabin Sketch';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cabinsketch/v19/QGYpz_kZZAGCONcK2A4bGOjMnw.ttf) format('truetype');
}
@font-face {
  font-family: 'Caesar Dressing';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/caesardressing/v21/yYLx0hLa3vawqtwdswbotmK4vrR3cQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Cagliostro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cagliostro/v21/ZgNWjP5HM73BV5amnX-TjGU.ttf) format('truetype');
}
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cairo/v22/SLXgc1nY6HkvangtZmpQdkhzfH5lkSs2SgRjCAGMQ1z0hOA-W1Q.ttf) format('truetype');
}
@font-face {
  font-family: 'Cairo Play';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cairoplay/v6/wXKEE3QSpo4vpRz_mz6FP-8iaauCLt_Hjopv3miu5IvcJo49mOo1knYq9w.ttf) format('truetype');
}
body {
  --google-font-color-cairoplay:none;
}
@font-face {
  font-family: 'Caladea';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/caladea/v7/kJEzBugZ7AAjhybUjR8.ttf) format('truetype');
}
@font-face {
  font-family: 'Calistoga';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/calistoga/v10/6NUU8F2OJg6MeR7l4e0vtA.ttf) format('truetype');
}
@font-face {
  font-family: 'Calligraffitti';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/calligraffitti/v19/46k2lbT3XjDVqJw3DCmCFjE0vnFZ.ttf) format('truetype');
}
@font-face {
  font-family: 'Cambay';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cambay/v12/SLXJc1rY6H0_ZDsGbg.ttf) format('truetype');
}
@font-face {
  font-family: 'Cambo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cambo/v14/IFSqHeNEk8FJk416.ttf) format('truetype');
}
@font-face {
  font-family: 'Candal';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/candal/v15/XoHn2YH6T7-t_8cNAQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Cantarell';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cantarell/v17/B50NF7ZDq37KMUvlO01Jiw.ttf) format('truetype');
}
@font-face {
  font-family: 'Cantata One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cantataone/v15/PlI5Fl60Nb5obNzNe2jslVxE.ttf) format('truetype');
}
@font-face {
  font-family: 'Cantora One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cantoraone/v19/gyB4hws1JdgnKy56GB_JX6zd.ttf) format('truetype');
}
@font-face {
  font-family: 'Capriola';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/capriola/v13/wXKoE3YSppcvo1PDln_8.ttf) format('truetype');
}
@font-face {
  font-family: 'Caramel';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/caramel/v7/P5sCzZKBbMTf_ShyxCQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Carattere';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/carattere/v7/4iCv6Kp1b9dXlgt_CkvTtw.ttf) format('truetype');
}
@font-face {
  font-family: 'Cardo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cardo/v19/wlp_gwjKBV1pqiv_.ttf) format('truetype');
}
@font-face {
  font-family: 'Carme';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/carme/v16/ptRHTiWdbvZIDOjG.ttf) format('truetype');
}
@font-face {
  font-family: 'Carrois Gothic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/carroisgothic/v16/Z9XPDmFATg-N1PLtLOOxvIHl9ZmD.ttf) format('truetype');
}
@font-face {
  font-family: 'Carrois Gothic SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/carroisgothicsc/v15/ZgNJjOVHM6jfUZCmyUqT2A2HVKjc-28n.ttf) format('truetype');
}
@font-face {
  font-family: 'Carter One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/carterone/v17/q5uCsoe5IOB2-pXv9UcNIxQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Castoro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/castoro/v18/1q2GY5yMCld3-O4cHYg.ttf) format('truetype');
}
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/catamaran/v17/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPHjd1ak.ttf) format('truetype');
}
@font-face {
  font-family: 'Caudex';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/caudex/v15/esDQ311QOP6BJUrIyg.ttf) format('truetype');
}
@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/caveat/v17/WnznHAc5bAfYB2QRah7pcpNvOx-pjfJ9SII.ttf) format('truetype');
}
@font-face {
  font-family: 'Caveat Brush';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/caveatbrush/v11/EYq0maZfwr9S9-ETZc3fKXtMWw.ttf) format('truetype');
}
@font-face {
  font-family: 'Cedarville Cursive';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cedarvillecursive/v17/yYL00g_a2veiudhUmxjo5VKkoqA-B_neJQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Ceviche One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cevicheone/v16/gyB4hws1IcA6JzR-GB_JX6zd.ttf) format('truetype');
}
@font-face {
  font-family: 'Chakra Petch';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/chakrapetch/v9/cIf6MapbsEk7TDLdtEz1BwkmmA.ttf) format('truetype');
}
@font-face {
  font-family: 'Changa';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/changa/v20/2-c79JNi2YuVOUcOarRPgnNGooxCZ62xQjA.ttf) format('truetype');
}
@font-face {
  font-family: 'Changa One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/changaone/v18/xfu00W3wXn3QLUJXhzq46AY.ttf) format('truetype');
}
@font-face {
  font-family: 'Chango';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/chango/v21/2V0cKI0OB5U7WaJyzw.ttf) format('truetype');
}
@font-face {
  font-family: 'Charis SIL';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/charissil/v1/oPWK_kV3l-s-Q8govXvKrPo.ttf) format('truetype');
}
@font-face {
  font-family: 'Charm';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/charm/v10/7cHmv4oii5K0MeYv.ttf) format('truetype');
}
@font-face {
  font-family: 'Charmonman';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/charmonman/v18/MjQDmiR3vP_nuxDv47jiWJE.ttf) format('truetype');
}
@font-face {
  font-family: 'Chathura';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/chathura/v20/_gP71R7-rzUuVjim418g.ttf) format('truetype');
}
@font-face {
  font-family: 'Chau Philomene One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/chauphilomeneone/v15/55xxezRsPtfie1vPY49qzdgSlJiHRQFsnA.ttf) format('truetype');
}
@font-face {
  font-family: 'Chela One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/chelaone/v21/6ae-4KC7Uqgdz_JZdPIy3w.ttf) format('truetype');
}
@font-face {
  font-family: 'Chelsea Market';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/chelseamarket/v13/BCawqZsHqfr89WNP_IApC8tzKBhl.ttf) format('truetype');
}
@font-face {
  font-family: 'Chenla';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/chenla/v25/SZc43FDpIKu8WZ9eXw.ttf) format('truetype');
}
@font-face {
  font-family: 'Cherish';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cherish/v7/ll88K2mXUyqsDsTN5iA.ttf) format('truetype');
}
@font-face {
  font-family: 'Cherry Cream Soda';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cherrycreamsoda/v21/UMBIrOxBrW6w2FFyi9paG0fdVdRciTd6.ttf) format('truetype');
}
@font-face {
  font-family: 'Cherry Swash';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cherryswash/v18/i7dNIFByZjaNAMxtZcnfAy58QA.ttf) format('truetype');
}
@font-face {
  font-family: 'Chewy';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/chewy/v18/uK_94ruUb-k-wk5x.ttf) format('truetype');
}
@font-face {
  font-family: 'Chicle';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/chicle/v21/lJwG-pw9i2dqU-BDyQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Chilanka';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/chilanka/v18/WWXRlj2DZQiMJYaYRrJQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Chivo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/chivo/v18/va9b4kzIxd1KFppkaRKvDRPJVDf_vB_ulw.ttf) format('truetype');
}
@font-face {
  font-family: 'Chonburi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/chonburi/v10/8AtqGs-wOpGRTBq66IWa.ttf) format('truetype');
}
@font-face {
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cinzel/v19/8vIU7ww63mVu7gtR-kwKxNvkNOjw-tbnTYo.ttf) format('truetype');
}
@font-face {
  font-family: 'Cinzel Decorative';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cinzeldecorative/v14/daaCSScvJGqLYhG8nNt8KPPswUAPnh7U.ttf) format('truetype');
}
@font-face {
  font-family: 'Clicker Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/clickerscript/v13/raxkHiKPvt8CMH6ZWP8PdlEq72rY.ttf) format('truetype');
}
@font-face {
  font-family: 'Coda';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/coda/v21/SLXHc1jY5nQ8JUI.ttf) format('truetype');
}
@font-face {
  font-family: 'Codystar';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/codystar/v15/FwZY7-Q1xVk-40qxOt6A.ttf) format('truetype');
}
@font-face {
  font-family: 'Coiny';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/coiny/v16/gyByhwU1K989PXwb.ttf) format('truetype');
}
@font-face {
  font-family: 'Combo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/combo/v21/BXRlvF3Jh_fIhg0i.ttf) format('truetype');
}
@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/comfortaa/v40/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4WjMPrQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Comforter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/comforter/v5/H4clBXOCl8nQnlaql3Qa6A.ttf) format('truetype');
}
@font-face {
  font-family: 'Comforter Brush';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/comforterbrush/v5/Y4GTYa1xVSggrfzZI5WMjxRaOz0jwA.ttf) format('truetype');
}
@font-face {
  font-family: 'Comic Neue';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/comicneue/v8/4UaHrEJDsxBrF37olUeDx60.ttf) format('truetype');
}
@font-face {
  font-family: 'Coming Soon';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/comingsoon/v19/qWcuB6mzpYL7AJ2VfdQR1u-S.ttf) format('truetype');
}
@font-face {
  font-family: 'Commissioner';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/commissioner/v13/tDbe2o2WnlgI0FNDgduEk4jAhwgIy5k8SlfU5Ni_IO8.ttf) format('truetype');
}
@font-face {
  font-family: 'Concert One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/concertone/v17/VEM1Ro9xs5PjtzCu-srDqRTl.ttf) format('truetype');
}
@font-face {
  font-family: 'Condiment';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/condiment/v20/pONk1hggFNmwvXALyH6Sqw.ttf) format('truetype');
}
@font-face {
  font-family: 'Content';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/content/v24/zrfl0HLayePhU_AwUaA.ttf) format('truetype');
}
@font-face {
  font-family: 'Contrail One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/contrailone/v15/eLGbP-j_JA-kG0_Zo51noafdZQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Convergence';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/convergence/v15/rax5HiePvdgXPmmMHcIPYRha.ttf) format('truetype');
}
@font-face {
  font-family: 'Cookie';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cookie/v17/syky-y18lb0tSbfNlQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Copse';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/copse/v15/11hPGpDKz1rGb0dj.ttf) format('truetype');
}
@font-face {
  font-family: 'Corben';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/corben/v19/LYjDdGzzklQtCMp9oA.ttf) format('truetype');
}
@font-face {
  font-family: 'Corinthia';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/corinthia/v9/wEO_EBrAnchaJyPMHE0FUQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Cormorant';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cormorant/v18/H4c2BXOCl9bbnla_nHIA47NMUjsNbCVrFhFTQ7Q.ttf) format('truetype');
}
@font-face {
  font-family: 'Cormorant Garamond';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cormorantgaramond/v16/co3bmX5slCNuHLi8bLeY9MK7whWMhyjorg.ttf) format('truetype');
}
@font-face {
  font-family: 'Cormorant Infant';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cormorantinfant/v17/HhyPU44g9vKiM1sORYSiWeAsLN993_A.ttf) format('truetype');
}
@font-face {
  font-family: 'Cormorant SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cormorantsc/v17/0yb5GD4kxqXBmOVLG30OGwserA.ttf) format('truetype');
}
@font-face {
  font-family: 'Cormorant Unicase';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cormorantunicase/v24/HI_QiZUaILtOqhqgDeXoF_n1_fTGX-vT.ttf) format('truetype');
}
@font-face {
  font-family: 'Cormorant Upright';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cormorantupright/v18/VuJrdM3I2Y35poFONtLdafkUCHw1y2vV.ttf) format('truetype');
}
@font-face {
  font-family: 'Courgette';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/courgette/v13/wEO_EBrAnc9BLjLQAUkFUQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Courier Prime';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/courierprime/v7/u-450q2lgwslOqpF_6gQ8kELWwY.ttf) format('truetype');
}
@font-face {
  font-family: 'Cousine';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cousine/v25/d6lIkaiiRdih4SpPzSM.ttf) format('truetype');
}
@font-face {
  font-family: 'Coustard';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/coustard/v16/3XFpErgg3YsZ5fqUU9UP.ttf) format('truetype');
}
@font-face {
  font-family: 'Covered By Your Grace';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/coveredbyyourgrace/v15/QGYwz-AZahWOJJI9kykWW9mD6opopoqXSOS0Fg.ttf) format('truetype');
}
@font-face {
  font-family: 'Crafty Girls';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/craftygirls/v16/va9B4kXI39VaDdlPJo8N_NvuQQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Creepster';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/creepster/v13/AlZy_zVUqJz4yMrniH4hdQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Crete Round';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/creteround/v14/55xoey1sJNPjPiv1ZZZrxJ18.ttf) format('truetype');
}
@font-face {
  font-family: 'Crimson Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/crimsonpro/v23/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZzm18OA.ttf) format('truetype');
}
@font-face {
  font-family: 'Crimson Text';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/crimsontext/v19/wlp2gwHKFkZgtmSR3NB0oRJvaA.ttf) format('truetype');
}
@font-face {
  font-family: 'Croissant One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/croissantone/v20/3y9n6bU9bTPg4m8NDy3Kq24UM3o.ttf) format('truetype');
}
@font-face {
  font-family: 'Crushed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/crushed/v25/U9Mc6dym6WXImTlFT1k.ttf) format('truetype');
}
@font-face {
  font-family: 'Cuprum';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cuprum/v20/dg45_pLmvrkcOkBnKsOzXyGWTBcmg-X6Zjw.ttf) format('truetype');
}
@font-face {
  font-family: 'Cute Font';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cutefont/v20/Noaw6Uny2oWPbSHMrY6vmA.ttf) format('truetype');
}
@font-face {
  font-family: 'Cutive';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cutive/v17/NaPZcZ_fHOhV3Ip7Tw.ttf) format('truetype');
}
@font-face {
  font-family: 'Cutive Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cutivemono/v14/m8JWjfRfY7WVjVi2E-K9H5RF.ttf) format('truetype');
}
@font-face {
  font-family: 'DM Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmmono/v10/aFTU7PB1QTsUX8KYhh0.ttf) format('truetype');
}
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v11/rP2Hp2ywxg089UriOZQ.ttf) format('truetype');
}
@font-face {
  font-family: 'DM Serif Display';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmserifdisplay/v10/-nFnOHM81r4j6k0gjAW3mujVU2B2K_c.ttf) format('truetype');
}
@font-face {
  font-family: 'DM Serif Text';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmseriftext/v10/rnCu-xZa_krGokauCeNq1wWyafM.ttf) format('truetype');
}
@font-face {
  font-family: 'Damion';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/damion/v14/hv-XlzJ3KEUe_YZUbQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Dancing Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dancingscript/v24/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSoHTQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Dangrek';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dangrek/v26/LYjCdG30nEgoH8E2gCM.ttf) format('truetype');
}
@font-face {
  font-family: 'Darker Grotesque';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/darkergrotesque/v7/U9MH6cuh-mLQlC4BKCtayOfARkSVm7Y.ttf) format('truetype');
}
@font-face {
  font-family: 'David Libre';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/davidlibre/v13/snfus0W_99N64iuYSvp4W_l8.ttf) format('truetype');
}
@font-face {
  font-family: 'Dawning of a New Day';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dawningofanewday/v16/t5t_IQMbOp2SEwuncwLRjMfIg1yYit_nAz8b.ttf) format('truetype');
}
@font-face {
  font-family: 'Days One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/daysone/v14/mem9YaCnxnKRiYZOCLYV.ttf) format('truetype');
}
@font-face {
  font-family: 'Dekko';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dekko/v19/46khlb_wWjfSrttF.ttf) format('truetype');
}
@font-face {
  font-family: 'Dela Gothic One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/delagothicone/v10/~ChEKD0RlbGEgR290aGljIE9uZSAA.ttf) format('truetype');
}
@font-face {
  font-family: 'Delius';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/delius/v15/PN_xRfK0pW_9e1rtYQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Delius Swash Caps';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/deliusswashcaps/v19/oY1E8fPLr7v4JWCExZpWebxVKORpXXed.ttf) format('truetype');
}
@font-face {
  font-family: 'Delius Unicase';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/deliusunicase/v26/845BNMEwEIOVT8BmgfSzIr_6mmLH.ttf) format('truetype');
}
@font-face {
  font-family: 'Della Respira';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dellarespira/v18/RLp5K5v44KaueWI6iEJQBiGPRfk.ttf) format('truetype');
}
@font-face {
  font-family: 'Denk One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/denkone/v15/dg4m_pzhrqcFb2IzROtH.ttf) format('truetype');
}
@font-face {
  font-family: 'Devonshire';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/devonshire/v21/46kqlbDwWirWr4gtBD2BX0U.ttf) format('truetype');
}
@font-face {
  font-family: 'Dhurjati';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dhurjati/v20/_6_8ED3gSeatXfFiFX3y.ttf) format('truetype');
}
@font-face {
  font-family: 'Didact Gothic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/didactgothic/v19/ahcfv8qz1zt6hCC5G4F_P4ASpUw.ttf) format('truetype');
}
@font-face {
  font-family: 'Diplomata';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/diplomata/v24/Cn-0JtiMXwhNwp-wKxyfYA.ttf) format('truetype');
}
@font-face {
  font-family: 'Diplomata SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/diplomatasc/v21/buExpoi3ecvs3kidKgBJo2kf-A.ttf) format('truetype');
}
@font-face {
  font-family: 'Do Hyeon';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dohyeon/v16/TwMN-I8CRRU2zM86HFE3.ttf) format('truetype');
}
@font-face {
  font-family: 'Dokdo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dokdo/v15/esDf315XNuCBLxLo.ttf) format('truetype');
}
@font-face {
  font-family: 'Domine';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/domine/v19/L0xhDFMnlVwD4h3Lt9JWnbX3jG-2X3LAI10.ttf) format('truetype');
}
@font-face {
  font-family: 'Donegal One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/donegalone/v20/m8JWjfRYea-ZnFz6fsK9FZRF.ttf) format('truetype');
}
@font-face {
  font-family: 'Dongle';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dongle/v8/sJoF3Ltdjt6VPkqmvQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Doppio One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/doppioone/v13/Gg8wN5gSaBfyBw2MqCh-lgs.ttf) format('truetype');
}
@font-face {
  font-family: 'Dorsa';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dorsa/v23/yYLn0hjd0OGwqo49.ttf) format('truetype');
}
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dosis/v27/HhyJU5sn9vOmLxNkIwRSjTVNWLEJN7MV3A.ttf) format('truetype');
}
@font-face {
  font-family: 'DotGothic16';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dotgothic16/v15/v6-QGYjBJFKgyw5nSoDAGE7L.ttf) format('truetype');
}
@font-face {
  font-family: 'Dr Sugiyama';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/drsugiyama/v22/HTxoL2k4N3O9n5I1boGI7abR.ttf) format('truetype');
}
@font-face {
  font-family: 'Duru Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/durusans/v19/xn7iYH8xwmSyTvEV_HOxTw.ttf) format('truetype');
}
@font-face {
  font-family: 'DynaPuff';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dynapuff/v1/z7N5dRvsZDIVHbYPMhZJ3HQ83UaSu4uhr7-ZFeoYkgAr1x8RSxYu6Yg.ttf) format('truetype');
}
@font-face {
  font-family: 'Dynalight';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dynalight/v18/1Ptsg8LOU_aOmQvTsF4ISg.ttf) format('truetype');
}
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ebgaramond/v26/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-6_RUAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Eagle Lake';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/eaglelake/v20/ptRMTiqbbuNJDOiKj9wG5O4.ttf) format('truetype');
}
@font-face {
  font-family: 'East Sea Dokdo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/eastseadokdo/v20/xfuo0Wn2V2_KanASqXSZp22m05_a.ttf) format('truetype');
}
@font-face {
  font-family: 'Eater';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/eater/v21/mtG04_FCK7bOvpu2.ttf) format('truetype');
}
@font-face {
  font-family: 'Economica';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/economica/v13/Qw3fZQZaHCLgIWa29ZBrMQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Eczar';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/eczar/v17/BXR2vF3Pi-DLmxcpJB-qbNTyTMDXHd6WqQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Edu NSW ACT Foundation';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/edunswactfoundation/v2/raxRHjqJtsNBFUi8WO0vUBgc9D-2lV_oQdCAYlt_QTQ0vUxJki9tovGL.ttf) format('truetype');
}
@font-face {
  font-family: 'Edu QLD Beginner';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/eduqldbeginner/v2/AMOHz5iUuHLEMNXyohhc_Y56PR3A8dNLF_w3Ka4HKE4E3oeb.ttf) format('truetype');
}
@font-face {
  font-family: 'Edu SA Beginner';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/edusabeginner/v2/rnC_-xRb1x-1lHXnLaZZ2xOoLIGfU3L82irpr_3C9989fo0.ttf) format('truetype');
}
@font-face {
  font-family: 'Edu TAS Beginner';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/edutasbeginner/v2/ZXuwe04WubHfGVY-1TcNg7AFUmshg8jIUTzK3r34f_Hwemkr.ttf) format('truetype');
}
@font-face {
  font-family: 'Edu VIC WA NT Beginner';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/eduvicwantbeginner/v2/jiz2RF1BuW9OwcnNPxLl4KfZCHd9nFtd5Tu7stCpElYpvPfZZ-OXlPmF.ttf) format('truetype');
}
@font-face {
  font-family: 'El Messiri';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/elmessiri/v16/K2FhfZBRmr9vQ1pHEey6GIGo8_pv3myYjuXwe65g.ttf) format('truetype');
}
@font-face {
  font-family: 'Electrolize';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/electrolize/v14/cIf5Ma1dtE0zSiGSiED7AUEG.ttf) format('truetype');
}
@font-face {
  font-family: 'Elsie';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/elsie/v12/BCanqZABrez54yYu.ttf) format('truetype');
}
@font-face {
  font-family: 'Elsie Swash Caps';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/elsieswashcaps/v21/845DNN8xGZyVX5MVo_upKf7KnjK0feo.ttf) format('truetype');
}
@font-face {
  font-family: 'Emblema One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/emblemaone/v21/nKKT-GQ0F5dSY8vzG0rOEIRB.ttf) format('truetype');
}
@font-face {
  font-family: 'Emilys Candy';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/emilyscandy/v13/2EbgL-1mD1Rnb0OGKudbk0y5rw.ttf) format('truetype');
}
@font-face {
  font-family: 'Encode Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/encodesans/v15/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGGHjZtU.ttf) format('truetype');
}
@font-face {
  font-family: 'Encode Sans Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/encodesanscondensed/v10/j8_16_LD37rqfuwxyIuaZhE6cRXOLtm2gfTGgQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Encode Sans Expanded';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/encodesansexpanded/v10/c4m_1mF4GcnstG_Jh1QH6ac4hNLeNyeYUqoi.ttf) format('truetype');
}
@font-face {
  font-family: 'Encode Sans SC';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/encodesanssc/v8/jVyp7nLwCGzQ9zE7ZyRg0QRXHPZc_uUA6Kb3VJWLE_Pdtm7lcD6qvXT1HHhm8c8.ttf) format('truetype');
}
@font-face {
  font-family: 'Encode Sans Semi Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/encodesanssemicondensed/v10/3qT4oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG2yR.ttf) format('truetype');
}
@font-face {
  font-family: 'Encode Sans Semi Expanded';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/encodesanssemiexpanded/v18/ke83OhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TC4o.ttf) format('truetype');
}
@font-face {
  font-family: 'Engagement';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/engagement/v22/x3dlckLDZbqa7RUs9MFVXNo.ttf) format('truetype');
}
@font-face {
  font-family: 'Englebert';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/englebert/v17/xn7iYH8w2XGrC8AR4HSxTw.ttf) format('truetype');
}
@font-face {
  font-family: 'Enriqueta';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/enriqueta/v15/goksH6L7AUFrRvV44HVTSw.ttf) format('truetype');
}
@font-face {
  font-family: 'Ephesis';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ephesis/v7/uU9PCBUS8IerL2VG7xM.ttf) format('truetype');
}
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/epilogue/v13/O4ZMFGj5hxF0EhjimngomvnCCtqb30OXMDPiDA.ttf) format('truetype');
}
@font-face {
  font-family: 'Erica One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ericaone/v23/WBLnrEXccV9VGrOKmGD1Ww.ttf) format('truetype');
}
@font-face {
  font-family: 'Esteban';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/esteban/v14/r05bGLZE-bdGdN-GdOs.ttf) format('truetype');
}
@font-face {
  font-family: 'Estonia';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/estonia/v9/7Au_p_4ijSecA1yHCCI.ttf) format('truetype');
}
@font-face {
  font-family: 'Euphoria Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/euphoriascript/v16/mFTpWb0X2bLb_cx6To2B8GpKoD5akw.ttf) format('truetype');
}
@font-face {
  font-family: 'Ewert';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ewert/v21/va9I4kzO2tFODYBv.ttf) format('truetype');
}
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo/v20/4UaZrEtFpBI4f1ZSIK9d4LjJ4lM3CwM.ttf) format('truetype');
}
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo2/v20/7cH1v4okm5zmbvwkAx_sfcEuiD8jvvKcPg.ttf) format('truetype');
}
@font-face {
  font-family: 'Expletus Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/expletussans/v24/RLpqK5v5_bqufTYdnhFzDj2dX_IwS3my73zcDaSY2s1o.ttf) format('truetype');
}
@font-face {
  font-family: 'Explora';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/explora/v7/tsstApxFfjUH4wrvc1o.ttf) format('truetype');
}
@font-face {
  font-family: 'Fahkwang';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/fahkwang/v16/Noax6Uj3zpmBOgbNpNqP.ttf) format('truetype');
}
@font-face {
  font-family: 'Familjen Grotesk';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/familjengrotesk/v4/Qw3LZR9ZHiDnImG6-NEMQ41wby8WRnYsfkunR_eGfMGJaSzt.ttf) format('truetype');
}
@font-face {
  font-family: 'Fanwood Text';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/fanwoodtext/v15/3XFtErwl05Ad_vSCF6Fq7xXGRQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Farro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/farro/v14/i7dEIFl3byGNHZVN.ttf) format('truetype');
}
@font-face {
  font-family: 'Farsan';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/farsan/v18/VEMwRoJ0vY_zsyz62g.ttf) format('truetype');
}
@font-face {
  font-family: 'Fascinate';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/fascinate/v21/z7NWdRrufC8XJK0IIEli1A.ttf) format('truetype');
}
@font-face {
  font-family: 'Fascinate Inline';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/fascinateinline/v22/jVyR7mzzB3zc-jp6QCAu60poNqIy1g0.ttf) format('truetype');
}
@font-face {
  font-family: 'Faster One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/fasterone/v17/H4ciBXCHmdfClFb-vWhfyLs.ttf) format('truetype');
}
@font-face {
  font-family: 'Fasthand';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/fasthand/v26/0yb9GDohyKTYn_ZEESku.ttf) format('truetype');
}
@font-face {
  font-family: 'Fauna One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/faunaone/v13/wlpzgwTPBVpjpCuwkuEx2Q.ttf) format('truetype');
}
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/faustina/v16/XLY4IZPxYpJfTbZAFXWzNT2SO8wpWHlsgoEvGQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Federant';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/federant/v25/2sDdZGNfip_eirT0_U0j.ttf) format('truetype');
}
@font-face {
  font-family: 'Federo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/federo/v19/iJWFBX-cbD_ETsbmjQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Felipa';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/felipa/v19/FwZa7-owz1Eu4F_wSA.ttf) format('truetype');
}
@font-face {
  font-family: 'Fenix';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/fenix/v20/XoHo2YL_S7-g5ost.ttf) format('truetype');
}
@font-face {
  font-family: 'Festive';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/festive/v7/cY9Ffj6KX1xcoDWhFtc.ttf) format('truetype');
}
@font-face {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/figtree/v2/_Xmz-HUzqDCFdgfMsYiV_F7wfS-Bs_d_QF5e.ttf) format('truetype');
}
@font-face {
  font-family: 'Finger Paint';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/fingerpaint/v15/0QInMXVJ-o-oRn_7dron8YWO8w.ttf) format('truetype');
}
@font-face {
  font-family: 'Finlandica';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/finlandica/v5/-nFsOGk-8vAc7lEtg0aSyZCty9GSsPBE19A7rEjx.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firacode/v21/uU9eCBsR6Z2vfE9aq3bL0fxyUs4tcw4W_D1sFVc.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firamono/v14/N0bX2SlFPv1weGeLZDtQIQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v16/va9E4kDNxMZdWfMOD5VfkA.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasanscondensed/v10/wEOhEADFm8hSaQTFG18FErVhsC9x-tarYfE.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPKcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda5fg.ttf) format('truetype');
}
@font-face {
  font-family: 'Fjalla One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/fjallaone/v13/Yq6R-LCAWCX3-6Ky7FAFnOY.ttf) format('truetype');
}
@font-face {
  font-family: 'Fjord One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/fjordone/v21/zOL-4pbEnKBY_9S1jNKr6Q.ttf) format('truetype');
}
@font-face {
  font-family: 'Flamenco';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/flamenco/v18/neIIzCehqYguo67ssaWG.ttf) format('truetype');
}
@font-face {
  font-family: 'Flavors';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/flavors/v22/FBV2dDrhxqmveJTpbkw.ttf) format('truetype');
}
@font-face {
  font-family: 'Fleur De Leah';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/fleurdeleah/v7/AYCNpXX7ftYZWLhv9UmPJTMC5vY.ttf) format('truetype');
}
@font-face {
  font-family: 'Flow Block';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/flowblock/v7/wlp0gwfPCEB65UmTk-d6-WY.ttf) format('truetype');
}
@font-face {
  font-family: 'Flow Circular';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/flowcircular/v7/lJwB-pc4j2F-H8YKuyvfxdZ45ic.ttf) format('truetype');
}
@font-face {
  font-family: 'Flow Rounded';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/flowrounded/v7/-zki91mtwsU9qlLiGwD4oQX3oQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Fondamento';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/fondamento/v16/4UaHrEJGsxNmFTPDnkaJx60.ttf) format('truetype');
}
@font-face {
  font-family: 'Fontdiner Swanky';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/fontdinerswanky/v19/ijwOs4XgRNsiaI5-hcVb4hQgMvCD4uE.ttf) format('truetype');
}
@font-face {
  font-family: 'Forum';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/forum/v16/6aey4Ky-Vb8Ew_IW.ttf) format('truetype');
}
@font-face {
  font-family: 'Fragment Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/fragmentmono/v1/4iCr6K5wfMRRjxp0DA6-2CLnN4Q.ttf) format('truetype');
}
@font-face {
  font-family: 'Francois One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/francoisone/v20/_Xmr-H4zszafZw3A-KPSZutNxg.ttf) format('truetype');
}
@font-face {
  font-family: 'Frank Ruhl Libre';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/frankruhllibre/v12/j8_w6_fAw7jrcalD7oKYNX0QfAnPa7c.ttf) format('truetype');
}
@font-face {
  font-family: 'Fraunces';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/fraunces/v24/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIctxujDg.ttf) format('truetype');
}
@font-face {
  font-family: 'Freckle Face';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/freckleface/v14/AMOWz4SXrmKHCvXTohxY-YI0Uw.ttf) format('truetype');
}
@font-face {
  font-family: 'Fredericka the Great';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/frederickathegreat/v15/9Bt33CxNwt7aOctW2xjbCstzwVKsIBVV-9Sk.ttf) format('truetype');
}
@font-face {
  font-family: 'Fredoka';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/fredoka/v9/X7nP4b87HvSqjb_WIi2yDCRwoQ_k7367_B-i2yQag0-mac3O8SLMFg.ttf) format('truetype');
}
@font-face {
  font-family: 'Fredoka One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/fredokaone/v13/k3kUo8kEI-tA1RRcTZGmTmHB.ttf) format('truetype');
}
@font-face {
  font-family: 'Freehand';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/freehand/v27/cIf-Ma5eqk01VjKTgAmB.ttf) format('truetype');
}
@font-face {
  font-family: 'Fresca';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/fresca/v18/6ae94K--SKgCzbM2Gg.ttf) format('truetype');
}
@font-face {
  font-family: 'Frijole';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/frijole/v14/uU9PCBUR8oakM2BQ7xM.ttf) format('truetype');
}
@font-face {
  font-family: 'Fruktur';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/fruktur/v26/SZc53FHsOru5QYsMfz0.ttf) format('truetype');
}
@font-face {
  font-family: 'Fugaz One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/fugazone/v15/rax_HiWKp9EAITukFslMBA.ttf) format('truetype');
}
@font-face {
  font-family: 'Fuggles';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/fuggles/v8/k3kQo8UEJOlD1hpOTd4.ttf) format('truetype');
}
@font-face {
  font-family: 'Fuzzy Bubbles';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/fuzzybubbles/v5/6qLGKZMbrgv9pwtjPEVNV0F2NnM.ttf) format('truetype');
}
@font-face {
  font-family: 'GFS Didot';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gfsdidot/v15/Jqzh5TybZ9vZMWFssvwiFw.ttf) format('truetype');
}
@font-face {
  font-family: 'GFS Neohellenic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gfsneohellenic/v25/8QIRdiDOrfiq0b7R8O1Iw9WLcY5TLQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Gabriela';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gabriela/v14/qkBWXvsO6sreR8E-b_m-.ttf) format('truetype');
}
@font-face {
  font-family: 'Gaegu';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gaegu/v15/TuGfUVB6Up9NU6ZL.ttf) format('truetype');
}
@font-face {
  font-family: 'Gafata';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gafata/v16/XRXV3I6Cn0VJKon4Mg.ttf) format('truetype');
}
@font-face {
  font-family: 'Galada';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/galada/v14/H4cmBXyGmcjXlUX-8g.ttf) format('truetype');
}
@font-face {
  font-family: 'Galdeano';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/galdeano/v22/uU9MCBoQ4YOqOW1boDPx.ttf) format('truetype');
}
@font-face {
  font-family: 'Galindo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/galindo/v20/HI_KiYMeLqVKqwyuQ5E.ttf) format('truetype');
}
@font-face {
  font-family: 'Gamja Flower';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gamjaflower/v20/6NUR8FiKJg-Pa0rM6uN40Z4kyQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Gantari';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gantari/v1/jVyV7nvyB2HL8iZyDk4GVvSZ5MtC9g0gOj3w.ttf) format('truetype');
}
@font-face {
  font-family: 'Gayathri';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gayathri/v15/MCoQzAb429DbBilWLIA4.ttf) format('truetype');
}
@font-face {
  font-family: 'Gelasio';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gelasio/v10/cIf9MaFfvUQxTTqSxCk.ttf) format('truetype');
}
@font-face {
  font-family: 'Gemunu Libre';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gemunulibre/v8/X7n34bQ6Cfy7jKGXVE_YlqnbEQAFP-PIuTCp0xiJPvQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Genos';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/genos/v6/SlGNmQqPqpUOYTYjacb0Hc91fTwVqkjorQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Gentium Book Basic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gentiumbookbasic/v16/pe0zMJCbPYBVokB1LHA9bbyaQb8ZGjcIVw.ttf) format('truetype');
}
@font-face {
  font-family: 'Gentium Book Plus';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gentiumbookplus/v1/vEFL2-RHBgUK5fbjKxRpbBtJPyRpofKf.ttf) format('truetype');
}
@font-face {
  font-family: 'Gentium Plus';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gentiumplus/v1/Iurd6Ytw-oSPaZ00r2bNe8VpjA.ttf) format('truetype');
}
@font-face {
  font-family: 'Geo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/geo/v19/CSRz4zRZlufVLw.ttf) format('truetype');
}
@font-face {
  font-family: 'Georama';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/georama/v8/MCo5zAn438bIEyxFf6swMnNpvPcUwW4u4yRcDh-ZjxApn9K5GvgtmQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Geostar';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/geostar/v22/sykz-yx4n701VLOftSo.ttf) format('truetype');
}
@font-face {
  font-family: 'Geostar Fill';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/geostarfill/v22/AMOWz4SWuWiXFfjEohxQ9os0Uw.ttf) format('truetype');
}
@font-face {
  font-family: 'Germania One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/germaniaone/v20/Fh4yPjrqIyv2ucM2qzBjeS3ezA.ttf) format('truetype');
}
@font-face {
  font-family: 'Gideon Roman';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gideonroman/v7/e3tmeuGrVOys8sxzZgWlmXogew.ttf) format('truetype');
}
@font-face {
  font-family: 'Gidugu';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gidugu/v21/L0x8DFMkk1Uf6w3RvA.ttf) format('truetype');
}
@font-face {
  font-family: 'Gilda Display';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gildadisplay/v13/t5tmIRoYMoaYG0WEOh7HwMeR7Tk.ttf) format('truetype');
}
@font-face {
  font-family: 'Girassol';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/girassol/v16/JTUUjIo_-DK48laaNC9N.ttf) format('truetype');
}
@font-face {
  font-family: 'Give You Glory';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/giveyouglory/v15/8QIQdiHOgt3vv4LR7ahjw9-XYc1z.ttf) format('truetype');
}
@font-face {
  font-family: 'Glass Antiqua';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/glassantiqua/v20/xfu30Wr0Wn3NOQM2piC0uXOjnL8.ttf) format('truetype');
}
@font-face {
  font-family: 'Glegoo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/glegoo/v14/_Xmt-HQyrTKWaw2Jiw.ttf) format('truetype');
}
@font-face {
  font-family: 'Gloria Hallelujah';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gloriahallelujah/v17/LYjYdHv3kUk9BMV96EIswT9DIbW-MLSy.ttf) format('truetype');
}
@font-face {
  font-family: 'Glory';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/glory/v9/q5uasoi9Lf1w5t3Est24nq9blIRQwImDpg.ttf) format('truetype');
}
@font-face {
  font-family: 'Gluten';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gluten/v9/Hhy_U5gk9fW7OUdVIPh2zD_RSqQJ__A15jgJsn-Bhb_yI8Vb7R1L.ttf) format('truetype');
}
@font-face {
  font-family: 'Goblin One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/goblinone/v22/CSR64z1ZnOqZRjRCBVY_TOc.ttf) format('truetype');
}
@font-face {
  font-family: 'Gochi Hand';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gochihand/v16/hES06XlsOjtJsgCkx1PkTo4.ttf) format('truetype');
}
@font-face {
  font-family: 'Goldman';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/goldman/v15/pe0uMIWbN4JFplR2LDI.ttf) format('truetype');
}
@font-face {
  font-family: 'Gorditas';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gorditas/v20/ll8_K2aTVD26DsPEtQDo.ttf) format('truetype');
}
@font-face {
  font-family: 'Gothic A1';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gothica1/v13/CSR94z5ZnPydRjlCCwl6bA.ttf) format('truetype');
}
@font-face {
  font-family: 'Gotu';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gotu/v14/o-0FIpksx3QOlH0.ttf) format('truetype');
}
@font-face {
  font-family: 'Goudy Bookletter 1911';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/goudybookletter1911/v15/sykt-z54laciWfKv-kX8krex0jDiD2HbY6I5tQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Gowun Batang';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gowunbatang/v7/ijwSs5nhRMIjYsdSgcMa3wRhXA.ttf) format('truetype');
}
@font-face {
  font-family: 'Gowun Dodum';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gowundodum/v7/3Jn5SD_00GqwlBnWc1TUJF0F.ttf) format('truetype');
}
@font-face {
  font-family: 'Graduate';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/graduate/v13/C8cg4cs3o2n15t_2YxgR.ttf) format('truetype');
}
@font-face {
  font-family: 'Grand Hotel';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/grandhotel/v13/7Au7p_IgjDKdCRWuR1azpmQN.ttf) format('truetype');
}
@font-face {
  font-family: 'Grandstander';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/grandstander/v11/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQD1--D3g.ttf) format('truetype');
}
@font-face {
  font-family: 'Grape Nuts';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/grapenuts/v2/syk2-yF4iLM2RfKj4F7k3tI.ttf) format('truetype');
}
@font-face {
  font-family: 'Gravitas One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gravitasone/v15/5h1diZ4hJ3cblKy3LWakKQmaDQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Great Vibes';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/greatvibes/v14/RWmMoKWR9v4ksMfaWd_JN-XC.ttf) format('truetype');
}
@font-face {
  font-family: 'Grechen Fuemen';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/grechenfuemen/v7/vEFI2_tHEQ4d5ObgKxBzZh0MAWgc.ttf) format('truetype');
}
@font-face {
  font-family: 'Grenze';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/grenze/v14/O4ZTFGb7hR12Bxq3_w.ttf) format('truetype');
}
@font-face {
  font-family: 'Grenze Gotisch';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/grenzegotisch/v12/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i5Lz9UcA.ttf) format('truetype');
}
@font-face {
  font-family: 'Grey Qo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/greyqo/v7/BXRrvF_Nmv_TyXxNDOs.ttf) format('truetype');
}
@font-face {
  font-family: 'Griffy';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/griffy/v21/FwZa7-ox2FQh9kfwSA.ttf) format('truetype');
}
@font-face {
  font-family: 'Gruppo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gruppo/v16/WwkfxPmzE06v_ZWFWQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Gudea';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gudea/v15/neIFzCqgsI0mp-CP.ttf) format('truetype');
}
@font-face {
  font-family: 'Gugi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gugi/v13/A2BVn5dXywshVA4.ttf) format('truetype');
}
@font-face {
  font-family: 'Gulzar';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gulzar/v5/Wnz6HAc9eB3HB2ILYQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Gupter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gupter/v14/2-cm9JNmxJqPO1QUYQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Gurajada';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gurajada/v15/FwZY7-Qx308m-l-0Kd6A.ttf) format('truetype');
}
@font-face {
  font-family: 'Gwendolyn';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gwendolyn/v5/qkBXXvoO_M3CSss-d7ee5A.ttf) format('truetype');
}
@font-face {
  font-family: 'Habibi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/habibi/v21/CSR-4zFWkuqcTTNCSg.ttf) format('truetype');
}
@font-face {
  font-family: 'Hachi Maru Pop';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/hachimarupop/v17/HI_TiYoRLqpLrEiMAuO9Ysfz7rW1.ttf) format('truetype');
}
@font-face {
  font-family: 'Hahmlet';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/hahmlet/v9/BngXUXpCQ3nKpIo0TfPyfCdXfaeU4RhKONjo.ttf) format('truetype');
}
@font-face {
  font-family: 'Halant';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/halant/v13/u-4-0qaujRI2PbsX3w.ttf) format('truetype');
}
@font-face {
  font-family: 'Hammersmith One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/hammersmithone/v17/qWcyB624q4L_C4jGQ9IK0O_dFlnbsg.ttf) format('truetype');
}
@font-face {
  font-family: 'Hanalei';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/hanalei/v23/E21n_dD8iufIjBRHXzg.ttf) format('truetype');
}
@font-face {
  font-family: 'Hanalei Fill';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/hanaleifill/v21/fC1mPYtObGbfyQznIaQzPQiMVw.ttf) format('truetype');
}
@font-face {
  font-family: 'Handlee';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/handlee/v14/-F6xfjBsISg9aMakDmo.ttf) format('truetype');
}
@font-face {
  font-family: 'Hanuman';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/hanuman/v22/VuJxdNvD15HhpJJBeKY.ttf) format('truetype');
}
@font-face {
  font-family: 'Happy Monkey';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/happymonkey/v14/K2F2fZZcl-9SXwl5F_C4R_OABw.ttf) format('truetype');
}
@font-face {
  font-family: 'Harmattan';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/harmattan/v17/goksH6L2DkFvVvRp9XpTSw.ttf) format('truetype');
}
@font-face {
  font-family: 'Headland One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/headlandone/v15/yYLu0hHR2vKnp89Tk1TCq3Tx0A.ttf) format('truetype');
}
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/heebo/v21/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSyccg.ttf) format('truetype');
}
@font-face {
  font-family: 'Henny Penny';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/hennypenny/v17/wXKvE3UZookzsxz_kjGSfMQq.ttf) format('truetype');
}
@font-face {
  font-family: 'Hepta Slab';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/heptaslab/v17/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5HvkV5zfb.ttf) format('truetype');
}
@font-face {
  font-family: 'Herr Von Muellerhoff';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/herrvonmuellerhoff/v15/WBL6rFjRZkREW8WqmCWYLgCkQKXb4CAft3c6.ttf) format('truetype');
}
@font-face {
  font-family: 'Hi Melody';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/himelody/v13/46ktlbP8Vnz0pJcqCTbEfw.ttf) format('truetype');
}
@font-face {
  font-family: 'Hina Mincho';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/hinamincho/v8/2sDaZGBRhpXa2Jjz5w5LAGW8.ttf) format('truetype');
}
@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/hind/v16/5aU69_a8oxmIRG4.ttf) format('truetype');
}
@font-face {
  font-family: 'Hind Guntur';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/hindguntur/v12/wXKvE3UZrok56nvamSuJd8Qq.ttf) format('truetype');
}
@font-face {
  font-family: 'Hind Madurai';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/hindmadurai/v11/f0Xx0e2p98ZvDXdZQIOcpqjn8Q.ttf) format('truetype');
}
@font-face {
  font-family: 'Hind Siliguri';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/hindsiliguri/v12/ijwTs5juQtsyLLR5jN4cxBEofJs.ttf) format('truetype');
}
@font-face {
  font-family: 'Hind Vadodara';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/hindvadodara/v12/neINzCKvrIcn5pbuuuriV9tTcJU.ttf) format('truetype');
}
@font-face {
  font-family: 'Holtwood One SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/holtwoodonesc/v16/yYLx0hLR0P-3vMFSk1TCq3Txg5B3cQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Homemade Apple';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/homemadeapple/v18/Qw3EZQFXECDrI2q789EKQZJob3x9.ttf) format('truetype');
}
@font-face {
  font-family: 'Homenaje';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/homenaje/v16/FwZY7-Q-xVAi_l-6Ld6A.ttf) format('truetype');
}
@font-face {
  font-family: 'Hubballi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/hubballi/v4/o-0JIpUj3WIZ1RFN56B7.ttf) format('truetype');
}
@font-face {
  font-family: 'Hurricane';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/hurricane/v5/pe0sMIuULZxTolZ5YldyAg.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexmono/v12/-F63fjptAgt5VM-kVkqdyU8n5ig.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsans/v14/zYXgKVElMYYaJe8bpLHnCwDKtdY.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans Arabic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsansarabic/v9/Qw3CZRtWPQCuHme67tEYUIx3Kh0PHR9N6bs6.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsanscondensed/v13/Gg8lN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHbau.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans Devanagari';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsansdevanagari/v9/XRXH3JCMvG4IDoS9SubXB6W-UX5iehIMBFR2-O__VQ.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsanshebrew/v10/BCa2qYENg9Kw1mpLpO0bGM5lfHAAZHhDXH2l.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans JP';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsansjp/v3/Z9XNDn9KbTDf6_f7dISNqYf_tvPT1Co.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans KR';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsanskr/v9/vEFK2-VJISZe3O_rc3ZVYh4aTwNO8tI.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans Thai';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsansthai/v9/m8JPje1VVIzcq1HzJq2AEdo2Tj_qvLq8Dg.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans Thai Looped';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsansthailooped/v9/tss_AoJJRAhL3BTrK3r2xxbFhvKfyBB6l7hHT30LxBI.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexserif/v15/jizDREVNn1dOx-zrZ2X3pZvkThUY.ttf) format('truetype');
}
@font-face {
  font-family: 'IM Fell DW Pica';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/imfelldwpica/v16/2sDGZGRQotv9nbn2qSl0TxXVYNw9ZA.ttf) format('truetype');
}
@font-face {
  font-family: 'IM Fell DW Pica SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/imfelldwpicasc/v21/0ybjGCAu5PfqkvtGVU15aBhXz3EUrnTW-A.ttf) format('truetype');
}
@font-face {
  font-family: 'IM Fell Double Pica';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/imfelldoublepica/v14/3XF2EqMq_94s9PeKF7Fg4gOKINyMtZ8rT0Q.ttf) format('truetype');
}
@font-face {
  font-family: 'IM Fell Double Pica SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/imfelldoublepicasc/v21/neIazDmuiMkFo6zj_sHpQ8teNbWlwBB_hXjJ4Y0.ttf) format('truetype');
}
@font-face {
  font-family: 'IM Fell English';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/imfellenglish/v14/Ktk1ALSLW8zDe0rthJysWrnLsAz3Fw.ttf) format('truetype');
}
@font-face {
  font-family: 'IM Fell English SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/imfellenglishsc/v16/a8IENpD3CDX-4zrWfr1VY879qFF05pZLOw.ttf) format('truetype');
}
@font-face {
  font-family: 'IM Fell French Canon';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/imfellfrenchcanon/v21/-F6ufiNtDWYfYc-tDiyiw08rrghJszkK6coV.ttf) format('truetype');
}
@font-face {
  font-family: 'IM Fell French Canon SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/imfellfrenchcanonsc/v22/FBVmdCru5-ifcor2bgq9V89khWcmQghEURY7H3c0.ttf) format('truetype');
}
@font-face {
  font-family: 'IM Fell Great Primer';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/imfellgreatprimer/v21/bx6aNwSJtayYxOkbYFsT6hMsLzX7u85rJorX.ttf) format('truetype');
}
@font-face {
  font-family: 'IM Fell Great Primer SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/imfellgreatprimersc/v21/ga6daxBOxyt6sCqz3fjZCTFCTUDMHagsQKdDTLf9.ttf) format('truetype');
}
@font-face {
  font-family: 'Ibarra Real Nova';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibarrarealnova/v20/sZlSdQiA-DBIDCcaWtQzL4BZHoiDundw4ATyjed3EXdg5MDt.ttf) format('truetype');
}
@font-face {
  font-family: 'Iceberg';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/iceberg/v20/8QIJdijAiM7o-qnZuIg.ttf) format('truetype');
}
@font-face {
  font-family: 'Iceland';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/iceland/v16/rax9HiuFsdMNOnWPWKw.ttf) format('truetype');
}
@font-face {
  font-family: 'Imbue';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/imbue/v21/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoP8iXfOs.ttf) format('truetype');
}
@font-face {
  font-family: 'Imperial Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/imperialscript/v3/5DCPAKrpzy_H98IV2ISnZBbGrVNvPQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Imprima';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/imprima/v16/VEMxRoN7sY3yuy-7-oU.ttf) format('truetype');
}
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inconsolata/v31/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lpp4U8aRo.ttf) format('truetype');
}
@font-face {
  font-family: 'Inder';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inder/v14/w8gUH2YoQe8_4vq6.ttf) format('truetype');
}
@font-face {
  font-family: 'Indie Flower';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/indieflower/v17/m8JVjfNVeKWVnh3QMuKkFcZlbg.ttf) format('truetype');
}
@font-face {
  font-family: 'Ingrid Darling';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ingriddarling/v2/LDIrapaJNxUtSuFdw-9yf4rCPsLO.ttf) format('truetype');
}
@font-face {
  font-family: 'Inika';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inika/v21/rnCm-x5X3QP-phTH.ttf) format('truetype');
}
@font-face {
  font-family: 'Inknut Antiqua';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inknutantiqua/v14/Y4GSYax7VC4ot_qNB4nYpBdaKXUD.ttf) format('truetype');
}
@font-face {
  font-family: 'Inria Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inriasans/v14/ptRMTiqXYfZMCOiVj9kQ5O4.ttf) format('truetype');
}
@font-face {
  font-family: 'Inria Serif';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inriaserif/v14/fC1lPYxPY3rXxEndZJAzN0Ss.ttf) format('truetype');
}
@font-face {
  font-family: 'Inspiration';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inspiration/v3/x3dkckPPZa6L4wIg5cZOEvoG.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZg.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter Tight';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/intertight/v1/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mjDw-qXA.ttf) format('truetype');
}
@font-face {
  font-family: 'Irish Grover';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/irishgrover/v23/buExpoi6YtLz2QW7LA4flVgf-A.ttf) format('truetype');
}
@font-face {
  font-family: 'Island Moments';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/islandmoments/v3/NaPBcZfVGvBdxIt7Ar0qzkXJF-TG.ttf) format('truetype');
}
@font-face {
  font-family: 'Istok Web';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/istokweb/v20/3qTvojGmgSyUukBzKslZAQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Italiana';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/italiana/v16/QldNNTtLsx4E__B0XTmR.ttf) format('truetype');
}
@font-face {
  font-family: 'Italianno';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/italianno/v16/dg4n_p3sv6gCJkwzT6Rnjw.ttf) format('truetype');
}
@font-face {
  font-family: 'Itim';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/itim/v10/0nknC9ziJOYewAQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Jacques Francois';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jacquesfrancois/v20/ZXu9e04ZvKeOOHIe1TMahbcIU2cgmcM.ttf) format('truetype');
}
@font-face {
  font-family: 'Jacques Francois Shadow';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jacquesfrancoisshadow/v21/KR1FBtOz8PKTMk-kqdkLVrvR0ECFrB6Pin-2_q8V.ttf) format('truetype');
}
@font-face {
  font-family: 'Jaldi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jaldi/v12/or3sQ67z0_CI30NU.ttf) format('truetype');
}
@font-face {
  font-family: 'JetBrains Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jetbrainsmono/v13/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8yKxjPQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Jim Nightshade';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jimnightshade/v20/PlIkFlu9Pb08Q8HLM1PxmB0g-OS4.ttf) format('truetype');
}
@font-face {
  font-family: 'Joan';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/joan/v1/ZXupe1oZsqWRbRc.ttf) format('truetype');
}
@font-face {
  font-family: 'Jockey One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jockeyone/v15/HTxpL2g2KjCFj4x8WI6ArIY.ttf) format('truetype');
}
@font-face {
  font-family: 'Jolly Lodger';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jollylodger/v20/BXRsvFTAh_bGkA1uQ48dlB3VWQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Jomhuria';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jomhuria/v18/Dxxp8j-TMXf-llKur2b1.ttf) format('truetype');
}
@font-face {
  font-family: 'Jomolhari';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jomolhari/v14/EvONzA1M1Iw_CBd2hsQCFw.ttf) format('truetype');
}
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_DjQXME.ttf) format('truetype');
}
@font-face {
  font-family: 'Josefin Slab';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinslab/v20/lW-swjwOK3Ps5GSJlNNkMalNpiZe_ldbOR4W71msd3k.ttf) format('truetype');
}
@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v14/92zPtBhPNqw79Ij1E865zBUv7myjJQVG.ttf) format('truetype');
}
@font-face {
  font-family: 'Joti One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jotione/v21/Z9XVDmdJQAmWm9TwaYTe.ttf) format('truetype');
}
@font-face {
  font-family: 'Jua';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jua/v13/co3KmW9ljjAjcw.ttf) format('truetype');
}
@font-face {
  font-family: 'Judson';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/judson/v18/FeVRS0Fbvbc14VxRDw.ttf) format('truetype');
}
@font-face {
  font-family: 'Julee';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/julee/v22/TuGfUVB3RpZPQ6ZL.ttf) format('truetype');
}
@font-face {
  font-family: 'Julius Sans One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/juliussansone/v14/1Pt2g8TAX_SGgBGUi0tGOYEga5W-xQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Junge';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/junge/v20/gokgH670Gl1lUqAd.ttf) format('truetype');
}
@font-face {
  font-family: 'Jura';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jura/v24/z7NOdRfiaC4Vd8hhoPzfb5vBTP1d7auh.ttf) format('truetype');
}
@font-face {
  font-family: 'Just Another Hand';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/justanotherhand/v19/845CNN4-AJyIGvIou-6yJKyptyOpOcr_.ttf) format('truetype');
}
@font-face {
  font-family: 'Just Me Again Down Here';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/justmeagaindownhere/v24/MwQmbgXtz-Wc6RUEGNMc0QpRrfUh2hSdBBMoAuwH.ttf) format('truetype');
}
@font-face {
  font-family: 'K2D';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/k2d/v9/J7aTnpF2V0ETdw.ttf) format('truetype');
}
@font-face {
  font-family: 'Kadwa';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kadwa/v10/rnCm-x5V0g7iphTH.ttf) format('truetype');
}
@font-face {
  font-family: 'Kaisei Decol';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kaiseidecol/v8/bMrwmSqP45sidWf3QmfFW6iyWw.ttf) format('truetype');
}
@font-face {
  font-family: 'Kaisei HarunoUmi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kaiseiharunoumi/v8/HI_RiZQSLqBQoAHhK_C6N_nzy_jcGss.ttf) format('truetype');
}
@font-face {
  font-family: 'Kaisei Opti';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kaiseiopti/v8/QldKNThJphYb8_g6c2nlIFle.ttf) format('truetype');
}
@font-face {
  font-family: 'Kaisei Tokumin';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kaiseitokumin/v8/Gg8sN5wdZg7xCwuMsylww2ZiQkJf.ttf) format('truetype');
}
@font-face {
  font-family: 'Kalam';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kalam/v16/YA9dr0Wd4kDdMuhW.ttf) format('truetype');
}
@font-face {
  font-family: 'Kameron';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kameron/v15/vm82dR7vXErQxuznsL4.ttf) format('truetype');
}
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kanit/v12/nKKZ-Go6G5tXcoaS.ttf) format('truetype');
}
@font-face {
  font-family: 'Kantumruy';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kantumruy/v21/sykx-yJ0m7wyVb-f4FO3_g.ttf) format('truetype');
}
@font-face {
  font-family: 'Kantumruy Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kantumruypro/v3/1q2TY5aECkp34vEBSPFOmJxwvk_pilU8OGNfyg1urEs0.ttf) format('truetype');
}
@font-face {
  font-family: 'Karantina';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/karantina/v11/buE0po24ccnh31GVMABJ8A.ttf) format('truetype');
}
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/karla/v23/qkBIXvYC6trAT55ZBi1ueQVIjQTD-JqqFA.ttf) format('truetype');
}
@font-face {
  font-family: 'Karma';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/karma/v16/va9I4kzAzMZRGIBv.ttf) format('truetype');
}
@font-face {
  font-family: 'Katibeh';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/katibeh/v17/ZGjXol5MQJog4bxDaC0.ttf) format('truetype');
}
@font-face {
  font-family: 'Kaushan Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kaushanscript/v14/vm8vdRfvXFLG3OLnsO15WYS5DF7_.ttf) format('truetype');
}
@font-face {
  font-family: 'Kavivanar';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kavivanar/v18/o-0IIpQgyXYSwhxP7_Jb4g.ttf) format('truetype');
}
@font-face {
  font-family: 'Kavoon';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kavoon/v21/pxiFyp4_scRYhlU4NA.ttf) format('truetype');
}
@font-face {
  font-family: 'Kdam Thmor Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kdamthmorpro/v1/EJRPQgAzVdcI-Qdvt34jzurnGA7_.ttf) format('truetype');
}
@font-face {
  font-family: 'Keania One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/keaniaone/v20/zOL54pXJk65E8pXardnuycQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Kelly Slab';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kellyslab/v15/-W_7XJX0Rz3cxUnJC5t6TkM.ttf) format('truetype');
}
@font-face {
  font-family: 'Kenia';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kenia/v24/jizURE5PuHQH9qCO.ttf) format('truetype');
}
@font-face {
  font-family: 'Khand';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/khand/v14/TwMA-IINQlQQ0YpV.ttf) format('truetype');
}
@font-face {
  font-family: 'Khmer';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/khmer/v25/MjQImit_vPPwpF-B.ttf) format('truetype');
}
@font-face {
  font-family: 'Khula';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/khula/v12/OpNCnoEOns3V7FcJ.ttf) format('truetype');
}
@font-face {
  font-family: 'Kings';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kings/v5/8AtnGsK4O5CYXU_I.ttf) format('truetype');
}
@font-face {
  font-family: 'Kirang Haerang';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kiranghaerang/v20/E21-_dn_gvvIjhYON1lpIU4-bcqv.ttf) format('truetype');
}
@font-face {
  font-family: 'Kite One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kiteone/v20/70lQu7shLnA_E02vyq1b.ttf) format('truetype');
}
@font-face {
  font-family: 'Kiwi Maru';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kiwimaru/v14/R70YjykGkuuDep-hRg6YmA.ttf) format('truetype');
}
@font-face {
  font-family: 'Klee One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kleeone/v7/LDIxapCLNRc6A8oT4q4A.ttf) format('truetype');
}
@font-face {
  font-family: 'Knewave';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/knewave/v14/sykz-yx0lLcxQaSItSo.ttf) format('truetype');
}
@font-face {
  font-family: 'KoHo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/koho/v16/K2F-fZ5fmddNBik.ttf) format('truetype');
}
@font-face {
  font-family: 'Kodchasan';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kodchasan/v16/1cXxaUPOAJv9sG4I-DJmjw.ttf) format('truetype');
}
@font-face {
  font-family: 'Koh Santepheap';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kohsantepheap/v9/gNMdW3p6SJbwyGj2rBZyeOrTjOPh.ttf) format('truetype');
}
@font-face {
  font-family: 'Kolker Brush';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kolkerbrush/v3/iJWDBXWRZjfKWdvmzwvvog3-7A.ttf) format('truetype');
}
@font-face {
  font-family: 'Kosugi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kosugi/v14/pxiFyp4_v8FCjlI4NA.ttf) format('truetype');
}
@font-face {
  font-family: 'Kosugi Maru';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kosugimaru/v14/0nksC9PgP_wGh21A2KeqGiTq.ttf) format('truetype');
}
@font-face {
  font-family: 'Kotta One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kottaone/v20/S6u_w41LXzPc_jlfNWqPHA.ttf) format('truetype');
}
@font-face {
  font-family: 'Koulen';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/koulen/v25/AMOQz46as3KIBPeWgg.ttf) format('truetype');
}
@font-face {
  font-family: 'Kranky';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kranky/v24/hESw6XVgJzlPsFnMpg.ttf) format('truetype');
}
@font-face {
  font-family: 'Kreon';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kreon/v32/t5t9IRIUKY-TFF_LW5lnMR3v2DnvYtimeg.ttf) format('truetype');
}
@font-face {
  font-family: 'Kristi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kristi/v17/uK_y4ricdeU6zwdRCg.ttf) format('truetype');
}
@font-face {
  font-family: 'Krona One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kronaone/v14/jAnEgHdjHcjgfIb1ZcUCMQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Krub';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/krub/v9/sZlLdRyC6CRYXkY.ttf) format('truetype');
}
@font-face {
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kufam/v20/C8c-4cY7pG7w_oSJDszBXsKCcBH3lqk7qQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Kulim Park';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kulimpark/v14/fdN79secq3hflz1Uu3IwtF4.ttf) format('truetype');
}
@font-face {
  font-family: 'Kumar One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kumarone/v17/bMr1mS-P958wYi6YaGeGNA.ttf) format('truetype');
}
@font-face {
  font-family: 'Kumar One Outline';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kumaroneoutline/v17/Noao6VH62pyLP0fsrZ-v18wlUEcX9zDw.ttf) format('truetype');
}
@font-face {
  font-family: 'Kumbh Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kumbhsans/v12/c4mw1n92AsfhuCq6tVsaoIx1CHIi4kToNorqSiNI.ttf) format('truetype');
}
@font-face {
  font-family: 'Kurale';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kurale/v11/4iCs6KV9e9dXjho6eA.ttf) format('truetype');
}
@font-face {
  font-family: 'La Belle Aurore';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/labelleaurore/v16/RrQIbot8-mNYKnGNDkWlocovHeIIGw.ttf) format('truetype');
}
@font-face {
  font-family: 'Lacquer';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lacquer/v15/EYqzma1QwqpG4_BBB78.ttf) format('truetype');
}
@font-face {
  font-family: 'Laila';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/laila/v13/LYjMdG_8nE8jDIRd.ttf) format('truetype');
}
@font-face {
  font-family: 'Lakki Reddy';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lakkireddy/v19/S6u5w49MUSzD9jlCPmvLZQfo.ttf) format('truetype');
}
@font-face {
  font-family: 'Lalezar';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lalezar/v14/zrfl0HLVx-HwTP82UaA.ttf) format('truetype');
}
@font-face {
  font-family: 'Lancelot';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lancelot/v22/J7acnppxBGtQEulG4JY4.ttf) format('truetype');
}
@font-face {
  font-family: 'Langar';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/langar/v26/kJEyBukW7AIlgjGVrQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Lateef';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lateef/v27/hESw6XVnNCxEvkbMpg.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v23/S6uyw4BMUTPHvxk.ttf) format('truetype');
}
@font-face {
  font-family: 'Lavishly Yours';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lavishlyyours/v2/jizDREVIvGwH5OjiZmX9r5z_WxUY.ttf) format('truetype');
}
@font-face {
  font-family: 'League Gothic';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/leaguegothic/v6/qFdR35CBi4tvBz81xy7WG7ep-BQAY7Krj7feObpH_-am.ttf) format('truetype');
}
@font-face {
  font-family: 'League Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/leaguescript/v24/CSR54zpSlumSWj9CGVsoBZdeaNM.ttf) format('truetype');
}
@font-face {
  font-family: 'League Spartan';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/leaguespartan/v6/kJEnBuEW6A0lliaV_m88ja5Twtx8BWhtkDVmjZvM_oTpBA.ttf) format('truetype');
}
@font-face {
  font-family: 'Leckerli One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/leckerlione/v16/V8mCoQH8VCsNttEnxnGQ-1itLQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Ledger';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ledger/v16/j8_q6-HK1L3if_sxmw.ttf) format('truetype');
}
@font-face {
  font-family: 'Lekton';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lekton/v17/SZc43FDmLaWmWpBeXw.ttf) format('truetype');
}
@font-face {
  font-family: 'Lemon';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lemon/v14/HI_EiYEVKqRMq0jB.ttf) format('truetype');
}
@font-face {
  font-family: 'Lemonada';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lemonada/v20/0QI-MXFD9oygTWy_R-FFlwV-bgfR7QJGeut2mQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lexend/v17/wlptgwvFAVdoq2_F94zlCfv0bz1WCzsW_LA.ttf) format('truetype');
}
@font-face {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lexenddeca/v17/K2FifZFYk-dHSE0UPPuwQ7CrD94i-NCKm-U48M1ArA.ttf) format('truetype');
}
@font-face {
  font-family: 'Lexend Exa';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lexendexa/v24/UMBCrPdOoHOnxExyjdBeQCH18mulUxBvI9r7TqbH.ttf) format('truetype');
}
@font-face {
  font-family: 'Lexend Giga';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lexendgiga/v24/PlIuFl67Mah5Y8yMHE7lkUZPlTBo4MWFfNRC2LiE6w.ttf) format('truetype');
}
@font-face {
  font-family: 'Lexend Mega';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lexendmega/v24/qFdX35aBi5JtHD41zSTFEuTByuvYFuE9IbDL8fmvvQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Lexend Peta';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lexendpeta/v24/BXR4vFPGjeLPh0kCfI4OkFX-UTQHSCaxvBgR6SByWw.ttf) format('truetype');
}
@font-face {
  font-family: 'Lexend Tera';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lexendtera/v24/RrQDbo98_jt_IXnBPwCWtYJLZ3P4hnaGKFiM5zMTdg.ttf) format('truetype');
}
@font-face {
  font-family: 'Lexend Zetta';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lexendzetta/v24/ll8uK2KYXje7CdOFnEWcU8synQbuVYjYB3BCy9bG0z4.ttf) format('truetype');
}
@font-face {
  font-family: 'Libre Barcode 128';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/librebarcode128/v26/cIfnMbdUsUoiW3O_hVviCwVjuLtXeJ_A.ttf) format('truetype');
}
@font-face {
  font-family: 'Libre Barcode 128 Text';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/librebarcode128text/v26/fdNv9tubt3ZEnz1Gu3I4-zppwZ9CWZ16Z0w5cV0.ttf) format('truetype');
}
@font-face {
  font-family: 'Libre Barcode 39';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/librebarcode39/v19/-nFnOHM08vwC6h8Li1eQnP_AHzI2K_c.ttf) format('truetype');
}
@font-face {
  font-family: 'Libre Barcode 39 Extended';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/librebarcode39extended/v25/8At7Gt6_O5yNS0-K4Nf5U922qSzhJ3dUdfJpwNUgfNQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Libre Barcode 39 Extended Text';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/librebarcode39extendedtext/v25/eLG1P_rwIgOiDA7yrs9LoKaYRVLQ1YldrrOnnL7xPO4jNP68fA.ttf) format('truetype');
}
@font-face {
  font-family: 'Libre Barcode 39 Text';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/librebarcode39text/v26/sJoa3KhViNKANw_E3LwoDXvs5Un0HQ1vT-031Q.ttf) format('truetype');
}
@font-face {
  font-family: 'Libre Barcode EAN13 Text';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/librebarcodeean13text/v19/wlpigxXFDU1_oCu9nfZytgIqSG0XRcJm_OQiB96PAA.ttf) format('truetype');
}
@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/librebaskerville/v14/kmKnZrc3Hgbbcjq75U4uslyuy4kn0pNe.ttf) format('truetype');
}
@font-face {
  font-family: 'Libre Bodoni';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/librebodoni/v3/_Xm--H45qDWDYULr5OfyZudXzSBgY2oMBGte6I1fwWw.ttf) format('truetype');
}
@font-face {
  font-family: 'Libre Caslon Display';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/librecaslondisplay/v14/TuGOUUFxWphYQ6YI6q9Xp61FQzxDRKmzr2lR.ttf) format('truetype');
}
@font-face {
  font-family: 'Libre Caslon Text';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/librecaslontext/v3/DdT878IGsGw1aF1JU10PUbTvNNaDMcq_.ttf) format('truetype');
}
@font-face {
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/librefranklin/v13/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhLsWUBw.ttf) format('truetype');
}
@font-face {
  font-family: 'Licorice';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/licorice/v3/t5tjIR8TMomTCAyjNk23.ttf) format('truetype');
}
@font-face {
  font-family: 'Life Savers';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lifesavers/v18/ZXuie1UftKKabUQMgxAal_lr.ttf) format('truetype');
}
@font-face {
  font-family: 'Lilita One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lilitaone/v13/i7dPIFZ9Zz-WBtRtedDbUEY.ttf) format('truetype');
}
@font-face {
  font-family: 'Lily Script One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lilyscriptone/v15/LhW9MV7ZMfIPdMxeBjBvFN8SXLS4gg.ttf) format('truetype');
}
@font-face {
  font-family: 'Limelight';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/limelight/v16/XLYkIZL7aopJVbZJHDuYPQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Linden Hill';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lindenhill/v22/-F61fjxoKSg9Yc3hZgO8ygFI.ttf) format('truetype');
}
@font-face {
  font-family: 'Literata';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/literata/v30/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbBG_F_Y.ttf) format('truetype');
}
@font-face {
  font-family: 'Liu Jian Mao Cao';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/liujianmaocao/v15/~ChIKEExpdSBKaWFuIE1hbyBDYW8gAA==.ttf) format('truetype');
}
@font-face {
  font-family: 'Livvic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/livvic/v13/rnCp-x1S2hzjrlfnbw.ttf) format('truetype');
}
@font-face {
  font-family: 'Lobster';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lobster/v28/neILzCirqoswsqX9_oU.ttf) format('truetype');
}
@font-face {
  font-family: 'Lobster Two';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lobstertwo/v18/BngMUXZGTXPUvIoyV6yN59fK.ttf) format('truetype');
}
@font-face {
  font-family: 'Londrina Outline';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/londrinaoutline/v23/C8c44dM8vmb14dfsZxhetg3pDH-Sfuo.ttf) format('truetype');
}
@font-face {
  font-family: 'Londrina Shadow';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/londrinashadow/v22/oPWX_kB4kOQoWNJmjxLV5JuoCUlXRg.ttf) format('truetype');
}
@font-face {
  font-family: 'Londrina Sketch';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/londrinasketch/v21/c4m41npxGMTnomOHtRU68eIJn8qfWQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Londrina Solid';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/londrinasolid/v15/flUhRq6sw40kQEJxWNgkLuudGcNZ.ttf) format('truetype');
}
@font-face {
  font-family: 'Long Cang';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/longcang/v17/LYjAdGP8kkgoTec8zkRgrQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lora/v26/0QI6MX1D_JOuGQbT0gvTJPa787weuyJG.ttf) format('truetype');
}
@font-face {
  font-family: 'Love Light';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lovelight/v3/t5tlIR0TNJyZWimpNAXDjKY.ttf) format('truetype');
}
@font-face {
  font-family: 'Love Ya Like A Sister';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/loveyalikeasister/v16/R70EjzUBlOqPeouhFDfR80-0FhOqJubN-Be78g.ttf) format('truetype');
}
@font-face {
  font-family: 'Loved by the King';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lovedbytheking/v17/Gw6gwdP76VDVJNXerebZxUMeRXUF2PiN.ttf) format('truetype');
}
@font-face {
  font-family: 'Lovers Quarrel';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/loversquarrel/v21/Yq6N-LSKXTL-5bCy8ksBzpQ_-zAs.ttf) format('truetype');
}
@font-face {
  font-family: 'Luckiest Guy';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/luckiestguy/v18/_gP_1RrxsjcxVyin9l9n_j2RSg.ttf) format('truetype');
}
@font-face {
  font-family: 'Lusitana';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lusitana/v13/CSR84z9ShvucWzsMKxha.ttf) format('truetype');
}
@font-face {
  font-family: 'Lustria';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lustria/v13/9oRONYodvDEyjuhOrCg.ttf) format('truetype');
}
@font-face {
  font-family: 'Luxurious Roman';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/luxuriousroman/v3/buEupou_ZcP1w0yTKxJJokVSmbpqYg.ttf) format('truetype');
}
@font-face {
  font-family: 'Luxurious Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/luxuriousscript/v5/ahcCv9e7yydulT32KZ0rBIoD7DzMg0o.ttf) format('truetype');
}
@font-face {
  font-family: 'M PLUS 1';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mplus1/v6/R70EjygA28ymD4HgBUGzkN5Eyoj-WpW5VSa78g.ttf) format('truetype');
}
@font-face {
  font-family: 'M PLUS 1 Code';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mplus1code/v7/ypvMbXOOx2xFpzmYJS3N2_J2hBN6RZ5oIp8m_7iN0HHp.ttf) format('truetype');
}
@font-face {
  font-family: 'M PLUS 1p';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mplus1p/v27/e3tjeuShHdiFyPFzBRro-A.ttf) format('truetype');
}
@font-face {
  font-family: 'M PLUS 2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mplus2/v6/7Auhp_Eq3gO_OGbGGhjdwrDdpeIBxlkwOa6Vxg.ttf) format('truetype');
}
@font-face {
  font-family: 'M PLUS Code Latin';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mpluscodelatin/v7/hv-ylyV-aXg7x7tULiNXXBA0Np4WMS8fDIymHY8fy8wn4_ifLAtrObKDO0Xf1EbA6i4.ttf) format('truetype');
}
@font-face {
  font-family: 'M PLUS Rounded 1c';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mplusrounded1c/v15/VdGEAYIAV6gnpUpoWwNkYvrugw9RuPWG.ttf) format('truetype');
}
@font-face {
  font-family: 'Ma Shan Zheng';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mashanzheng/v10/NaPecZTRCLxvwo41b4gvzkXaRMQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Macondo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/macondo/v21/RrQQboN9-iB1IXmOS2U.ttf) format('truetype');
}
@font-face {
  font-family: 'Macondo Swash Caps';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/macondoswashcaps/v20/6NUL8EaAJgGKZA7lpt941Z9s6ZYgDq6Oeg.ttf) format('truetype');
}
@font-face {
  font-family: 'Mada';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mada/v16/7Auwp_0qnzeSTTU.ttf) format('truetype');
}
@font-face {
  font-family: 'Magra';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/magra/v14/uK_94ruaZus72k5x.ttf) format('truetype');
}
@font-face {
  font-family: 'Maiden Orange';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/maidenorange/v25/kJE1BuIX7AUmhi2V4m08kb1XjOY.ttf) format('truetype');
}
@font-face {
  font-family: 'Maitree';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/maitree/v10/MjQGmil5tffhpBrkrtk.ttf) format('truetype');
}
@font-face {
  font-family: 'Major Mono Display';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/majormonodisplay/v12/RWmVoLyb5fEqtsfBX9PDZIGr2tFubRhLCg.ttf) format('truetype');
}
@font-face {
  font-family: 'Mako';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mako/v18/H4coBX6Mmc_Z0SQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Mali';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mali/v9/N0ba2SRONuN4eCo.ttf) format('truetype');
}
@font-face {
  font-family: 'Mallanna';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mallanna/v13/hv-Vlzx-KEQb84YaDGwz.ttf) format('truetype');
}
@font-face {
  font-family: 'Mandali';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mandali/v14/LhWlMVbYOfASNfNUVFk.ttf) format('truetype');
}
@font-face {
  font-family: 'Manjari';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manjari/v9/k3kQo8UPMOBO2w1UTd4.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v13/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk79FO_F.ttf) format('truetype');
}
@font-face {
  font-family: 'Mansalva';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mansalva/v12/aWB4m0aacbtDfvq5NJll.ttf) format('truetype');
}
@font-face {
  font-family: 'Manuale';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manuale/v23/f0Xp0eas_8Z-TFZdHv3mMxFaSqASeeHke7wD.ttf) format('truetype');
}
@font-face {
  font-family: 'Marcellus';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/marcellus/v13/wEO_EBrOk8hQLDvIAF8FUQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Marcellus SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/marcellussc/v13/ke8iOgUHP1dg-Rmi6RWjbLEPgQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Marck Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/marckscript/v16/nwpTtK2oNgBA3Or78gapdwuCzw.ttf) format('truetype');
}
@font-face {
  font-family: 'Margarine';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/margarine/v21/qkBXXvoE6trLT9Y7YLye5A.ttf) format('truetype');
}
@font-face {
  font-family: 'Marhey';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/marhey/v3/x3d8ck7Laq-T7wl7mqfVrEe9sDvtBctwO2c.ttf) format('truetype');
}
@font-face {
  font-family: 'Markazi Text';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/markazitext/v22/sykh-ydym6AtQaiEtX7yhqb_rV1k_81ZVYYZtfSQT4M.ttf) format('truetype');
}
@font-face {
  font-family: 'Marko One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/markoone/v22/9Btq3DFG0cnVM5lw1haaKg.ttf) format('truetype');
}
@font-face {
  font-family: 'Marmelad';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/marmelad/v15/Qw3eZQdSHj_jK2e-8tFL.ttf) format('truetype');
}
@font-face {
  font-family: 'Martel';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/martel/v10/PN_xRfK9oXHga0XtYQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Martel Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/martelsans/v12/h0GsssGi7VdzDgKjM-4d8ijf.ttf) format('truetype');
}
@font-face {
  font-family: 'Marvel';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/marvel/v14/nwpVtKeoNgBV0qaIkQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Mate';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mate/v14/m8JdjftRd7WZ2z0.ttf) format('truetype');
}
@font-face {
  font-family: 'Mate SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/matesc/v21/-nF8OGQ1-uoVr2wKyiU.ttf) format('truetype');
}
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mavenpro/v32/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8SX25nA.ttf) format('truetype');
}
@font-face {
  font-family: 'McLaren';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mclaren/v13/2EbnL-ZuAXFqZFXISYY.ttf) format('truetype');
}
@font-face {
  font-family: 'Mea Culpa';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/meaculpa/v3/AMOTz4GcuWbEIuza8jsZmg.ttf) format('truetype');
}
@font-face {
  font-family: 'Meddon';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/meddon/v20/kmK8ZqA2EgDNeHTZhA.ttf) format('truetype');
}
@font-face {
  font-family: 'MedievalSharp';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/medievalsharp/v24/EvOJzAlL3oU5AQl2mP5KdgptAq8.ttf) format('truetype');
}
@font-face {
  font-family: 'Medula One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/medulaone/v19/YA9Wr0qb5kjJM6l2V0yukiE.ttf) format('truetype');
}
@font-face {
  font-family: 'Meera Inimai';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/meerainimai/v12/845fNMM5EIqOW5MPuvO3ILep_w.ttf) format('truetype');
}
@font-face {
  font-family: 'Megrim';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/megrim/v16/46kulbz5WjvLqJZlbQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Meie Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/meiescript/v21/_LOImzDK7erRjhunIspaMjxn.ttf) format('truetype');
}
@font-face {
  font-family: 'Meow Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/meowscript/v5/0FlQVPqanlaJrtr8AnJ0ESch.ttf) format('truetype');
}
@font-face {
  font-family: 'Merienda';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/merienda/v17/gNMaW3x8Qoy5_mf8uUkJGHtiYXjmKFy5enhoSQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Merienda One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/meriendaone/v16/H4cgBXaMndbflEq6kyZ1ht6Ygg.ttf) format('truetype');
}
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/merriweather/v30/u-440qyriQwlOrhSvowK_l5Oew.ttf) format('truetype');
}
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/merriweathersans/v22/2-cO9IRs1JiJN1FRAMjTN5zd9vgsFF_5asQTb6hZ2JKZou4ljg.ttf) format('truetype');
}
@font-face {
  font-family: 'Metal';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/metal/v28/lW-wwjUJIXTo7i3n.ttf) format('truetype');
}
@font-face {
  font-family: 'Metal Mania';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/metalmania/v22/RWmMoKWb4e8kqMfBUdPFJeXC.ttf) format('truetype');
}
@font-face {
  font-family: 'Metamorphous';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/metamorphous/v18/Wnz8HA03aAXcC39ZEX5y1330PA.ttf) format('truetype');
}
@font-face {
  font-family: 'Metrophobic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/metrophobic/v19/sJoA3LZUhMSAPV_u0qwiAT-J.ttf) format('truetype');
}
@font-face {
  font-family: 'Michroma';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/michroma/v16/PN_zRfy9qWD8fEagAMg6.ttf) format('truetype');
}
@font-face {
  font-family: 'Milonga';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/milonga/v20/SZc53FHnIaK9W5kffz0.ttf) format('truetype');
}
@font-face {
  font-family: 'Miltonian';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/miltonian/v26/zOL-4pbPn6Ne9JqTg9mr6Q.ttf) format('truetype');
}
@font-face {
  font-family: 'Miltonian Tattoo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/miltoniantattoo/v28/EvOUzBRL0o0kCxF-lcMCQxlpVsA_FwM.ttf) format('truetype');
}
@font-face {
  font-family: 'Mina';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mina/v11/-nFzOGc18vARrz8.ttf) format('truetype');
}
@font-face {
  font-family: 'Mingzat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mingzat/v2/0QIgMX5C-o-oWWyvBts.ttf) format('truetype');
}
@font-face {
  font-family: 'Miniver';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/miniver/v21/eLGcP-PxIg-5H0vC770.ttf) format('truetype');
}
@font-face {
  font-family: 'Miriam Libre';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/miriamlibre/v13/DdTh798HsHwubBAqfkcBTL_vYA.ttf) format('truetype');
}
@font-face {
  font-family: 'Mirza';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mirza/v15/co3ImWlikiN5Eurd.ttf) format('truetype');
}
@font-face {
  font-family: 'Miss Fajardose';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/missfajardose/v22/E21-_dn5gvrawDdPFVl-N0Ajb8qv.ttf) format('truetype');
}
@font-face {
  font-family: 'Mitr';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mitr/v11/pxiLypw5ucZFyTs.ttf) format('truetype');
}
@font-face {
  font-family: 'Mochiy Pop One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mochiypopone/v7/QdVPSTA9Jh-gg-5XZP2UmU4O9kww.ttf) format('truetype');
}
@font-face {
  font-family: 'Mochiy Pop P One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mochiypoppone/v7/Ktk2AKuPeY_td1-h9LayHYWCjAqyN4M.ttf) format('truetype');
}
@font-face {
  font-family: 'Modak';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/modak/v18/EJRYQgs1XtIEsnMH.ttf) format('truetype');
}
@font-face {
  font-family: 'Modern Antiqua';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/modernantiqua/v22/NGStv5TIAUg6Iq_RLNo_2dp1sI1E.ttf) format('truetype');
}
@font-face {
  font-family: 'Mogra';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mogra/v19/f0X40eSs8c95TBo4.ttf) format('truetype');
}
@font-face {
  font-family: 'Mohave';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mohave/v8/7cH0v4ksjJunKqMVAOPIMOeSmiojdnn_HvA.ttf) format('truetype');
}
@font-face {
  font-family: 'Molengo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/molengo/v16/I_uuMpWeuBzZNBtQbbQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Monda';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/monda/v16/TK3tWkYFABsmjvpm.ttf) format('truetype');
}
@font-face {
  font-family: 'Monofett';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/monofett/v22/mFTyWbofw6zc9NtnW43S.ttf) format('truetype');
}
@font-face {
  font-family: 'Monoton';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/monoton/v15/5h1aiZUrOngCibe4fkY.ttf) format('truetype');
}
@font-face {
  font-family: 'Monsieur La Doulaise';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/monsieurladoulaise/v14/_Xmz-GY4rjmCbQfc-aPRaa4pqV340p7EZl5e.ttf) format('truetype');
}
@font-face {
  font-family: 'Montaga';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montaga/v13/H4cnBX2Ml8rCkEO_0gY.ttf) format('truetype');
}
@font-face {
  font-family: 'Montagu Slab';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montaguslab/v6/6qLhKZIQtB_zv0xUaXRDWkY_HXsphdLRZF40vm_jzR2jhk_n3T6ACkDbEnP9.ttf) format('truetype');
}
@font-face {
  font-family: 'MonteCarlo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montecarlo/v7/buEzpo6-f9X01GadLA0G0Co.ttf) format('truetype');
}
@font-face {
  font-family: 'Montez';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montez/v18/845ZNMk5GoGIX8lm1A.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat Alternates';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserratalternates/v17/mFTvWacfw6zH4dthXcyms1lPpC8I_b0juU0J7A.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat Subrayada';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserratsubrayada/v17/U9MD6c-o9H7PgjlTHThBnNHGVUORwteQQE8L.ttf) format('truetype');
}
@font-face {
  font-family: 'Moo Lah Lah';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/moolahlah/v3/dg4h_p_opKZOA0w1AYcm55wt.ttf) format('truetype');
}
@font-face {
  font-family: 'Moon Dance';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/moondance/v3/WBLgrEbUbFlYW9ekmGawe2U.ttf) format('truetype');
}
@font-face {
  font-family: 'Moul';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/moul/v25/nuF2D__FSo_3E-Q.ttf) format('truetype');
}
@font-face {
  font-family: 'Moulpali';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/moulpali/v28/H4ckBXKMl9HagUWymyY6.ttf) format('truetype');
}
@font-face {
  font-family: 'Mountains of Christmas';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mountainsofchristmas/v20/3y9w6a4zcCnn5X0FDyrKi2ZRUBIy8uxoUo7ePNY.ttf) format('truetype');
}
@font-face {
  font-family: 'Mouse Memoirs';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mousememoirs/v13/t5tmIRoSNJ-PH0WNNgDYxdSb7Tk.ttf) format('truetype');
}
@font-face {
  font-family: 'Mr Bedfort';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mrbedfort/v21/MQpR-WCtNZSWAdTMwBiclio.ttf) format('truetype');
}
@font-face {
  font-family: 'Mr Dafoe';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mrdafoe/v14/lJwE-pIzkS5NXuMMrGiq.ttf) format('truetype');
}
@font-face {
  font-family: 'Mr De Haviland';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mrdehaviland/v14/OpNVnooIhJj96FdB73296ksbOj3C.ttf) format('truetype');
}
@font-face {
  font-family: 'Mrs Saint Delafield';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mrssaintdelafield/v13/v6-IGZDIOVXH9xtmTZfRagunqBw5WC62cK4.ttf) format('truetype');
}
@font-face {
  font-family: 'Mrs Sheppards';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mrssheppards/v21/PN_2Rfm9snC0XUGoEZhb91ig3vg.ttf) format('truetype');
}
@font-face {
  font-family: 'Ms Madi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/msmadi/v2/HTxsL2UxNnOji5E1N-A.ttf) format('truetype');
}
@font-face {
  font-family: 'Mukta';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mukta/v13/iJWKBXyXfDDVXYnG.ttf) format('truetype');
}
@font-face {
  font-family: 'Mukta Mahee';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muktamahee/v15/XRXQ3IOIi0hcP8iVU67hA-vN.ttf) format('truetype');
}
@font-face {
  font-family: 'Mukta Malar';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muktamalar/v12/MCoXzAXyz8LOE2FpJMxZqLv4.ttf) format('truetype');
}
@font-face {
  font-family: 'Mukta Vaani';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muktavaani/v13/3Jn5SD_-ynaxmxnEfVHPIF0F.ttf) format('truetype');
}
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mulish/v12/1Ptyg83HX_SGhgqO0yLcmjzUAuWexZNRwaA.ttf) format('truetype');
}
@font-face {
  font-family: 'Murecho';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/murecho/v6/q5uYsoq3NOBn_I-ggCJg98TBOoNFCMpr5XWZ.ttf) format('truetype');
}
@font-face {
  font-family: 'MuseoModerno';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/museomoderno/v22/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMlZEuew.ttf) format('truetype');
}
@font-face {
  font-family: 'My Soul';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mysoul/v2/3XFqErcuy945_u6KF_U.ttf) format('truetype');
}
@font-face {
  font-family: 'Mystery Quest';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mysteryquest/v20/-nF6OG414u0E6k0wynSGlujRHwE.ttf) format('truetype');
}
@font-face {
  font-family: 'NTR';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ntr/v15/RLpzK5Xy0ZjiGA.ttf) format('truetype');
}
@font-face {
  font-family: 'Nabla';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nabla/v9/j8_D6-LI0Lvpe7Makz5UhJt9C3uqg_X_75gyGS4jAxsNIjrRNRA.ttf) format('truetype');
}
body {
  --google-font-color-nabla:none;
}
@font-face {
  font-family: 'Nanum Brush Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nanumbrushscript/v22/wXK2E2wfpokopxzthSqPbcR5_gVaxazyjg.ttf) format('truetype');
}
@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nanumgothic/v21/PN_3Rfi-oW3hYwmKDpxS7F_z_g.ttf) format('truetype');
}
@font-face {
  font-family: 'Nanum Gothic Coding';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nanumgothiccoding/v19/8QIVdjzHisX_8vv59_xMxtPFW4IXROwsy6Q.ttf) format('truetype');
}
@font-face {
  font-family: 'Nanum Myeongjo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nanummyeongjo/v20/9Btx3DZF0dXLMZlywRbVRNhxy1Lr.ttf) format('truetype');
}
@font-face {
  font-family: 'Nanum Pen Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nanumpenscript/v19/daaDSSYiLGqEal3MvdA_FOL_3FkN2z4.ttf) format('truetype');
}
@font-face {
  font-family: 'Neonderthaw';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/neonderthaw/v3/Iure6Yx5-oWVZI0r-17AeZZJ.ttf) format('truetype');
}
@font-face {
  font-family: 'Nerko One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nerkoone/v15/m8JQjfZSc7OXlB3ZMOjzcA.ttf) format('truetype');
}
@font-face {
  font-family: 'Neucha';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/neucha/v17/q5uGsou0JOdh94bvug.ttf) format('truetype');
}
@font-face {
  font-family: 'Neuton';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/neuton/v18/UMBTrPtMoH62xUZyyA.ttf) format('truetype');
}
@font-face {
  font-family: 'New Rocker';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/newrocker/v16/MwQzbhjp3-HImzcCU_cJkGM.ttf) format('truetype');
}
@font-face {
  font-family: 'New Tegomin';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/newtegomin/v10/SLXMc1fV7Gd9USdBAfPlqfN0.ttf) format('truetype');
}
@font-face {
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/newscycle/v22/CSR64z1Qlv-GDxkbKVQ_TOc.ttf) format('truetype');
}
@font-face {
  font-family: 'Newsreader';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/newsreader/v19/cY9qfjOCX1hbuyalUrK49dLac06G1ZGsZBtoBCzBDXXD9JVF438weI_ADA.ttf) format('truetype');
}
@font-face {
  font-family: 'Niconne';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/niconne/v15/w8gaH2QvRug1_rTfrQs.ttf) format('truetype');
}
@font-face {
  font-family: 'Niramit';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/niramit/v10/I_uuMpWdvgLdNxVLbbQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Nixie One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nixieone/v16/lW-8wjkKLXjg5y2o2uUoUA.ttf) format('truetype');
}
@font-face {
  font-family: 'Nobile';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nobile/v17/m8JTjflSeaOVl1i2Xg.ttf) format('truetype');
}
@font-face {
  font-family: 'Nokora';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nokora/v30/~CggKBk5va29yYSAA.ttf) format('truetype');
}
@font-face {
  font-family: 'Norican';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/norican/v14/MwQ2bhXp1eSBqjkPGJI.ttf) format('truetype');
}
@font-face {
  font-family: 'Nosifer';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nosifer/v20/ZGjXol5JTp0g5bxZaC0.ttf) format('truetype');
}
@font-face {
  font-family: 'Notable';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notable/v14/gNMEW3N_SIqx-WX9-HM.ttf) format('truetype');
}
@font-face {
  font-family: 'Nothing You Could Do';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nothingyoucoulddo/v15/oY1B8fbBpaP5OX3DtrRYf_Q2BPB1SnfZb0OJ.ttf) format('truetype');
}
@font-face {
  font-family: 'Noticia Text';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/noticiatext/v15/VuJ2dNDF2Yv9qppOePKYRP1GYQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Color Emoji';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notocoloremoji/v24/Yq6P-KqIXTD0t4D9z1ESnKM3-HpFab4.ttf) format('truetype');
}
body {
  --google-font-color-notocoloremoji:none;
}
@font-face {
  font-family: 'Noto Emoji';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoemoji/v34/bMrnmSyK7YY-MEu6aWjPDs-ar6uWaGWuob-r0jwv.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Kufi Arabic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notokufiarabic/v15/CSRp4ydQnPyaDxEXLFF6LZVLKrodhu8t57o1kDc5Wh5v34bP.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Music';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notomusic/v17/pe0rMIiSN5pO63htf1sxItc.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Naskh Arabic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notonaskharabic/v18/RrQ5bpV-9Dd1b1OAGA6M9PkyDuVBePeKNaxcsss0Y7bwvc5krA.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Nastaliq Urdu';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notonastaliqurdu/v14/LhWNMUPbN-oZdNFcBy1-DJYsEoTq5pudQ9L940pGPkB3Qt_-DK0.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Rashi Hebrew';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notorashihebrew/v21/EJR_Qh82XsIK-QFmqXk4zvLwFVya0vFL-HlKM5e6C6HZB-HkRw.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosans/v27/o-0IIpQlx3QUlC5A4PNb4g.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Adlam';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansadlam/v21/neIczCCpqp0s5pPusPamd81eMfjPonvqdbYxxpgufnv0TGk.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Adlam Unjoined';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansadlamunjoined/v21/P5sszY2MYsLRsB5_ildkzPPDsLQXcOEmaFOqOGcaYrzFTIjsPam_Ye35PME.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Anatolian Hieroglyphs';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansanatolianhieroglyphs/v14/ijw9s4roRME5LLRxjsRb8A0gKPSWq4BbDmHHu6j2pEtUJzZWXyY.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Arabic';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCfyGyvuw.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Armenian';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansarmenian/v39/ZgN0jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idb74R9UdM3y2nZLorxb60i.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Avestan';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansavestan/v17/bWti7ejKfBziStx7lIzKOLQZKhIJkyu9.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Balinese';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansbalinese/v18/NaPwcYvSBuhTirw6IaFn6UrRDaqje-lpbbRtYf-Fwu2Ov7fdhE4.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Bamum';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansbamum/v26/uk-0EGK3o6EruUbnwovcbBTkkklK_Ya_PBHfNGTPEddO-_g.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Bassa Vah';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansbassavah/v17/PN_bRee-r3f7LnqsD5sax12gjZn7mBpL5YwUpA2MBdcFn4MaAc6p.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Batak';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansbatak/v16/gok2H6TwAEdtF9N8-mdTCQvT-Zdgow.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Bengali';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansbengali/v20/Cn-SJsCGWQxOjaGwMQ6fIiMywrNJIky6nvd8BjzVMvJx2mcSPVFpVEqE-6KmsolLudA.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Bhaiksuki';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansbhaiksuki/v15/UcC63EosKniBH4iELXATsSBWdvUHXxhj8rI.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Brahmi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansbrahmi/v15/vEFK2-VODB8RrNDvZSUmQQIIByV18tI.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Buginese';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansbuginese/v17/esDM30ldNv-KYGGJpKGk18phe_7Da6_gtQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Buhid';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansbuhid/v17/Dxxy8jiXMW75w3OmoDXVWJD7YwzAew.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Canadian Aboriginal';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanscanadianaboriginal/v21/4C_TLjTuEqPj-8J01CwaGkiZ9os0iGVkezM1mUT-j_Lmlzda6uH_nnX1bzigWLn_yA.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Carian';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanscarian/v15/LDIpaoiONgYwA9Yc6f0gUILeMIOgs7o.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Caucasian Albanian';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanscaucasianalbanian/v16/nKKA-HM_FYFRJvXzVXaANsU0VzsAc46QGOkWytlTs-TXrYA.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Chakma';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanschakma/v17/Y4GQYbJ8VTEp4t3MKJSMjg5OIzhi4Jg.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Cham';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanscham/v23/pe06MIySN5pO62Z5YkFyQb_bbuRhe6D4yip43qfcERwcvw.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Cherokee';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanscherokee/v19/KFOPCm6Yu8uF-29fiz9vQF9YWK6Z8O10cHNA0cSkZCHYWi5PDkk.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Coptic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanscoptic/v17/iJWfBWmUZi_OHPqn4wq6kgqumOEd78s.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Cuneiform';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanscuneiform/v15/bMrrmTWK7YY-MF22aHGGd7H8PhJtvBDWgb8.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Cypriot';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanscypriot/v15/8AtzGta9PYqQDjyp79a6f8Cj-3a3cxIs.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Deseret';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansdeseret/v15/MwQsbgPp1eKH6QsAVuFb9AZM6MMr2Vq9.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Devanagari';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansdevanagari/v19/TuGoUUFzXI5FBtUq5a8bjKYTZjtRU6Sgv3NaV_SNmI0b8QQCQmHn6B2OHjbL_08AlXQly-A.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Display';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansdisplay/v20/RLpbK4fy6r6tOBEJg0IAKzqdFZVZxpMkXJMhnB9XjO1o90LuV-PT4Doq_AKp_3cKVTE.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Duployan';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansduployan/v16/gokzH7nwAEdtF9N8-mdTDx_X9JM5wsvrFg.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Egyptian Hieroglyphs';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansegyptianhieroglyphs/v26/vEF42-tODB8RrNDvZSUmRhcQHzx1s7y_F9-j3qSzEcbEYindSQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Elbasan';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanselbasan/v15/-F6rfiZqLzI2JPCgQBnw400qp1trvHdl.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Elymaic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanselymaic/v15/UqyKK9YTJW5liNMhTMqe9vUFP65ZD4Aj.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Ethiopic';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansethiopic/v38/7cHPv50vjIepfJVOZZgcpQ5B9FBTH9KGNfhSTgtoow1KVnIvyBoMSzUMacb-T35OK6Dj.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Georgian';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansgeorgian/v36/PlIaFke5O6RzLfvNNVSitxkr76PRHBC4Ytyq-Gof7PUs4S7zWn-8YDB09HFNdpvnzFj-.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Glagolitic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansglagolitic/v15/1q2ZY4-BBFBst88SU_tOj4J-4yuNF_HI4ERK.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Gothic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansgothic/v15/TuGKUUVzXI5FBtUq5a8bj6wRbzxTFMU.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Grantha';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansgrantha/v17/3y976akwcCjmsU8NDyrKo3IQfQ4o-r8c.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Gujarati';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansgujarati/v19/wlpWgx_HC1ti5ViekvcxnhMlCVo3f5pv17ivlzsUB14gg1TMR2Gw4VceEl7MA_ypFwPM.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Gunjala Gondi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansgunjalagondi/v15/bWto7e7KfBziStx7lIzKPrcSMwcEnCv6DW7n5hcV.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Gurmukhi';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansgurmukhi/v20/w8g9H3EvQP81sInb43inmyN9zZ7hb7ATbSWo4q8dJ74a3cVrYFQ_bogT0-gPeG1Oenbx.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans HK';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanshk/v21/nKKQ-GM_FYFRJvXzVXaAPe9hMg.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans Hanifi Rohingya';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanshanifirohingya/v18/5h17iYsoOmIC3Yu3MDXLDw3UZCgghyOEBBY7hhLNyo3tiaiuSIAqrIYY4j6v.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Hanunoo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanshanunoo/v17/f0Xs0fCv8dxkDWlZSoXOj6CphMloFsEs.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Hatran';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanshatran/v15/A2BBn4Ne0RgnVF3Lnko-0sOBIfL_mM8.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Hebrew';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanshebrew/v38/or3HQ7v33eiDljA1IufXTtVf7V6RvEEdhQlk0LlGxCyaeNKYZC0sqk3xXGiXd4qtog.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Imperial Aramaic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansimperialaramaic/v15/a8IMNpjwKmHXpgXbMIsbTc_kvks91LlLetBr5itQrtdm.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Indic Siyaq Numbers';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansindicsiyaqnumbers/v15/6xK5dTJFKcWIu4bpRBjRZRpsIYHabOeZ8UZLubTzpXNHKx2W.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Inscriptional Pahlavi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansinscriptionalpahlavi/v15/ll8UK3GaVDuxR-TEqFPIbsR79Xxz9WEKbwsjpz7VklYlC7FCVto.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Inscriptional Parthian';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansinscriptionalparthian/v15/k3k7o-IMPvpLmixcA63oYi-yStDkgXuXncL7dzfW3P4TAJ2yklBJ.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansjp/v42/-F62fjtqLzI2JPCgQBnw7HFowA.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans Javanese';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansjavanese/v19/2V01KJkDAIA6Hp4zoSScDjV0Y-eoHAHT-Z3MngEefiidxJnkFFk.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanskr/v27/PbykFmXiEBPT4ITbgNA5Cgm20A.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans Kaithi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanskaithi/v16/buEtppS9f8_vkXadMBJJu0tWjLwjQi0.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Kannada';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanskannada/v21/8vIs7xs32H97qzQKnzfeXycxXZyUmySvZWItmf1fe6TVmgop9ndpS-BqHEyGrDvNzSI.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Kayah Li';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanskayahli/v20/B50nF61OpWTRcGrhOVJJwOMXdca6Yecki3E06x2jVTX3WCc3CZE.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Kharoshthi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanskharoshthi/v15/Fh4qPiLjKS30-P4-pGMMXCCfvkc5Vd7KE5z4.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Khmer';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanskhmer/v18/ijw3s5roRME5LLRxjsRb-gssOenAyendxrgV2c-Zw-9vbVUti_Z_dWgtWYuNAJz4.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Khojki';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanskhojki/v16/-nFnOHM29Oofr2wohFbTuPPKVWpmK_c.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Khudawadi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanskhudawadi/v16/fdNi9t6ZsWBZ2k5ltHN73zZ5hc8HANlHIjQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Lao';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanslao/v24/bx6lNx2Ol_ixgdYWLm9BwxM3NW6BOkuf763Clj73CiQ_J1Djx9pidOt4ccbdfw.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Lao Looped';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanslaolooped/v2/a8IgNpbwKmHXpgXbMIsbSMP7-3U72qUOX5gBg6LRXExhqHIX9YPTpvqkW4UthhjomPr3M-Y.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Lepcha';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanslepcha/v16/0QI7MWlB_JWgA166SKhu05TekNS32AI.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Limbu';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanslimbu/v22/3JnlSDv90Gmq2mrzckOBBRRoNJVj0A.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Linear A';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanslineara/v16/oPWS_l16kP4jCuhpgEGmwJOiA18FZj22zg.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Linear B';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanslinearb/v15/HhyJU4wt9vSgfHoORYOiXOckKNB737IV3A.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Lisu';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanslisu/v21/uk-3EGO3o6EruUbnwovcYhz6kh57_nqbcTdjJnHP2Vwt2w.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Lycian';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanslycian/v15/QldVNSNMqAsHtsJ7UmqxBQA9r8wA5_k.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Lydian';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanslydian/v15/c4m71mVzGN7s8FmIukZJ1v4ZlcPReUM.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Mahajani';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansmahajani/v15/-F6sfiVqLzI2JPCgQBnw60Agp0JrvD5Fhw.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Malayalam';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansmalayalam/v21/sJoi3K5XjsSdcnzn071rL37lpAOsUThnDZIfPdbeSNzVakglNM-Qw8EaeB8Nss-_RuD9BA.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Mandaic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansmandaic/v15/cIfnMbdWt1w_HgCcilqhKQBo_OsMI5_A.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Manichaean';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansmanichaean/v15/taiVGntiC4--qtsfi4Jp9-_GkPZZCcrfekqC.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Marchen';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansmarchen/v17/aFTO7OZ_Y282EP-WyG6QTOX_C8WZMHhP.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Masaram Gondi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansmasaramgondi/v17/6xK_dThFKcWIu4bpRBjRYRV7KZCbUq6n_1kPnuGe.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Math';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansmath/v15/7Aump_cpkSecTWaHRlH2hyV5UHkG.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Mayan Numerals';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansmayannumerals/v15/PlIuFk25O6RzLfvNNVSivR09_KqYMwvvDKYjfIiE6w.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Medefaidrin';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansmedefaidrin/v21/WwkzxOq6Dk-wranENynkfeVsNbRZtbOIdLb1exeM4ZeuabBfmErWlT0.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Meetei Mayek';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansmeeteimayek/v14/HTxAL3QyKieByqY9eZPFweO0be7M21uSphSdhqILnmrRfJ8t_1TJ_vTW.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Mende Kikakui';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansmendekikakui/v28/11hRGoLHz17aKjQCWj-JHcLvu2Q5zZrnkbNCLUx_.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Meroitic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansmeroitic/v16/IFS5HfRJndhE3P4b5jnZ3ITPvC6i00UDgA.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Miao';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansmiao/v17/Dxxz8jmXMW75w3OmoDXVV4zyZUjg.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Modi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansmodi/v20/pe03MIySN5pO62Z5YkFyT7jeav5q.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Mongolian';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansmongolian/v17/VdGCAYADGIwE0EopZx8xQfHlgEAMsrToxLs.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Mono';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansmono/v21/BngrUXNETWXI6LwhGYvaxZikqZqK6fBq6kPvUce2oAZcdthSBUsYck4-_FNJ49o.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Mro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansmro/v15/qWcsB6--pZv9TqnUQMhe9b39WDw.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Multani';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansmultani/v20/9Bty3ClF38_RfOpe1gCaZ8p30BOFO1A0.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Myanmar';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansmyanmar/v20/AlZq_y1ZtY3ymOryg38hOCSdOnFq0En2.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans N Ko';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansnko/v17/6NUP8FqDKBaKKjnr6P8v-sxPpvVB.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Nabataean';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansnabataean/v15/IFS4HfVJndhE3P4b5jnZ34DfsjO330dNoBI.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans New Tai Lue';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansnewtailue/v17/H4cKBW-Pl9DZ0Xe_nHUapt7PovLXAhAnY7wqaLy-OJgU3p_pdeXAYUY.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Newa';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansnewa/v16/7r3fqXp6utEsO9pI4f8ok8sWg8n_.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Nushu';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansnushu/v18/rnCw-xRQ3B7652emAbAe_Ai1IYaFWA.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Ogham';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansogham/v15/kmKlZqk1GBDGN0mY6k5lmEmww4hrtw.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Ol Chiki';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansolchiki/v20/N0b92TJNOPt-eHmFZCdQbrL32r-4CvhzDzRwlxOQYuVALWk267I.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Old Hungarian';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansoldhungarian/v15/E213_cD6hP3GwCJPEUssHEM0KqLaHJXg2PiIgRfj.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Old Italic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansolditalic/v15/TuGOUUFzXI5FBtUq5a8bh68BJxxEVam7tWlR.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Old North Arabian';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansoldnortharabian/v15/esDF30BdNv-KYGGJpKGk2tNiMt7Jar6olZDyNdr81zBQmQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Old Permic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansoldpermic/v16/snf1s1q1-dF8pli1TesqcbUY4Mr-ElrwKLdX.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Old Persian';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansoldpersian/v15/wEOjEAbNnc5caQTFG18FHrZr9Bp6-8CmIJ_tqA.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Old Sogdian';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansoldsogdian/v15/3JnjSCH90Gmq2mrzckOBBhFhdrMst48aURt7nQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Old South Arabian';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansoldsoutharabian/v15/3qT5oiOhnSyU8TNFIdhZTice3hB_HWKsEnF--0XCHiKx1A.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Old Turkic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansoldturkic/v15/yMJNMJVya43H0SUF_WmcGEQVqoEMKDKbsE2R.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Oriya';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansoriya/v19/AYCppXfzfccDCstK_hrjDyADv5e9748vhj3CJBLHIARtgD6TJQS0dJT5Ivj0f6_c.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Osage';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansosage/v18/oPWX_kB6kP4jCuhpgEGmw4mtAVtXRg.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Osmanya';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansosmanya/v18/8vIS7xs32H97qzQKnzfeWzUyUpOJmz6k.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Pahawh Hmong';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanspahawhhmong/v17/bWtp7e_KfBziStx7lIzKKaMUOBEA3UPQDW7krzc.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Palmyrene';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanspalmyrene/v15/ZgNPjOdKPa7CHqq0h37c_ASCWvH93SFCPnI.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Pau Cin Hau';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanspaucinhau/v19/x3d-cl3IZKmUqiMg_9wBLLtzl22EayN7ehIdjA.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Phags Pa';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansphagspa/v15/pxiZyoo6v8ZYyWh5WuPeJzMkd4SrGChkqg.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Phoenician';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansphoenician/v15/jizFRF9Ksm4Bt9PvcTaEkIHiTVtxmFtS5X7J.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Psalter Pahlavi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanspsalterpahlavi/v15/rP2Vp3K65FkAtHfwd-eISGznYihzggmsicPfud3w1G0.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Rejang';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansrejang/v18/Ktk2AKuMeZjqPnXgyqrib7DIogqwN4M.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Runic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansrunic/v15/H4c_BXWPl9DZ0Xe_nHUaus7W68WWaw.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanssc/v26/k3kXo84MPvpLmixcA63oeALhLw.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans Samaritan';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanssamaritan/v15/buEqppe9f8_vkXadMBJJo0tSmaYjFkxOUo4.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Saurashtra';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanssaurashtra/v18/ea8GacQ0Wfz_XKWXe6OtoA8w8zvmYwTef9nd.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Sharada';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanssharada/v16/gok0H7rwAEdtF9N8-mdTGALG6p0kwoXL.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Shavian';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansshavian/v15/CHy5V_HZE0jxJBQlqAeCKjJvQBNF4EFQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Siddham';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanssiddham/v17/OZpZg-FwqiNLe9PELUikxTWDoCCeGqnd.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Sinhala';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanssinhala/v26/yMJ2MJBya43H0SUF_WmcBEEf4rQVO2P524V5N_MxQzQtb-tf5dJbC30Fu9zUwg2a5lg.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Sogdian';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanssogdian/v15/taiQGn5iC4--qtsfi4Jp6eHPnfxQBo--.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Sora Sompeng';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanssorasompeng/v19/PlIRFkO5O6RzLfvNNVSioxM2_OTrEhPyDLolKvCsHzCxWuGkYHR818Dp.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Soyombo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanssoyombo/v15/RWmSoL-Y6-8q5LTtXs6MF6q7xsxgY0Fr.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Sundanese';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanssundanese/v19/FwZw7_84xUkosG2xJo2gm7nFwSLQkdymq2mkz3Gz1_b6ctxpNNHC.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Syloti Nagri';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanssylotinagri/v20/uU9eCAQZ75uhfF9UoWDRiY3q7Sf_VFV3m4dGFVc.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Symbols';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanssymbols/v36/rP2up3q65FkAtHfwd-eIS2brbDN6gxP34F9jRRCe4W3gfQ8gag.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Symbols 2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanssymbols2/v15/I_uyMoGduATTei9eI8daxVHDyfisHr71ypM.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Syriac';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanssyriac/v15/Ktk2AKuMeZjqPnXgyqribqzQqgW0N4M.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nF7OG829Oofr2wohFbTp9iFOQ.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans Tagalog';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstagalog/v17/J7aFnoNzCnFcV9ZI-sUYuvote1R0wwEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Tagbanwa';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstagbanwa/v17/Y4GWYbB8VTEp4t3MKJSMmQdIKjRtt_nZRg.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Tai Le';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstaile/v17/vEFK2-VODB8RrNDvZSUmVxEATwR58tI.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Tai Tham';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstaitham/v19/kJEbBv0U4hgtwxDUw2x9q7tbjLIfbPGHBoaVSAZ3MdLJBCUbPgo.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Tai Viet';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstaiviet/v16/8QIUdj3HhN_lv4jf9vsE-9GMOLsaSPZr6w.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Takri';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstakri/v21/TuGJUVpzXI5FBtUq5a8bnKIOdTwQNA.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Tamil';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstamil/v21/ieVc2YdFI3GCY6SyQy1KfStzYKZgzN1z4LKDbeZce-0429tBManUktuex7vGo70R.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Tamil Supplement';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstamilsupplement/v19/DdTz78kEtnooLS5rXF1DaruiCd_bFp_Ph4sGcn7ax_vs.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Telugu';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstelugu/v19/0FlxVOGZlE2Rrtr-HmgkMWJNjJ5_RyT8o8c7fHkeg-esVC5dzHkHIJQqrEntezbqQQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Thaana';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansthaana/v18/C8c14dM-vnz-s-3jaEsxlxHkBH-WZOETXfoQrfQ9Y4XrbhLh.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Thai';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansthai/v20/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdU5RtpzE.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Thai Looped';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansthailooped/v12/B50RF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3gOw.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Tifinagh';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstifinagh/v15/I_uzMoCduATTei9eI8dawkHIwvmhCvbn6g.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Tirhuta';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstirhuta/v15/t5t6IQYRNJ6TWjahPR6X-M-apUyby7uG.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Ugaritic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansugaritic/v15/3qTwoiqhnSyU8TNFIdhZVCwbjCpkAXXkMg.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Vai';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansvai/v17/NaPecZTSBuhTirw6IaFn_UrURMQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Wancho';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanswancho/v17/zrf-0GXXyfn6Fs0lH9P4cUubP0GBqAM.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Warang Citi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanswarangciti/v17/EYqtmb9SzL1YtsZSScyKDXIeOv3w-zgsNvKRpQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Yi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansyi/v18/sJoD3LFXjsSdcnzn071rO3apxQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Zanabazar Square';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanszanabazarsquare/v16/Cn-jJsuGWQxOjaGwMQ6fOicyxLBEMRfDtkzl4uagQtJx.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoserif/v21/ga6Iaw1J5X9T9RW6j9bNTFA.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif Ahom';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoserifahom/v17/FeVIS0hfp6cprmEUffAW_fUL_AN-wg.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif Armenian';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoserifarmenian/v23/3XFMEqMt3YoFsciDRZxptyCUKJmytZ0kVU-XvF7QaZuL85rnQ_zDNzDe5xNnKxyZi8Kbxg.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif Balinese';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoserifbalinese/v16/QdVKSS0-JginysQSRvuCmUMB_wVeQAxXRbg.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif Bengali';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoserifbengali/v19/hYkuPvggTvnzO14VSXltirUdnnkt1pwmWrprmO7RjE0a5BtdATYU1crFaM_5JfcAHnqn.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif Devanagari';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoserifdevanagari/v21/x3dYcl3IZKmUqiMk48ZHXJ5jwU-DZGRSaQ4Hh2dGyFzPLcQPVbnRNeFsw0xRWb6uxTA-ow-H.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif Display';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoserifdisplay/v17/buERppa9f8_vkXaZLAgP0G5Wi6QmA1QaeYah2sovLCDq_ZgLyt3idQfktOG-PVpd4tgK.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif Dogra';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoserifdogra/v16/MQpP-XquKMC7ROPP3QOOlm7xPu3fGy4.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif Ethiopic';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoserifethiopic/v18/V8mjoR7-XjwJ8_Au3Ti5tXj5Rd83frpWLK4d-taxqWw2HMWjDxBAg5S_0QsrggxCzSUjkQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif Georgian';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoserifgeorgian/v18/VEMXRpd8s4nv8hG_qOzL7HOAw4nt0Sl_XxyaEduNMvi7T6Y4etRnmGhyLop-R3aSTvofdw.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif Grantha';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoserifgrantha/v19/qkBIXuEH5NzDDvc3fLDYxPk9-Wq3WLiqFA.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif Gujarati';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoserifgujarati/v21/hESa6WBlOixO-3OJ1FTmTsmqlBRUJBVkcgNLpdsspzP2HuYycIzu.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif Gurmukhi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoserifgurmukhi/v14/92z-tA9LNqsg7tCYlXdCV1VPnAEeDU0vLoYMbylXk0xTCr6-eRTN.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif HK';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoserifhk/v2/BngdUXBETWXI6LwlBZGcqL-B_KuJFcgfwP_9RMd-K2Rm.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif Hebrew';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoserifhebrew/v20/k3k0o9MMPvpLmixYH7euCwmkS9DohjX1-kRyiqyBqIxnoLbp93i9IKrXKF_qVAwSAG8.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif JP';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoserifjp/v21/xn7mYHs72GKoTvER4Gn3b5eMXNg.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Serif KR';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoserifkr/v20/3Jn7SDn90Gmq2mr3blnHaTZXduY.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Serif Kannada';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoserifkannada/v21/v6-8GZHLJFKIhClqUYqXDiWqpxQxWSPoW6bz-l4hGHiNgcYDceQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif Khmer';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoserifkhmer/v18/-F6UfidqLzI2JPCkXAO2hmogq0146FxtbwKEr951z5s6lI40sDRH_AVhUKdN6B8wXA.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif Khojki';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoserifkhojki/v5/I_uHMoOduATTei9aP90ctmPGxP2rBKTM4mcQ5M3z9QMY0ghvyQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif Lao';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoseriflao/v18/3y9C6bYwcCjmsU8JEzCMxEwQfEBLk3f0rlSqCdaM_LlSNZ59oNw0BWH8VeMKrvM.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif Malayalam';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoserifmalayalam/v20/JIAZUU5sdmdP_HMcVcZFcH7DeVBeGVgSMEk2cmVDq1ihUXL1t-xfnQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif Myanmar';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoserifmyanmar/v13/VuJsdM7F2Yv76aBKKs-bHMQfAHUw3jn1pA.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif Nyiakeng Puachue Hmong';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoserifnyiakengpuachuehmong/v16/5h1jibMoOmIC3YuzLC-NZyLDZC8iwh-MTC8ggAjEhePFNRVcneAFp44kcYMUkNqVKhqPDFs.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif Oriya';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoseriforiya/v2/MjQQmj56u-r69izk_LDqWN7w0cYByutv9qeWYrvLaxrc_Hy-.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoserifsc/v22/H4chBXePl9DZ0Xe7gG9cyOj7oqA.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Serif Sinhala';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoserifsinhala/v18/DtVEJwinQqclnZE2CnsPug9lgGC3y2F2nehQ7Eg4EdBKWxPiDxMivFLgRXs_-pGxR1Ms.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif TC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoseriftc/v23/XLYgIZb5bJNDGYxLBibeHZ0BhnE.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Serif Tamil';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoseriftamil/v21/LYjndHr-klIgTfc40komjQ5OObazYp-6H94dBF-RX6nNRJfi-Gf55IgAecattN-R8A.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif Tangut';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoseriftangut/v15/xn76YGc72GKoTvER4Gn3b4m9Ern7Em41.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif Telugu';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoseriftelugu/v20/tDbl2pCbnkEKmXNVmt2M1q6f4HWbbj6MRbYEeav7Fe9D9TCwuQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif Thai';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoserifthai/v19/k3kyo80MPvpLmixYH7euCxWpSMu3-gcWGj0hHAKGvUQlUv_bCKDUSzB5L0oiF-RR.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif Tibetan';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoseriftibetan/v18/gokGH7nwAEdtF9N45n0Vaz7O-pk0wsvxHeDXMfqguoCmIrYcPS4.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif Yezidi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notoserifyezidi/v16/XLYPIYr5bJNDGYxLBibeHZAn3B5KJENnQjbfhMSVZspD2yEkrg.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Traditional Nushu';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nototraditionalnushu/v16/SZco3EDkJ7q9FaoMPlmF4Su8hlIjoGh5aj67J00.ttf) format('truetype');
}
@font-face {
  font-family: 'Nova Cut';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/novacut/v24/KFOkCnSYu8mL-39LkWxP.ttf) format('truetype');
}
@font-face {
  font-family: 'Nova Flat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/novaflat/v24/QdVUSTc-JgqpytEbVebEuQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Nova Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/novamono/v18/Cn-0JtiGWQ5Ajb--MRKfYA.ttf) format('truetype');
}
@font-face {
  font-family: 'Nova Oval';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/novaoval/v24/jAnEgHdmANHvPenMaswCMQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Nova Round';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/novaround/v21/flU9Rqquw5UhEnlwTJYTYYc.ttf) format('truetype');
}
@font-face {
  font-family: 'Nova Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/novascript/v25/7Au7p_IpkSWSTWaFWkumvmQN.ttf) format('truetype');
}
@font-face {
  font-family: 'Nova Slim';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/novaslim/v24/Z9XUDmZNQAuem8jyZcn-yA.ttf) format('truetype');
}
@font-face {
  font-family: 'Nova Square';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/novasquare/v20/RrQUbo9-9DV7b06QHgSWsZhA.ttf) format('truetype');
}
@font-face {
  font-family: 'Numans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/numans/v15/SlGRmQmGupYAfH8IYQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXI3I6Li01BKofiOc5wtlZ2di8HDLshRTM.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v12/pe0qMImSLYBIv1o4X1M8cfe6.ttf) format('truetype');
}
@font-face {
  font-family: 'Nuosu SIL';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nuosusil/v3/8vIK7wM3wmRn_kc4uAjeFA.ttf) format('truetype');
}
@font-face {
  font-family: 'Odibee Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/odibeesans/v14/neIPzCSooYAho6WvjeToRYky.ttf) format('truetype');
}
@font-face {
  font-family: 'Odor Mean Chey';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/odormeanchey/v27/raxkHiKDttkTe1aOGcJMR1A_4mrY.ttf) format('truetype');
}
@font-face {
  font-family: 'Offside';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/offside/v20/HI_KiYMWKa9QrAykQ5E.ttf) format('truetype');
}
@font-face {
  font-family: 'Oi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/oi/v15/w8gXH2EuRqta.ttf) format('truetype');
}
@font-face {
  font-family: 'Old Standard TT';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/oldstandardtt/v18/MwQubh3o1vLImiwAVvYawgcf2eVurQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Oldenburg';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/oldenburg/v20/fC1jPY5JYWzbywv7c4V6UQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Ole';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ole/v3/dFazZf6Z-rd89Q.ttf) format('truetype');
}
@font-face {
  font-family: 'Oleo Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/oleoscript/v14/rax5HieDvtMOe0iICsUccBha.ttf) format('truetype');
}
@font-face {
  font-family: 'Oleo Script Swash Caps';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/oleoscriptswashcaps/v13/Noaj6Vb-w5SFbTTAsZP_7JkCS08K-jCzDn_HMXo.ttf) format('truetype');
}
@font-face {
  font-family: 'Oooh Baby';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ooohbaby/v3/2sDcZGJWgJTT2Jf76xQDbw.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4n.ttf) format('truetype');
}
@font-face {
  font-family: 'Oranienbaum';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/oranienbaum/v15/OZpHg_txtzZKMuXLIVrx-3zn.ttf) format('truetype');
}
@font-face {
  font-family: 'Orbitron';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/orbitron/v25/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nyGy6xpg.ttf) format('truetype');
}
@font-face {
  font-family: 'Oregano';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/oregano/v13/If2IXTPxciS3H4S2kZc.ttf) format('truetype');
}
@font-face {
  font-family: 'Orelega One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/orelegaone/v10/3qTpojOggD2XtAdFb-QXZGt6.ttf) format('truetype');
}
@font-face {
  font-family: 'Orienta';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/orienta/v13/PlI9FlK4Jrl5Y9zNeyc.ttf) format('truetype');
}
@font-face {
  font-family: 'Original Surfer';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/originalsurfer/v18/RWmQoKGZ9vIirYntXJ3_MbekzNMiDA.ttf) format('truetype');
}
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvgUE.ttf) format('truetype');
}
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/outfit/v6/QGYyz_MVcBeNP4NjuGObqx1XmO1I4TC1C4E.ttf) format('truetype');
}
@font-face {
  font-family: 'Over the Rainbow';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/overtherainbow/v16/11haGoXG1k_HKhMLUWz7Mc7vvW5upvM.ttf) format('truetype');
}
@font-face {
  font-family: 'Overlock';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/overlock/v15/Z9XVDmdMWRiN1_T9Z4Te.ttf) format('truetype');
}
@font-face {
  font-family: 'Overlock SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/overlocksc/v21/1cX3aUHKGZrstGAY8nwVzHGA.ttf) format('truetype');
}
@font-face {
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/overpass/v12/qFda35WCmI96Ajtm83upeyoaX6QPnlo6_PPrOQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Overpass Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/overpassmono/v15/_Xm5-H86tzKDdAPa-KPQZ-AC_COcRycquHlL6EXUokzz.ttf) format('truetype');
}
@font-face {
  font-family: 'Ovo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ovo/v17/yYLl0h7Wyfzjyw.ttf) format('truetype');
}
@font-face {
  font-family: 'Oxanium';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/oxanium/v14/RrQPboN_4yJ0JmiMUW7sIGjd1IA9G81JfniM.ttf) format('truetype');
}
@font-face {
  font-family: 'Oxygen';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/oxygen/v15/2sDfZG1Wl4Lcnbu6iQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Oxygen Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/oxygenmono/v13/h0GsssGg9FxgDgCjLeAd7ijf.ttf) format('truetype');
}
@font-face {
  font-family: 'PT Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ptmono/v13/9oRONYoBnWILk-9ArCg.ttf) format('truetype');
}
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ptsans/v17/jizaRExUiTo99u79P0U.ttf) format('truetype');
}
@font-face {
  font-family: 'PT Sans Caption';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ptsanscaption/v18/0FlMVP6Hrxmt7-fsUFhlFXNIlpcqfQ.ttf) format('truetype');
}
@font-face {
  font-family: 'PT Sans Narrow';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ptsansnarrow/v17/BngRUXNadjH0qYEzV7ab-oWlsYCB.ttf) format('truetype');
}
@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ptserif/v17/EJRVQgYoZZY2vCFuvDFR.ttf) format('truetype');
}
@font-face {
  font-family: 'PT Serif Caption';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ptserifcaption/v17/ieVl2ZhbGCW-JoW6S34pSDpqYKU059U.ttf) format('truetype');
}
@font-face {
  font-family: 'Pacifico';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/pacifico/v22/FwZY7-Qmy14u9lezJ96A.ttf) format('truetype');
}
@font-face {
  font-family: 'Padauk';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/padauk/v16/RrQRboJg-id7OnbBaw.ttf) format('truetype');
}
@font-face {
  font-family: 'Palanquin';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/palanquin/v13/9XUnlJ90n1fBFg7ceXwsdg.ttf) format('truetype');
}
@font-face {
  font-family: 'Palanquin Dark';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/palanquindark/v12/xn75YHgl1nqmANMB-26xC7yuF_6O.ttf) format('truetype');
}
@font-face {
  font-family: 'Pangolin';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/pangolin/v11/cY9GfjGcW0FPpi-tWPfK.ttf) format('truetype');
}
@font-face {
  font-family: 'Paprika';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/paprika/v21/8QIJdijZitv49rDfuIg.ttf) format('truetype');
}
@font-face {
  font-family: 'Parisienne';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/parisienne/v13/E21i_d3kivvAkxhLEVZpcy8.ttf) format('truetype');
}
@font-face {
  font-family: 'Passero One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/passeroone/v24/JTUTjIko8DOq5FeaeEAjgE5B.ttf) format('truetype');
}
@font-face {
  font-family: 'Passion One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/passionone/v16/PbynFmL8HhTPqbjUzux3JHuW.ttf) format('truetype');
}
@font-face {
  font-family: 'Passions Conflict';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/passionsconflict/v5/kmKnZrcrFhfafnWX9x0GuEC-zowow5Ne.ttf) format('truetype');
}
@font-face {
  font-family: 'Pathway Gothic One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/pathwaygothicone/v14/MwQrbgD32-KAvjkYGNUUxAtW7pEBwx-dTA.ttf) format('truetype');
}
@font-face {
  font-family: 'Patrick Hand';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/patrickhand/v19/LDI1apSQOAYtSuYWp8ZhfYeMWQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Patrick Hand SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/patrickhandsc/v13/0nkwC9f7MfsBiWcLtY65AWDK873ViQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Pattaya';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/pattaya/v12/ea8ZadcqV_zkHY-XNdA.ttf) format('truetype');
}
@font-face {
  font-family: 'Patua One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/patuaone/v16/ZXuke1cDvLCKLDcimxBI5A.ttf) format('truetype');
}
@font-face {
  font-family: 'Pavanam';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/pavanam/v11/BXRrvF_aiezLh0xPDOs.ttf) format('truetype');
}
@font-face {
  font-family: 'Paytone One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/paytoneone/v18/0nksC9P7MfYHj2oFtYm2CiTq.ttf) format('truetype');
}
@font-face {
  font-family: 'Peddana';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/peddana/v20/aFTU7PBhaX89UcKWhh0.ttf) format('truetype');
}
@font-face {
  font-family: 'Peralta';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/peralta/v17/hYkJPu0-RP_9d3kRGxA.ttf) format('truetype');
}
@font-face {
  font-family: 'Permanent Marker';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/permanentmarker/v16/Fh4uPib9Iyv2ucM6pGQMWimMp004Hao.ttf) format('truetype');
}
@font-face {
  font-family: 'Petemoss';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/petemoss/v5/A2BZn5tA2xgtGWHZgxke.ttf) format('truetype');
}
@font-face {
  font-family: 'Petit Formal Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/petitformalscript/v13/B50TF6xQr2TXJBnGOFME6u5OR83oRP5qoHk.ttf) format('truetype');
}
@font-face {
  font-family: 'Petrona';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/petrona/v28/mtGl4_NXL7bZo9XXq35wRLONYyOjFk6NsQRB.ttf) format('truetype');
}
@font-face {
  font-family: 'Philosopher';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/philosopher/v19/vEFV2_5QCwIS4_Dhez5jcVBp.ttf) format('truetype');
}
@font-face {
  font-family: 'Piazzolla';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/piazzolla/v25/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7LYxnLy.ttf) format('truetype');
}
@font-face {
  font-family: 'Piedra';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/piedra/v21/ke8kOg8aN0Bn7hTunA.ttf) format('truetype');
}
@font-face {
  font-family: 'Pinyon Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/pinyonscript/v17/6xKpdSJbL9-e9LuoeQiDRQR8aOI.ttf) format('truetype');
}
@font-face {
  font-family: 'Pirata One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/pirataone/v22/I_urMpiDvgLdLh0fAtoftig.ttf) format('truetype');
}
@font-face {
  font-family: 'Plaster';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plaster/v24/DdTm79QatW80eRh4Ei4.ttf) format('truetype');
}
@font-face {
  font-family: 'Play';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/play/v17/6aez4K2oVqwIjtI.ttf) format('truetype');
}
@font-face {
  font-family: 'Playball';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playball/v16/TK3gWksYAxQ7jbsKcj8D.ttf) format('truetype');
}
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v30/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKdFvUDQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Playfair Display SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplaysc/v15/ke85OhoaMkR6-hSn7kbHVoFf7ZfgMPr_pb4.ttf) format('truetype');
}
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v3/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_qU7NSg.ttf) format('truetype');
}
@font-face {
  font-family: 'Podkova';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/podkova/v26/K2FufZ1EmftJSV9VQpXb1lo9vC3nZWtFzcU4.ttf) format('truetype');
}
@font-face {
  font-family: 'Poiret One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poiretone/v14/UqyVK80NJXN4zfRgbdfbk5k.ttf) format('truetype');
}
@font-face {
  font-family: 'Poller One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/pollerone/v19/ahccv82n0TN3gia5E4Bud-k.ttf) format('truetype');
}
@font-face {
  font-family: 'Poly';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poly/v16/MQpb-W6wKNitRLA.ttf) format('truetype');
}
@font-face {
  font-family: 'Pompiere';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/pompiere/v15/VEMyRoxis5Dwuyeov6Wt.ttf) format('truetype');
}
@font-face {
  font-family: 'Pontano Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/pontanosans/v13/qFdD35GdgYR8EzR6oBLDHa3qwg.ttf) format('truetype');
}
@font-face {
  font-family: 'Poor Story';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poorstory/v20/jizfREFUsnUct9P6cDfd4Ok.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrFJA.ttf) format('truetype');
}
@font-face {
  font-family: 'Port Lligat Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/portlligatsans/v18/kmKmZrYrGBbdN1aV7Vokow6Lw4s4l7M.ttf) format('truetype');
}
@font-face {
  font-family: 'Port Lligat Slab';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/portlligatslab/v21/LDIpaoiQNgArA8kR7ulhZ8P_NYOss7o.ttf) format('truetype');
}
@font-face {
  font-family: 'Potta One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/pottaone/v16/FeVSS05Bp6cy7xI-YfxQ3Q.ttf) format('truetype');
}
@font-face {
  font-family: 'Pragati Narrow';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/pragatinarrow/v13/vm8vdRf0T0bS1ffgsPB7WZ-mD17_.ttf) format('truetype');
}
@font-face {
  font-family: 'Praise';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/praise/v5/qkBUXvUZ-cnFXcFyDg.ttf) format('truetype');
}
@font-face {
  font-family: 'Prata';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/prata/v18/6xKhdSpbNNCT-vWI.ttf) format('truetype');
}
@font-face {
  font-family: 'Preahvihear';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/preahvihear/v27/6NUS8F-dNQeEYhzj7uluxswE.ttf) format('truetype');
}
@font-face {
  font-family: 'Press Start 2P';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/pressstart2p/v14/e3t4euO8T-267oIAQAu6jDQyK0nS.ttf) format('truetype');
}
@font-face {
  font-family: 'Pridi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/pridi/v11/2sDQZG5JnZLfkfWa.ttf) format('truetype');
}
@font-face {
  font-family: 'Princess Sofia';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/princesssofia/v21/qWczB6yguIb8DZ_GXZst16n7GRz7.ttf) format('truetype');
}
@font-face {
  font-family: 'Prociono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/prociono/v22/r05YGLlR-KxAf9GGO8up.ttf) format('truetype');
}
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/prompt/v10/-W__XJnvUD7dzB26Zw.ttf) format('truetype');
}
@font-face {
  font-family: 'Prosto One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/prostoone/v17/OpNJno4VhNfK-RgpwWWxpio.ttf) format('truetype');
}
@font-face {
  font-family: 'Proza Libre';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/prozalibre/v9/LYjGdGHgj0k1DIQRyUEyyHov.ttf) format('truetype');
}
@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuFpm5ww.ttf) format('truetype');
}
@font-face {
  font-family: 'Puppies Play';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/puppiesplay/v5/wlp2gwHZEV99rG6M3NR9uB9vaA.ttf) format('truetype');
}
@font-face {
  font-family: 'Puritan';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/puritan/v24/845YNMgkAJ2VTtIo9Jo.ttf) format('truetype');
}
@font-face {
  font-family: 'Purple Purse';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/purplepurse/v21/qWctB66gv53iAp-Vfs4My6qyeA.ttf) format('truetype');
}
@font-face {
  font-family: 'Qahiri';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/qahiri/v7/tsssAp1RZy0C_hGuUw.ttf) format('truetype');
}
@font-face {
  font-family: 'Quando';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/quando/v14/xMQVuFNaVa6YuW0pCw.ttf) format('truetype');
}
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/quantico/v15/rax-HiSdp9cPL3KIF4xs.ttf) format('truetype');
}
@font-face {
  font-family: 'Quattrocento';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/quattrocento/v17/OZpEg_xvsDZQL_LKIF7q4jPHxA.ttf) format('truetype');
}
@font-face {
  font-family: 'Quattrocento Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/quattrocentosans/v18/va9c4lja2NVIDdIAAoMR5MfuElaRB3zO.ttf) format('truetype');
}
@font-face {
  font-family: 'Questrial';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/questrial/v18/QdVUSTchPBm7nuUeVf7EuQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/quicksand/v30/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkP8o18E.ttf) format('truetype');
}
@font-face {
  font-family: 'Quintessential';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/quintessential/v20/fdNn9sOGq31Yjnh3qWU14DdtjY5w.ttf) format('truetype');
}
@font-face {
  font-family: 'Qwigley';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/qwigley/v16/1cXzaU3UGJb5tGoCuVw.ttf) format('truetype');
}
@font-face {
  font-family: 'Qwitcher Grypen';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/qwitchergrypen/v3/pxicypclp9tDilN9RrC5BSI1dZmrSA.ttf) format('truetype');
}
@font-face {
  font-family: 'Racing Sans One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/racingsansone/v13/sykr-yRtm7EvTrXNxkv5jfKKyDCwLw.ttf) format('truetype');
}
@font-face {
  font-family: 'Radio Canada';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/radiocanada/v16/XRX13ISXn0dBMcibU6jlAqr3ejLv5OLZYiYXik6db2P4jxxlsls-0nFMkQPI.ttf) format('truetype');
}
@font-face {
  font-family: 'Radley';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/radley/v20/LYjDdGzinEIjCN19oA.ttf) format('truetype');
}
@font-face {
  font-family: 'Rajdhani';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rajdhani/v15/LDIxapCSOBg7S-QT7q4A.ttf) format('truetype');
}
@font-face {
  font-family: 'Rakkas';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rakkas/v17/Qw3cZQlNHiblL3j_lg.ttf) format('truetype');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaooCP.ttf) format('truetype');
}
@font-face {
  font-family: 'Raleway Dots';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ralewaydots/v14/6NUR8FifJg6AfQvzpshgwJ8kyQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Ramabhadra';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ramabhadra/v15/EYq2maBOwqRW9P1SQ83LehM.ttf) format('truetype');
}
@font-face {
  font-family: 'Ramaraja';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ramaraja/v15/SlGTmQearpYAYG1CABIk.ttf) format('truetype');
}
@font-face {
  font-family: 'Rambla';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rambla/v13/snfrs0ip98hx6mr0Iw.ttf) format('truetype');
}
@font-face {
  font-family: 'Rammetto One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rammettoone/v14/LhWiMV3HOfMbMetJG3lQDpp9Mg.ttf) format('truetype');
}
@font-face {
  font-family: 'Rampart One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rampartone/v7/K2F1fZFGl_JSR1tAWNG9R6qg.ttf) format('truetype');
}
@font-face {
  font-family: 'Ranchers';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ranchers/v13/zrfm0H3Lx-P2Xvs2AoDY.ttf) format('truetype');
}
@font-face {
  font-family: 'Rancho';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rancho/v17/46kulbzmXjLaqZRlbQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Ranga';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ranga/v17/C8ct4cYisGb28p6C.ttf) format('truetype');
}
@font-face {
  font-family: 'Rasa';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rasa/v15/xn76YHIn1mWmVKl8ZtAM9NrJfN5GJW41.ttf) format('truetype');
}
@font-face {
  font-family: 'Rationale';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rationale/v24/9XUnlJ92n0_JFxHIfHcsdg.ttf) format('truetype');
}
@font-face {
  font-family: 'Ravi Prakash';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raviprakash/v19/gokpH6fsDkVrF9Bv9X8SOAKHmA.ttf) format('truetype');
}
@font-face {
  font-family: 'Readex Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/readexpro/v10/SLXYc1bJ7HE5YDoGPuzj_dh8na74KiwZQQxfm7w3.ttf) format('truetype');
}
@font-face {
  font-family: 'Recursive';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/recursive/v35/8vJN7wMr0mhh-RQChyHEH06TlXhq_gukbYrFMk1QuAIcyEwG_X-dpEfaE5YaERmK-CImKsvxvU-MXGX2fSqasNfUvz2xbXfn1uEQadCCk018.ttf) format('truetype');
}
@font-face {
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbmyWckg.ttf) format('truetype');
}
@font-face {
  font-family: 'Red Hat Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/redhatmono/v10/jVyY7nDnA2uf2zVvFAhhzEs-VMSjJpBTfgjwQV3I-7E.ttf) format('truetype');
}
@font-face {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/redhattext/v13/RrQCbohi_ic6B3yVSzGBrMx6ZI_cy1A6Ok2ML7hwVrY.ttf) format('truetype');
}
@font-face {
  font-family: 'Red Rose';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/redrose/v14/QdVISTYiLBjouPgEUajvsfWwDtc3MH8yrfsDcg.ttf) format('truetype');
}
@font-face {
  font-family: 'Redacted';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/redacted/v6/Z9XVDmdRShme2O_7aITe.ttf) format('truetype');
}
@font-face {
  font-family: 'Redacted Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/redactedscript/v6/ypvBbXGRglhokR7dcC3d1-R6zmxSsQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Redressed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/redressed/v25/x3dickHUbrmJ7wMy9MsBfA.ttf) format('truetype');
}
@font-face {
  font-family: 'Reem Kufi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/reemkufi/v18/2sDPZGJLip7W2J7v7wQZZE1I0yCmYzzQtuZnEGE.ttf) format('truetype');
}
@font-face {
  font-family: 'Reem Kufi Fun';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/reemkufifun/v6/uK_m4rOFYukkmyUEbF43fIryZEk5qRZ8nrKChoYj3nCg.ttf) format('truetype');
}
body {
  --google-font-color-reemkufifun:none;
}
@font-face {
  font-family: 'Reem Kufi Ink';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/reemkufiink/v6/oPWJ_kJmmu8hCvB9iFumxZSnRj4.ttf) format('truetype');
}
body {
  --google-font-color-reemkufiink:none;
}
@font-face {
  font-family: 'Reenie Beanie';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/reeniebeanie/v16/z7NSdR76eDkaJKZJFkkjuvWxbP0.ttf) format('truetype');
}
@font-face {
  font-family: 'Reggae One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/reggaeone/v14/~CgwKClJlZ2dhZSBPbmUgAA==.ttf) format('truetype');
}
@font-face {
  font-family: 'Revalia';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/revalia/v20/WwkexPimBE2-4ZPEeVo.ttf) format('truetype');
}
@font-face {
  font-family: 'Rhodium Libre';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rhodiumlibre/v17/1q2AY5adA0tn_ukeHcQHqpx6pEQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Ribeye';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ribeye/v21/L0x8DFMxk1MP9R3RvA.ttf) format('truetype');
}
@font-face {
  font-family: 'Ribeye Marrow';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ribeyemarrow/v22/GFDsWApshnqMRO2JdtRZ2d0vEAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Righteous';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/righteous/v13/1cXxaUPXBpj2rGoU7C9mjw.ttf) format('truetype');
}
@font-face {
  font-family: 'Risque';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/risque/v20/VdGfAZUfHosahXxoCQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Road Rage';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roadrage/v5/6NUU8F2fKAOBKjjr4ekvtA.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5Q.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v25/ieVl2ZhZI2eCN5jzbjEETS9weq8-59U.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaN4epOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXpRJ6cXW4O8TNGoXjC79QRyaLshNDUf3e0O-gn5rrZCu20YNYG0EACUTNK-QKavMlxGIQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotomono/v22/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vqPQw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Serif';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoserif/v8/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX6UgfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEliotp6c.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoslab/v24/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojISWaA.ttf) format('truetype');
}
@font-face {
  font-family: 'Rochester';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rochester/v18/6ae-4KCqVa4Zy6Fif-Uy3w.ttf) format('truetype');
}
@font-face {
  font-family: 'Rock Salt';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rocksalt/v18/MwQ0bhv11fWD6QsAVOZbsA.ttf) format('truetype');
}
@font-face {
  font-family: 'RocknRoll One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rocknrollone/v10/kmK7ZqspGAfCeUiW6FFlmEC9guU.ttf) format('truetype');
}
@font-face {
  font-family: 'Rokkitt';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rokkitt/v29/qFdb35qfgYFjGy5hukqqhw5XeRgdi1ryd5DL.ttf) format('truetype');
}
@font-face {
  font-family: 'Romanesco';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/romanesco/v21/w8gYH2ozQOY7_r_J7mSn3A.ttf) format('truetype');
}
@font-face {
  font-family: 'Ropa Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ropasans/v15/EYqxmaNOzLlWtsZSScyKWg.ttf) format('truetype');
}
@font-face {
  font-family: 'Rosario';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rosario/v27/xfuu0WDhWW_fOEoY8l_VPNZfB7jPM68YCWcz.ttf) format('truetype');
}
@font-face {
  font-family: 'Rosarivo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rosarivo/v20/PlI-Fl2lO6N9f8HaNAeC.ttf) format('truetype');
}
@font-face {
  font-family: 'Rouge Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rougescript/v14/LYjFdGbiklMoCIQOw1Ep3S4PVA.ttf) format('truetype');
}
@font-face {
  font-family: 'Rowdies';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rowdies/v15/ptRJTieMYPNBAK21zrc.ttf) format('truetype');
}
@font-face {
  font-family: 'Rozha One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rozhaone/v13/AlZy_zVFtYP12Zncg2khdQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v21/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4i1UA.ttf) format('truetype');
}
@font-face {
  font-family: 'Rubik Beastly';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubikbeastly/v10/0QImMXRd5oOmSC2ZQ7o9653X07w.ttf) format('truetype');
}
@font-face {
  font-family: 'Rubik Bubbles';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubikbubbles/v2/JIA1UVdwbHFJtwA7Us1BPFbRNTE.ttf) format('truetype');
}
@font-face {
  font-family: 'Rubik Burned';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubikburned/v1/Jqzk5TmOVOqQHihKqPpscqniHQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Rubik Dirt';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubikdirt/v1/DtVmJxC7WLEj1uIXEWAdulw.ttf) format('truetype');
}
@font-face {
  font-family: 'Rubik Distressed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubikdistressed/v1/GFDxWBdsmnqAVqjtUsZf2dcrQ2ldcWA.ttf) format('truetype');
}
@font-face {
  font-family: 'Rubik Glitch';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubikglitch/v2/qkBSXv8b_srFRYQVYrDKh9ZvmA.ttf) format('truetype');
}
@font-face {
  font-family: 'Rubik Iso';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubikiso/v1/x3dickHUfr-S4VAI4sABfA.ttf) format('truetype');
}
@font-face {
  font-family: 'Rubik Marker Hatch';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubikmarkerhatch/v1/QldTNSFQsh0B_bFXXWv6LAt-jswapJHQDA.ttf) format('truetype');
}
@font-face {
  font-family: 'Rubik Maze';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubikmaze/v1/xMQRuF9ZVa2ftiJEavXSAX4.ttf) format('truetype');
}
@font-face {
  font-family: 'Rubik Microbe';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubikmicrobe/v2/UqyWK8oPP3hjw6ANS9rM3PsZcs8.ttf) format('truetype');
}
@font-face {
  font-family: 'Rubik Mono One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubikmonoone/v14/UqyJK8kPP3hjw6ANTdfRk9YSN-8w.ttf) format('truetype');
}
@font-face {
  font-family: 'Rubik Moonrocks';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubikmoonrocks/v2/845ANMAmAI2VUZMLu_W0M7HqlDHnXQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Rubik Puddles';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubikpuddles/v2/1Ptog8bYX_qGnkLkrU5MJsQcJfA.ttf) format('truetype');
}
@font-face {
  font-family: 'Rubik Wet Paint';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubikwetpaint/v2/HTx0L20uMDGHgdULcpTF3Oe4d_-F-w.ttf) format('truetype');
}
@font-face {
  font-family: 'Ruda';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ruda/v23/k3kKo8YQJOpFgHQ1mQ5VkEbUKaJFsi_-.ttf) format('truetype');
}
@font-face {
  font-family: 'Rufina';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rufina/v13/Yq6V-LyURyLy-aKyow.ttf) format('truetype');
}
@font-face {
  font-family: 'Ruge Boogie';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rugeboogie/v24/JIA3UVFwbHRF_GIWSMhKNROi.ttf) format('truetype');
}
@font-face {
  font-family: 'Ruluko';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ruluko/v21/xMQVuFNZVaODtm0pCw.ttf) format('truetype');
}
@font-face {
  font-family: 'Rum Raisin';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rumraisin/v20/nwpRtKu3Ih8D5avB4h2uJ38.ttf) format('truetype');
}
@font-face {
  font-family: 'Ruslan Display';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ruslandisplay/v22/Gw6jwczl81XcIZuckK_e3Upfdzxr.ttf) format('truetype');
}
@font-face {
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/russoone/v14/Z9XUDmZRWg6M1LvRYsH-yA.ttf) format('truetype');
}
@font-face {
  font-family: 'Ruthie';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ruthie/v24/gokvH63sGkdqXuU9lA.ttf) format('truetype');
}
@font-face {
  font-family: 'Rye';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rye/v13/r05XGLJT86YDFg.ttf) format('truetype');
}
@font-face {
  font-family: 'STIX Two Text';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/stixtwotext/v10/YA9Gr02F12Xkf5whdwKf11l0jbKkeidMTtZ5Yihg2SOY.ttf) format('truetype');
}
@font-face {
  font-family: 'Sacramento';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sacramento/v13/buEzpo6gcdjy0EiZMBUG0Co.ttf) format('truetype');
}
@font-face {
  font-family: 'Sahitya';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sahitya/v17/6qLAKZkOuhnuqlJAaSc.ttf) format('truetype');
}
@font-face {
  font-family: 'Sail';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sail/v16/DPEjYwiBxwYJFBQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Saira';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/saira/v14/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA71rCosg.ttf) format('truetype');
}
@font-face {
  font-family: 'Saira Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sairacondensed/v11/EJROQgErUN8XuHNEtX81i9TmEkrfpQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Saira Extra Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sairaextracondensed/v11/-nFiOHYr-vcC7h8MklGBkrvmUG9rbpkisrTT7w.ttf) format('truetype');
}
@font-face {
  font-family: 'Saira Semi Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sairasemicondensed/v11/U9MD6c-2-nnJkHxyCjRcnMHcWVWV1cWRRU8L.ttf) format('truetype');
}
@font-face {
  font-family: 'Saira Stencil One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sairastencilone/v14/SLXSc03I6HkvZGJ1GvvipLoYSTEL9AsM.ttf) format('truetype');
}
@font-face {
  font-family: 'Salsa';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/salsa/v17/gNMKW3FiRpKj-imY.ttf) format('truetype');
}
@font-face {
  font-family: 'Sanchez';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sanchez/v13/Ycm2sZJORluHnXbITm4.ttf) format('truetype');
}
@font-face {
  font-family: 'Sancreek';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sancreek/v23/pxiHypAnsdxUm159X7D-.ttf) format('truetype');
}
@font-face {
  font-family: 'Sansita';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sansita/v10/QldONTRRphEb_-V7HBk.ttf) format('truetype');
}
@font-face {
  font-family: 'Sansita Swashed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sansitaswashed/v17/BXR8vFfZifTZgFlDDLgNkBydPKTt3pVCeYWqJnZSW7RpbTo.ttf) format('truetype');
}
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sarabun/v13/DtVjJx26TKEr37c9WBI.ttf) format('truetype');
}
@font-face {
  font-family: 'Sarala';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sarala/v10/uK_y4riEZv4o1w9RCg.ttf) format('truetype');
}
@font-face {
  font-family: 'Sarina';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sarina/v21/-F6wfjF3ITQwasLhLg.ttf) format('truetype');
}
@font-face {
  font-family: 'Sarpanch';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sarpanch/v11/hESy6Xt4NCpRuk6Pzh2A.ttf) format('truetype');
}
@font-face {
  font-family: 'Sassy Frass';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sassyfrass/v5/LhWhMVrGOe0FLb97BjhsE99d.ttf) format('truetype');
}
@font-face {
  font-family: 'Satisfy';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/satisfy/v17/rP2Hp2yn6lkG50LoOZQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Sawarabi Gothic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sawarabigothic/v12/x3d4ckfVaqqa-BEj-I9mE65u3k3NBQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Sawarabi Mincho';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sawarabimincho/v17/8QIRdiDaitzr7brc8ahpxt6GcIJTLQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Scada';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/scada/v14/RLpxK5Pv5qumeWJo.ttf) format('truetype');
}
@font-face {
  font-family: 'Scheherazade New';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/scheherazadenew/v14/4UaZrFhTvxVnHDvUkUiHg8jprP4DCwM.ttf) format('truetype');
}
@font-face {
  font-family: 'Schoolbell';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/schoolbell/v18/92zQtBZWOrcgoe-fgnJIVxI.ttf) format('truetype');
}
@font-face {
  font-family: 'Scope One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/scopeone/v14/WBLnrEXKYFlGHrOKmGD1Ww.ttf) format('truetype');
}
@font-face {
  font-family: 'Seaweed Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/seaweedscript/v13/bx6cNx6Tne2pxOATYE8C_Rsoe0WJ.ttf) format('truetype');
}
@font-face {
  font-family: 'Secular One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/secularone/v11/8QINdiTajsj_87rMuMdKypDl.ttf) format('truetype');
}
@font-face {
  font-family: 'Sedgwick Ave';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sedgwickave/v12/uK_04rKEYuguzAcSYRdWTJq8Xg.ttf) format('truetype');
}
@font-face {
  font-family: 'Sedgwick Ave Display';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sedgwickavedisplay/v19/xfuu0XPgU3jZPUoUo3ScvmPi-NapQ8OxM2cz.ttf) format('truetype');
}
@font-face {
  font-family: 'Sen';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sen/v7/6xKjdSxYI9_Hmw.ttf) format('truetype');
}
@font-face {
  font-family: 'Send Flowers';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sendflowers/v2/If2PXTjtZS-0Xqy13uCQSULvxw.ttf) format('truetype');
}
@font-face {
  font-family: 'Sevillana';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sevillana/v21/KFOlCnWFscmDt1Bfiy1vAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Seymour One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/seymourone/v20/4iCp6Khla9xbjQpoWGGd0myI.ttf) format('truetype');
}
@font-face {
  font-family: 'Shadows Into Light';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/shadowsintolight/v15/UqyNK9UOIntux_czAvDQx_ZcHqZXBNQDcg.ttf) format('truetype');
}
@font-face {
  font-family: 'Shadows Into Light Two';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/shadowsintolighttwo/v13/4iC86LVlZsRSjQhpWGedwyOoW-0A6_kpsyNmlAs.ttf) format('truetype');
}
@font-face {
  font-family: 'Shalimar';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/shalimar/v5/uU9MCBoE6I6iNWFUvTPx.ttf) format('truetype');
}
@font-face {
  font-family: 'Shanti';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/shanti/v23/t5thIREMM4uSDgzgUw.ttf) format('truetype');
}
@font-face {
  font-family: 'Share';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/share/v16/i7dEIFliZjKNF5VN.ttf) format('truetype');
}
@font-face {
  font-family: 'Share Tech';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sharetech/v17/7cHtv4Uyi5K0OeZ7bohUwHo.ttf) format('truetype');
}
@font-face {
  font-family: 'Share Tech Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sharetechmono/v15/J7aHnp1uDWRBEqV98dVQztYldFc7pA.ttf) format('truetype');
}
@font-face {
  font-family: 'Shippori Antique';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/shipporiantique/v8/-F6qfid3KC8pdMyzR0qRyFUht11v8lc.ttf) format('truetype');
}
@font-face {
  font-family: 'Shippori Antique B1';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/shipporiantiqueb1/v8/2Eb7L_JwClR7Zl_UAKZ0mUHw3oMKd40grRE.ttf) format('truetype');
}
@font-face {
  font-family: 'Shippori Mincho';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/shipporimincho/v14/VdGGAZweH5EbgHY6YExcZfDoj0BA2w.ttf) format('truetype');
}
@font-face {
  font-family: 'Shippori Mincho B1';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/shipporiminchob1/v19/~ChQKElNoaXBwb3JpIE1pbmNobyBCMSAA.ttf) format('truetype');
}
@font-face {
  font-family: 'Shojumaru';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/shojumaru/v15/rax_HiWfutkLLnaKCtlMBA.ttf) format('truetype');
}
@font-face {
  font-family: 'Short Stack';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/shortstack/v15/bMrzmS2X6p0jZC6EcmPFX-SS.ttf) format('truetype');
}
@font-face {
  font-family: 'Shrikhand';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/shrikhand/v11/a8IbNovtLWfR7T7bMJwbBA.ttf) format('truetype');
}
@font-face {
  font-family: 'Siemreap';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/siemreap/v24/Gg82N5oFbgLvHAfNl2Yb.ttf) format('truetype');
}
@font-face {
  font-family: 'Sigmar One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sigmarone/v16/co3DmWZ8kjZuErj9Ta3dk6M.ttf) format('truetype');
}
@font-face {
  font-family: 'Signika';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/signika/v20/vEFO2_JTCgwQ5ejvMV0O96D01E8J0tJXHJbG.ttf) format('truetype');
}
@font-face {
  font-family: 'Signika Negative';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/signikanegative/v20/E21x_cfngu7HiRpPX3ZpNE4kY5zKSPmJXkF0VDD2RAqnS73s.ttf) format('truetype');
}
@font-face {
  font-family: 'Silkscreen';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/silkscreen/v1/m8JXjfVPf62XiF7kO-i9ULQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Simonetta';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/simonetta/v23/x3dickHVYrCU5BU15c4BfA.ttf) format('truetype');
}
@font-face {
  font-family: 'Single Day';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/singleday/v15/LYjHdGDjlEgoAcF95EI5jVo.ttf) format('truetype');
}
@font-face {
  font-family: 'Sintony';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sintony/v13/XoHm2YDqR7-98cVUITQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Sirin Stencil';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sirinstencil/v21/mem4YaWwznmLx-lzGfN7MdRydcg.ttf) format('truetype');
}
@font-face {
  font-family: 'Six Caps';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sixcaps/v16/6ae_4KGrU7VR7bNmabcS.ttf) format('truetype');
}
@font-face {
  font-family: 'Skranji';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/skranji/v13/OZpDg_dtriVFNerMYzs.ttf) format('truetype');
}
@font-face {
  font-family: 'Slabo 13px';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/slabo13px/v13/11hEGp_azEvXZUdSBzzRcKc.ttf) format('truetype');
}
@font-face {
  font-family: 'Slabo 27px';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/slabo27px/v12/mFT0WbgBwKPR_Z4hGN2qsxg.ttf) format('truetype');
}
@font-face {
  font-family: 'Slackey';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/slackey/v24/N0bV2SdQO-5yM0-dKlQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Smokum';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/smokum/v24/TK3iWkUbAhopmrdGHg.ttf) format('truetype');
}
@font-face {
  font-family: 'Smooch';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/smooch/v5/o-0LIps4xW8U1xUBjg.ttf) format('truetype');
}
@font-face {
  font-family: 'Smooch Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/smoochsans/v6/c4mz1n5uGsXss2LJh1QH6b129FZvxPj6I4oiwUBodg.ttf) format('truetype');
}
@font-face {
  font-family: 'Smythe';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/smythe/v23/MwQ3bhT01--coT1BOA.ttf) format('truetype');
}
@font-face {
  font-family: 'Sniglet';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sniglet/v17/cIf9MaFLtkE3UjaJxCk.ttf) format('truetype');
}
@font-face {
  font-family: 'Snippet';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/snippet/v21/bWt47f7XfQH9Gupu2v8.ttf) format('truetype');
}
@font-face {
  font-family: 'Snowburst One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/snowburstone/v20/MQpS-WezKdujBsXY3B7I-UT7eZ8.ttf) format('truetype');
}
@font-face {
  font-family: 'Sofadi One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sofadione/v21/JIA2UVBxdnVBuElZaMFGcDM.ttf) format('truetype');
}
@font-face {
  font-family: 'Sofia';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sofia/v14/8QIHdirahM3j_vu-.ttf) format('truetype');
}
@font-face {
  font-family: 'Solway';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/solway/v17/AMOQz46Cs2uTAOCWgg.ttf) format('truetype');
}
@font-face {
  font-family: 'Song Myung';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/songmyung/v20/1cX2aUDWAJH5-EIC7DIhr1E.ttf) format('truetype');
}
@font-face {
  font-family: 'Sono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sono/v1/aFT97PNiY3U2Cqf_aYEN64CYaK18YWJEsV6u-QLiOsxVtkWdEg.ttf) format('truetype');
}
@font-face {
  font-family: 'Sonsie One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sonsieone/v21/PbymFmP_EAnPqbKaoc18YVs.ttf) format('truetype');
}
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sora/v11/xMQOuFFYT72X5wkB_18qmnndmSdSnn-K.ttf) format('truetype');
}
@font-face {
  font-family: 'Sorts Mill Goudy';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sortsmillgoudy/v15/Qw3GZR9MED_6PSuS_50nEaVrfzgEXH0.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DMyQhM4.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcesans3/v8/nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8Ky461EN.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3aPw.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Serif 4';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourceserif4/v7/vEFy2_tTDB4M7-auWDN0ahZJW3IX2ih5nk3AucvUHf6OAVIJmeUDygwjihdqrhw.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourceserifpro/v15/neIQzD-0qpwxpaWvjeD0X88SAOeaiXM.ttf) format('truetype');
}
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spacegrotesk/v13/V8mQoQDjQSkFtoMM3T6r8E7mF71Q-gOoraIAEj7oUUsj.ttf) format('truetype');
}
@font-face {
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spacemono/v12/i7dPIFZifjKcF5UAWdDRUEY.ttf) format('truetype');
}
@font-face {
  font-family: 'Special Elite';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/specialelite/v18/XLYgIZbkc4JPUL5CVArUVL0nhnc.ttf) format('truetype');
}
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spectral/v13/rnCr-xNNww_2s0amA-M-.ttf) format('truetype');
}
@font-face {
  font-family: 'Spectral SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spectralsc/v11/KtkpALCRZonmalTgyPmRfvWi.ttf) format('truetype');
}
@font-face {
  font-family: 'Spicy Rice';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spicyrice/v21/uK_24rSEd-Uqwk4jY1RyGv8.ttf) format('truetype');
}
@font-face {
  font-family: 'Spinnaker';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spinnaker/v17/w8gYH2oyX-I0_rvR6Hmn3A.ttf) format('truetype');
}
@font-face {
  font-family: 'Spirax';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spirax/v21/buE3poKgYNLy0F3cXg.ttf) format('truetype');
}
@font-face {
  font-family: 'Splash';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/splash/v1/KtksAL2RZoDkbU6hpA.ttf) format('truetype');
}
@font-face {
  font-family: 'Spline Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/splinesans/v8/_6_sED73Uf-2WfU2LzycEZousNzn1a1lKWRpOFnYEg.ttf) format('truetype');
}
@font-face {
  font-family: 'Spline Sans Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/splinesansmono/v4/R70MjzAei_CDNLfgZxrW6wrZOF2WdZ6xabUGSVtNuGBiMrtV.ttf) format('truetype');
}
@font-face {
  font-family: 'Squada One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/squadaone/v14/BCasqZ8XsOrx4mcOk6MtWaA.ttf) format('truetype');
}
@font-face {
  font-family: 'Square Peg';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/squarepeg/v2/y83eW48Nzw6ZlUHc-phrBDE.ttf) format('truetype');
}
@font-face {
  font-family: 'Sree Krushnadevaraya';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sreekrushnadevaraya/v21/R70FjzQeifmPepmyQQjQ9kvwMkWYPfTA_EWb.ttf) format('truetype');
}
@font-face {
  font-family: 'Sriracha';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sriracha/v10/0nkrC9D4IuYBgWcI9ObY.ttf) format('truetype');
}
@font-face {
  font-family: 'Srisakdi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/srisakdi/v16/yMJRMIlvdpDbkB0A-jq8.ttf) format('truetype');
}
@font-face {
  font-family: 'Staatliches';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/staatliches/v11/HI_OiY8KO6hCsQSoAPmtMbec.ttf) format('truetype');
}
@font-face {
  font-family: 'Stalemate';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/stalemate/v20/taiIGmZ_EJq97-UfkZRpug.ttf) format('truetype');
}
@font-face {
  font-family: 'Stalinist One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/stalinistone/v54/MQpS-WezM9W4Dd7D3B7I-UT7eZ8.ttf) format('truetype');
}
@font-face {
  font-family: 'Stardos Stencil';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/stardosstencil/v15/X7n94bcuGPC8hrvEOHXOgaKCc2TR7w.ttf) format('truetype');
}
@font-face {
  font-family: 'Stick';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/stick/v15/Qw3TZQpMCyTtJSvf.ttf) format('truetype');
}
@font-face {
  font-family: 'Stick No Bills';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sticknobills/v8/bWts7ffXZwHuAa9Uld-oEK4QKlxj9f9t_7uEmjcVv8Q7Kg.ttf) format('truetype');
}
@font-face {
  font-family: 'Stint Ultra Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/stintultracondensed/v21/-W_gXIrsVjjeyEnPC45qD2NoFPtBE0xCh2A-qg.ttf) format('truetype');
}
@font-face {
  font-family: 'Stint Ultra Expanded';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/stintultraexpanded/v20/CSRg4yNNh-GbW3o3JkwoDcdvMKMf0oBAd0qo.ttf) format('truetype');
}
@font-face {
  font-family: 'Stoke';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/stoke/v22/z7NadRb7aTMfKONp.ttf) format('truetype');
}
@font-face {
  font-family: 'Strait';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/strait/v13/DtViJxy6WaEr1LZzeA.ttf) format('truetype');
}
@font-face {
  font-family: 'Style Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/stylescript/v7/vm8xdRX3SV7Z0aPa88xzW5npeA.ttf) format('truetype');
}
@font-face {
  font-family: 'Stylish';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/stylish/v20/m8JSjfhPYriQkk7-fo0.ttf) format('truetype');
}
@font-face {
  font-family: 'Sue Ellen Francisco';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sueellenfrancisco/v16/wXK3E20CsoJ9j1DDkjHcQ5ZL8xRaxru9roo.ttf) format('truetype');
}
@font-face {
  font-family: 'Suez One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/suezone/v11/taiJGmd_EZ6rqscQgNFJ.ttf) format('truetype');
}
@font-face {
  font-family: 'Sulphur Point';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sulphurpoint/v14/RLp5K5vv8KaycDcazWFPBj2aRfk.ttf) format('truetype');
}
@font-face {
  font-family: 'Sumana';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sumana/v10/4UaDrE5TqRBjGj-G8A.ttf) format('truetype');
}
@font-face {
  font-family: 'Sunshiney';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sunshiney/v24/LDIwapGTLBwsS-wT4vcgEw.ttf) format('truetype');
}
@font-face {
  font-family: 'Supermercado One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/supermercadoone/v22/OpNXnpQWg8jc_xps_Gi14kVVEXOn60Y.ttf) format('truetype');
}
@font-face {
  font-family: 'Sura';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sura/v15/SZc23FL5PbyzFf4.ttf) format('truetype');
}
@font-face {
  font-family: 'Suranna';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/suranna/v13/gokuH6ztGkFjWe58tBQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Suravaram';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/suravaram/v21/_gP61R_usiY7SCym4xIAiw.ttf) format('truetype');
}
@font-face {
  font-family: 'Suwannaphum';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/suwannaphum/v29/jAnCgHV7GtDvc8jbe8hXXIWl.ttf) format('truetype');
}
@font-face {
  font-family: 'Swanky and Moo Moo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/swankyandmoomoo/v22/flUlRrKz24IuWVI_WJYTYcqbEsMUZ3kUtQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Syncopate';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/syncopate/v19/pe0sMIuPIYBCpEV5eFdyAg.ttf) format('truetype');
}
@font-face {
  font-family: 'Syne';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/syne/v15/8vIS7w4qzmVxsWxjBZRjr0FKM_04uT6k.ttf) format('truetype');
}
@font-face {
  font-family: 'Syne Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/synemono/v15/K2FzfZNHj_FHBmRbFvHzIg.ttf) format('truetype');
}
@font-face {
  font-family: 'Syne Tactile';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/synetactile/v15/11hGGpna2UTQKjMCVzjAPMKh3w.ttf) format('truetype');
}
@font-face {
  font-family: 'Tai Heritage Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/taiheritagepro/v2/sZlfdQid-zgaNiNIYcUzJMU3IYyNoHw.ttf) format('truetype');
}
@font-face {
  font-family: 'Tajawal';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tajawal/v9/Iura6YBj_oCad4k1rzY.ttf) format('truetype');
}
@font-face {
  font-family: 'Tangerine';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tangerine/v17/IurY6Y5j_oScZZow4VOBDg.ttf) format('truetype');
}
@font-face {
  font-family: 'Tapestry';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tapestry/v2/SlGTmQecrosEYXhaGBIk.ttf) format('truetype');
}
@font-face {
  font-family: 'Taprom';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/taprom/v27/UcCn3F82JHycULbFQw.ttf) format('truetype');
}
@font-face {
  font-family: 'Tauri';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tauri/v16/TwMA-IISS0AM3IpV.ttf) format('truetype');
}
@font-face {
  font-family: 'Taviraj';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/taviraj/v11/ahcZv8Cj3ylylTXzfO4.ttf) format('truetype');
}
@font-face {
  font-family: 'Teko';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/teko/v15/LYjNdG7kmE0gTaQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Telex';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/telex/v14/ieVw2Y1fKWmIO9fT.ttf) format('truetype');
}
@font-face {
  font-family: 'Tenali Ramakrishna';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tenaliramakrishna/v12/raxgHj6Yt9gAN3LLKs0BZVMo8jmwn1-8KA.ttf) format('truetype');
}
@font-face {
  font-family: 'Tenor Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tenorsans/v17/bx6ANxqUneKx06UkIXISr3I.ttf) format('truetype');
}
@font-face {
  font-family: 'Text Me One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/textmeone/v20/i7dOIFdlayuLUvgoFvHQFWZc.ttf) format('truetype');
}
@font-face {
  font-family: 'Texturina';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/texturina/v21/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2eYGvUg.ttf) format('truetype');
}
@font-face {
  font-family: 'Thasadith';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/thasadith/v9/mtG44_1TIqPYrd_f5R1YsA.ttf) format('truetype');
}
@font-face {
  font-family: 'The Girl Next Door';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/thegirlnextdoor/v18/pe0zMJCIMIsBjFxqYBIcZ6_OI5oFHCYIVw.ttf) format('truetype');
}
@font-face {
  font-family: 'The Nautigal';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/thenautigal/v3/VdGZAZ8ZH51Lvng9fQV2bfKr5w.ttf) format('truetype');
}
@font-face {
  font-family: 'Tienne';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tienne/v20/AYCKpX7pe9YCRP0LkA.ttf) format('truetype');
}
@font-face {
  font-family: 'Tillana';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tillana/v11/VuJxdNvf35P4qJ1OeKY.ttf) format('truetype');
}
@font-face {
  font-family: 'Timmana';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/timmana/v12/6xKvdShfL9yK-rvpCms.ttf) format('truetype');
}
@font-face {
  font-family: 'Tinos';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tinos/v24/buE4poGnedXvwgX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Tiro Bangla';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tirobangla/v6/IFSgHe1Tm95E3O8b5i2V8MG9.ttf) format('truetype');
}
@font-face {
  font-family: 'Tiro Devanagari Hindi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tirodevanagarihindi/v5/55xyezN7P8T4e0_CfIJrwdodg9HoYw0i-M9fSA.ttf) format('truetype');
}
@font-face {
  font-family: 'Tiro Devanagari Marathi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tirodevanagarimarathi/v5/fC1xPZBSZHrRhS3rd4M0MAPNJUHl4znXCxAkotDr.ttf) format('truetype');
}
@font-face {
  font-family: 'Tiro Devanagari Sanskrit';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tirodevanagarisanskrit/v5/MCoAzBbr09vVUgVBM8FWu_yZdZkhkg-I0nUlb59pEg.ttf) format('truetype');
}
@font-face {
  font-family: 'Tiro Gurmukhi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tirogurmukhi/v6/x3dmckXSYq-Uqjc048JUF7Jvly4.ttf) format('truetype');
}
@font-face {
  font-family: 'Tiro Kannada';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tirokannada/v6/CSR44ztKmvqaDxEDJFY7CIYKSA.ttf) format('truetype');
}
@font-face {
  font-family: 'Tiro Tamil';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tirotamil/v6/m8JXjfVIf7OT22n3M-S_ULQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Tiro Telugu';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tirotelugu/v6/aFTQ7PxlZWk2EPiSymjXdKSN.ttf) format('truetype');
}
@font-face {
  font-family: 'Titan One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/titanone/v13/mFTzWbsGxbbS_J5cQcjykw.ttf) format('truetype');
}
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/titilliumweb/v15/NaPecZTIAOhVxoMyOr9n_E7fRMQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Tomorrow';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tomorrow/v15/WBLmrETNbFtZCeGqgSXV.ttf) format('truetype');
}
@font-face {
  font-family: 'Tourney';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tourney/v8/AlZa_ztDtYzv1tzq1wcJnbVt7xseomk-tNs7qrzTWbyt8n7GOQFyZQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Trade Winds';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tradewinds/v17/AYCPpXPpYNIIT7h8-QenM3Jq.ttf) format('truetype');
}
@font-face {
  font-family: 'Train One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/trainone/v13/gyB-hwkiNtc6KnxUVjWHOg.ttf) format('truetype');
}
@font-face {
  font-family: 'Trirong';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/trirong/v11/7r3GqXNgp8wxdOdOr4w.ttf) format('truetype');
}
@font-face {
  font-family: 'Trispace';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/trispace/v18/Yq65-LKSQC3o56LxxgRrtA6yBqsrXL5GI5KI-IUZVGsxWFIlbH9roQk.ttf) format('truetype');
}
@font-face {
  font-family: 'Trocchi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/trocchi/v14/qWcqB6WkuIDxDZLcDrs.ttf) format('truetype');
}
@font-face {
  font-family: 'Trochut';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/trochut/v20/CHyjV-fDDlP9bDIw5nQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Truculenta';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/truculenta/v18/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMlAiswc.ttf) format('truetype');
}
@font-face {
  font-family: 'Trykker';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/trykker/v21/KtktALyWZJXudUPzhNk.ttf) format('truetype');
}
@font-face {
  font-family: 'Tulpen One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tulpenone/v21/dFa6ZfeC474skLgesc0CWj0.ttf) format('truetype');
}
@font-face {
  font-family: 'Turret Road';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/turretroad/v7/pxiAypMgpcBFjE84Zv-fE3tF.ttf) format('truetype');
}
@font-face {
  font-family: 'Twinkle Star';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/twinklestar/v3/pe0pMI6IL4dPoFl9LGEmY6WaAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgo6eA.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntucondensed/v16/u-4k0rCzjgs5J7oXnJcM_0kACGMtf-c.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntumono/v15/KFOjCneDtsqEr0keqCMhbBc9.ttf) format('truetype');
}
@font-face {
  font-family: 'Uchen';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/uchen/v7/nKKZ-GokGZ1baIaS.ttf) format('truetype');
}
@font-face {
  font-family: 'Ultra';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ultra/v19/zOLy4prXmrtY-tT6.ttf) format('truetype');
}
@font-face {
  font-family: 'Uncial Antiqua';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/uncialantiqua/v20/N0bM2S5WOex4OUbESzoESK-i-PfR.ttf) format('truetype');
}
@font-face {
  font-family: 'Underdog';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/underdog/v22/CHygV-jCElj7diMroVSi.ttf) format('truetype');
}
@font-face {
  font-family: 'Unica One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/unicaone/v13/DPEuYwWHyAYGVTSmalshdg.ttf) format('truetype');
}
@font-face {
  font-family: 'UnifrakturMaguntia';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/unifrakturmaguntia/v16/WWXPlieVYwiGNomYU-ciRLRvEmK7oaVunw.ttf) format('truetype');
}
@font-face {
  font-family: 'Unkempt';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/unkempt/v19/2EbnL-Z2DFZue0DSSYY.ttf) format('truetype');
}
@font-face {
  font-family: 'Unlock';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/unlock/v22/7Au-p_8ykD-cDl7GKA.ttf) format('truetype');
}
@font-face {
  font-family: 'Unna';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/unna/v21/AYCEpXzofN0NCpg.ttf) format('truetype');
}
@font-face {
  font-family: 'Updock';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/updock/v2/nuF4D_3dVZ70UI9SjA.ttf) format('truetype');
}
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/urbanist/v10/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDyx4fFg.ttf) format('truetype');
}
@font-face {
  font-family: 'VT323';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/vt323/v17/pxiKyp0ihIEF2hsY.ttf) format('truetype');
}
@font-face {
  font-family: 'Vampiro One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/vampiroone/v18/gokqH6DoDl5yXvJytFsdLkqn.ttf) format('truetype');
}
@font-face {
  font-family: 'Varela';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/varela/v16/DPEtYwqExx0AWHXJBA.ttf) format('truetype');
}
@font-face {
  font-family: 'Varela Round';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/varelaround/v19/w8gdH283Tvk__Lua32TysjIvoA.ttf) format('truetype');
}
@font-face {
  font-family: 'Varta';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/varta/v17/Qw3AZQpJHj_6LzHUngWbrFkDH1x9tD4Eig.ttf) format('truetype');
}
@font-face {
  font-family: 'Vast Shadow';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/vastshadow/v15/pe0qMImKOZ1V62ZwbVY9dfe6.ttf) format('truetype');
}
@font-face {
  font-family: 'Vazirmatn';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/vazirmatn/v6/Dxx78j6PP2D_kU2muijPEe1n2vVbfJRklWgzORc.ttf) format('truetype');
}
@font-face {
  font-family: 'Vesper Libre';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/vesperlibre/v19/bx6CNxyWnf-uxPdXDHUD_Rd4Dw.ttf) format('truetype');
}
@font-face {
  font-family: 'Viaoda Libre';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/viaodalibre/v15/vEFW2_lWCgoR6OKuRz9kcRVJbw.ttf) format('truetype');
}
@font-face {
  font-family: 'Vibes';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/vibes/v14/QdVYSTsmIB6tmbd3.ttf) format('truetype');
}
@font-face {
  font-family: 'Vibur';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/vibur/v23/DPEiYwmEzw0QRjTp.ttf) format('truetype');
}
@font-face {
  font-family: 'Vidaloka';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/vidaloka/v18/7cHrv4c3ipenMKlEass8.ttf) format('truetype');
}
@font-face {
  font-family: 'Viga';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/viga/v14/xMQbuFFdSaiX_QI.ttf) format('truetype');
}
@font-face {
  font-family: 'Voces';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/voces/v20/-F6_fjJyLyU8d4PB.ttf) format('truetype');
}
@font-face {
  font-family: 'Volkhov';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/volkhov/v17/SlGQmQieoJcKemNeQTI.ttf) format('truetype');
}
@font-face {
  font-family: 'Vollkorn';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/vollkorn/v21/0ybgGDoxxrvAnPhYGzMlQLzuMasz6Df2MHGuGQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Vollkorn SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/vollkornsc/v11/j8_v6-zQ3rXpceZj9cqnVhF5.ttf) format('truetype');
}
@font-face {
  font-family: 'Voltaire';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/voltaire/v15/1Pttg8PcRfSblAvGvQoo.ttf) format('truetype');
}
@font-face {
  font-family: 'Vujahday Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/vujahdayscript/v3/RWmQoKGA8fEkrIPtSZ3_J7er2dUiDA.ttf) format('truetype');
}
@font-face {
  font-family: 'Waiting for the Sunrise';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/waitingforthesunrise/v16/WBL1rFvOYl9CEv2i1mO6KUW8RKWJ2zoXoz5JsYZQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Wallpoet';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/wallpoet/v16/f0X10em2_8RnXVVdUNbu.ttf) format('truetype');
}
@font-face {
  font-family: 'Walter Turncoat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/walterturncoat/v19/snfys0Gs98ln43n0d-14ULoToe67YA.ttf) format('truetype');
}
@font-face {
  font-family: 'Warnes';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/warnes/v22/pONn1hc0GsW6sW5Opg.ttf) format('truetype');
}
@font-face {
  font-family: 'Water Brush';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/waterbrush/v2/AYCPpXPqc8cJWLhp4hywKHJq.ttf) format('truetype');
}
@font-face {
  font-family: 'Waterfall';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/waterfall/v3/MCoRzAfo293fACdFKcwY2g.ttf) format('truetype');
}
@font-face {
  font-family: 'Wellfleet';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/wellfleet/v20/nuF7D_LfQJb3VYgX6eyT4w.ttf) format('truetype');
}
@font-face {
  font-family: 'Wendy One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/wendyone/v14/2sDcZGJOipXfgfXV5wgDbw.ttf) format('truetype');
}
@font-face {
  font-family: 'Whisper';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/whisper/v2/q5uHsoqtKftx74K9mik.ttf) format('truetype');
}
@font-face {
  font-family: 'WindSong';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/windsong/v7/KR1WBsyu-P-GFEW57r95.ttf) format('truetype');
}
@font-face {
  font-family: 'Wire One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/wireone/v24/qFdH35Wah5htUhV75WGi.ttf) format('truetype');
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v18/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXNig.ttf) format('truetype');
}
@font-face {
  font-family: 'Xanh Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/xanhmono/v17/R70YjykVmvKCep-vWhSYmA.ttf) format('truetype');
}
@font-face {
  font-family: 'Yaldevi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/yaldevi/v8/cY9afj6VW0NMrDWtDNzCOwlPMq9SLpdxJzvo.ttf) format('truetype');
}
@font-face {
  font-family: 'Yanone Kaffeesatz';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/yanonekaffeesatz/v24/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIfto9tWpcA.ttf) format('truetype');
}
@font-face {
  font-family: 'Yantramanav';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/yantramanav/v11/flU8Rqu5zY00QEpyWJYWN6f0.ttf) format('truetype');
}
@font-face {
  font-family: 'Yatra One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/yatraone/v14/C8ch4copsHzj8p7NaF0xww.ttf) format('truetype');
}
@font-face {
  font-family: 'Yellowtail';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/yellowtail/v18/OZpGg_pnoDtINPfRIlLotlw.ttf) format('truetype');
}
@font-face {
  font-family: 'Yeon Sung';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/yeonsung/v20/QldMNTpbohAGtsJvUn6xSQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Yeseva One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/yesevaone/v20/OpNJno4ck8vc-xYpwWWxpio.ttf) format('truetype');
}
@font-face {
  font-family: 'Yesteryear';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/yesteryear/v14/dg4g_p78rroaKl8kRKo1r7w.ttf) format('truetype');
}
@font-face {
  font-family: 'Yomogi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/yomogi/v8/VuJwdNrS2ZL7rpoPWA.ttf) format('truetype');
}
@font-face {
  font-family: 'Yrsa';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/yrsa/v15/wlprgwnQFlxs_wD3CFSMYmFaaCieSNNV.ttf) format('truetype');
}
@font-face {
  font-family: 'Yuji Boku';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/yujiboku/v5/P5sAzZybeNzXsA9xj1FkjQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Yuji Mai';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/yujimai/v5/ZgNQjPxdJ7DEHrS0gC38.ttf) format('truetype');
}
@font-face {
  font-family: 'Yuji Syuku';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/yujisyuku/v5/BngNUXdTV3vO6Lw5ApOPqPc.ttf) format('truetype');
}
@font-face {
  font-family: 'Yusei Magic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/yuseimagic/v11/yYLt0hbAyuCmoo5wlhPkpjHR.ttf) format('truetype');
}
@font-face {
  font-family: 'ZCOOL KuaiLe';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/zcoolkuaile/v19/tssqApdaRQokwFjFJjvM6h2Wpg.ttf) format('truetype');
}
@font-face {
  font-family: 'ZCOOL QingKe HuangYou';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/zcoolqingkehuangyou/v15/2Eb5L_R5IXJEWhD3AOhSvFC554MOOahI4mRIiw.ttf) format('truetype');
}
@font-face {
  font-family: 'ZCOOL XiaoWei';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/zcoolxiaowei/v14/i7dMIFFrTRywPpUVX9_RJyM1YFI.ttf) format('truetype');
}
@font-face {
  font-family: 'Zen Antique';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/zenantique/v12/AYCPpXPnd91Ma_Zf-Ri2JXJq.ttf) format('truetype');
}
@font-face {
  font-family: 'Zen Antique Soft';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/zenantiquesoft/v12/DtV4JwqzSL1q_KwnEWMc_3xfgW6ihwA.ttf) format('truetype');
}
@font-face {
  font-family: 'Zen Dots';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/zendots/v10/XRXX3ICfm00IGoesQeaE.ttf) format('truetype');
}
@font-face {
  font-family: 'Zen Kaku Gothic Antique';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/zenkakugothicantique/v13/6qLQKYkHvh-nlUpKPAdoVFBtfxDzIn1eCzpB21-g.ttf) format('truetype');
}
@font-face {
  font-family: 'Zen Kaku Gothic New';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/zenkakugothicnew/v13/gNMYW2drQpDw0GjzrVNFf_valaDBcznOkjs.ttf) format('truetype');
}
@font-face {
  font-family: 'Zen Kurenaido';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/zenkurenaido/v12/3XFsEr0515BK2u6UUptu_gWJZfw.ttf) format('truetype');
}
@font-face {
  font-family: 'Zen Loop';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/zenloop/v7/h0GrssK16UsnJwHsEK9z.ttf) format('truetype');
}
@font-face {
  font-family: 'Zen Maru Gothic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/zenmarugothic/v12/o-0SIpIxzW5b-RxT-6A8jWAtCp-k7Q.ttf) format('truetype');
}
@font-face {
  font-family: 'Zen Old Mincho';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/zenoldmincho/v11/tss0ApVaYytLwxTqcxfMyBveyYb3.ttf) format('truetype');
}
@font-face {
  font-family: 'Zen Tokyo Zoo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/zentokyozoo/v7/NGSyv5ffC0J_BK6aFNtr6sRv8a0.ttf) format('truetype');
}
@font-face {
  font-family: 'Zeyada';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/zeyada/v15/11hAGpPTxVPUbgZDNA.ttf) format('truetype');
}
@font-face {
  font-family: 'Zhi Mang Xing';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/zhimangxing/v17/f0Xw0ey79sErYFtWQ9a2rq-g0ac.ttf) format('truetype');
}
@font-face {
  font-family: 'Zilla Slab';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/zillaslab/v11/dFa6ZfeM_74wlPZtksIFWj0.ttf) format('truetype');
}
@font-face {
  font-family: 'Zilla Slab Highlight';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/zillaslabhighlight/v17/gNMbW2BrTpK8-inLtBJgMMfbm6uNVDvRxhtI.ttf) format('truetype');
}
