.cursor-pointer {
  cursor: pointer !important;
}

.text-underline-hover {
  text-decoration: none;
}

.text-underline-hover:hover {
  text-decoration: underline;
}

.ql-editor {
  height: 250px;
}

.plugin-card:hover {
  transition: box-shadow ease-in-out 0.15s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.plugin-name {
  text-decoration: none;
}

.plugin-name:hover {
  text-decoration: underline;
}

.screen-reader-element {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
}

.full-screen-editor {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  background-color: #00000070;
}

.full-screen-editor > div {
  width: 85vw;
  height: 80vh !important;
  display: flex;
  flex-direction: column;
}

@media (min-width: 1200px) {
  .w-xl-fit {
    width: fit-content !important;
  }

  .fw-xl-normal {
    font-weight: 400 !important;
  }

  .border-xl-end {
    border-right: 1px solid #dee2e6 !important;
  }
}
